import React from "react";
import "./PressNews18.scss";
function PressNews18() {
  return (
    <div className="PressNews18">
        <div>Press Release</div>

        <div class="heading">
          Alset Capital Inc. Announces Update To Previously Announced Debt
          Settlement
        </div>

        <div class="date">February 2, 2024</div>

        <p>
          <span className="bold">February 2, 2024 – Vancouver, Canada – ALSET CAPITAL INC.</span>
          (TSXV:KSUM.H) (“<span className="bold">ALSET</span>” or the “<span className="bold">Company</span>”) is pleased to announce that
          it has granted incentive stock options (the “<span className="bold">Options</span>”) to certain
          directors, officers and consultants of the Company for the purchase of
          up to 2,626,000 common shares in the capital of the Company (the
          “<span className="bold">Shares</span>”) pursuant to the Company’s share option plan. The Options
          vest immediately and are exercisable into Shares at an exercise price
          of $0.05 per Share for a five (5) year term, expiring on February 2,
          2029. The grant of Options is subject to the approval of the TSX
          Venture Exchange (the “<span className="bold">TSXV</span>”). The Options and any Shares issued upon
          exercise thereof shall be subject to a hold period of four (4) months
          plus one (1) day from the date of grant.
        </p>

        <div class="sub-header">On behalf of ALSET CAPITAL INC.</div>

        <p><span className="italic">“Morgan Good”</span> Morgan Good</p>

        <p>Chief Executive Officer</p>

        <div class="sub-header">About ALSET CAPITAL INC.</div>

        <p>
          Alset Capital Inc. is an investment issuer that is focused on
          investment in diversified industries such as technology, healthcare,
          industrial, special situations, operating businesses through both debt
          and equity using cash resources or shares in its capital. The Company
          is led by an experienced, entrepreneurial group of executives having a
          diverse industry and capital markets background.
        </p>

        <p>For further information about ALSET CAPITAL INC., please contact:</p>

        <p>Morgan Good, CEO and Director</p>

        <p>T: 604.715.4751</p>

        <p>E: morgangood@gmail.com</p>

          <div class="sub-header">
            Cautionary Statement on Forward-Looking Information
          </div>

          <p className="italic">
            This press release contains forward-looking statements.
            Forward-looking statements can be identified by the use of words
            such as, “subject to”, or variations of such words and phrases or
            state that certain actions, events, or results “may” or “will” be
            taken, occur, or be achieved. Forward-looking statements include
            statements with respect to receiving regulatory and exchange
            approvals with respect to the grant of the Options. Forward-looking
            statements are based on assumptions, but the actual results may be
            materially different from any future expectations expressed or
            implied by the forward-looking statements. The forward-looking
            statements can be affected by known and unknown risks,
            uncertainties, and other factors, including, but not limited to, the
            equity markets generally and a failure to obtain the necessary
            approvals from the TSXV. Accordingly, readers should not place undue
            reliance on forward-looking statements.
          </p>

          <p className="italic">
            Except as required by law, the Company undertakes no obligation to
            publicly update any forward-looking statements, whether as a result
            of new information, future events, or otherwise.
          </p>

          <p className="bold italic"> 
            Neither TSX Venture Exchange nor its Regulation Services Provider
            (as that term is defined in the policies of the TSX Venture
            Exchange) accepts responsibility for the adequacy or accuracy of
            this release.
          </p>
        </div>
  );
}

export default PressNews18;
