import React from "react";
import "./PressRelease.scss";
import table2 from "../../../../Assets/Images/table2.svg";
import { useSearchParams } from "react-router-dom";

function PressRelease() {
  return (
    <div className="PressRelease_main">
      <div className="top">
        <img src={table2} alt="table 2" className="table2" />
        <div className="text">
          <div className="title">
            Alset Capital Inc. Announces Strategic Partnership With AI Data
            Engineering And Automation Pioneer, NOM NOM AI Inc. By Investee
            Company Vertex AI Ventures Inc.
          </div>
          <div className="sub_title">
            <span
              style={{
                fontFamily: "M_Bold",
              }}
            >
              NOT FOR DISTRIBUTION TO UNITED STATES NEWSWIRE SERVICES OR FOR
              RELEASE, PUBLICATION, DISTRIBUTION OR DISSEMINATION, DIRECTLY OR
              INDIRECTLY, IN WHOLE OR IN PART, IN OR INTO THE UNITED STATES
              VANCOUVER, BC / ACCESSWIRE / May 21, 2024 / Alset Capital Inc.
              (TSXV:KSUM)(OTC:ALSCF)(FSE:1R60, WKN:A3ESVQ) (“Alset” or the
              “Company”){" "}
            </span>
            is pleased to announce that on May 20, 2024, its investee company,
            Vertex AI Ventures Inc. (“Vertex”) has entered into a strategic AI
            data engineering and automation partnership with Nom Nom AI Inc.
            (“Nom Nom”) in accordance with the terms of a Master Services
            Agreement between Vertex and Nom Nom (the “Master Services
            Agreement”).
            <br />
            <br />
            The design and development of Large Language Models (“LLMs”), and
            other AI products, requires the processing of massive amounts of
            data. ChatGPT-4, as an example, had 1.76 trillion parameters1.
            Vertex, led by Niko Kontogiannis, seeks to offer Data Management
            tools to companies, to improve their customers’ business
            intelligence and ability to innovate.
            <br />
            <br />
            “This partnership marks a significant milestone for Vertex as we
            seek to drive innovation in managed data services and AI.” said Niko
            Kontogiannis, Director of Vertex. “Nom Nom’s expertise perfectly
            complements our offerings seamlessly, enabling us to deliver
            exceptional value to clients. By integrating Nom Nom’s patent
            pending data management technology, we are uniquely positioned to
            cultivate long-term client relationships by enhancing data quality,
            security and infrastructure performance.” 
            <br />
            <br />
            “Our collaboration with Vertex represents a significant step forward
            in our mission to revolutionize data engineering, security,
            automation and AI optimization”, said Nam Nguyen CEO of Nom Nom. “By
            leveraging Nom Nom’s proprietary data solutions, Vertex is poised to
            deliver unparalleled value to businesses, driving innovation and
            efficiency in today’s data-driven world.”
            <br />
            <br />
            Nom Nom is a pioneer in data engineering, security, and automation
            as a service. Their knowledge and expertise provides innovative
            solutions that leverage artificial intelligence to streamline
            operations, reduce operational and capital expenses, and ensure
            businesses maintain a competitive edge in today’s data-rich
            environment. With data volumes reaching unprecedented levels, the
            demand for sophisticated analytics and scalable solutions has never
            been more urgent. Nom Nom’s expertise in managing, integrating, and
            automating vast datasets positions them as a cornerstone in the
            evolving landscape of data management and artificial intelligence.
            Nom Nom seamlessly integrates hardware with their software
            capabilities, granting clients comprehensive access to data insights
            through their user-friendly interface. Vertex clients will also gain
            an intuitive interface that enhances their understanding of data.
            <br />
            <br />
            The partnership between Vertex and Nom Nom is expected to bring
            forth a myriad of benefits for future clients, including enhanced
            data observability, insights, optimization of machine performance,
            and real-time analytics for clients, particularly those utilizing
            Alset’s portfolio companies’ AI computing infrastructure. Nom Nom’s
            specialty in data retention and disposition policies ensures data
            security and compliance, addressing crucial concerns in today’s
            digital age.
            <br />
            <br />
            The collaboration between Vertex and Nom Nom underscores the
            transformative power of AI in reshaping industries and driving
            efficiency. As organizations increasingly turn to automation and AI
            technologies to eliminate human error and streamline operations,
            data security remains paramount. This partnership addresses these
            critical needs, empowering businesses to operate with confidence in
            a secure and compliant digital environment. Nom Nom will provide a
            user interface for Vertex’s clients, the technology will allow
            Vertex to optimize its energy consumption and minimize costs.
            Vertex’s future clients will have real-time insights on how much
            power is being utilized and when. Nom Nom’s innovative technology
            provides detailed analytics and insights to optimize energy, costs,
            and performance.
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="sec_1">
          <span
            style={{
              fontFamily: "M_Bold",
            }}
          >
            {" "}
            On behalf of Alset Capital Inc.
          </span>
          <br />
          “Morgan Good“ Morgan Good Chief Executive Officer
          <br />
          <br />
          <span
            style={{
              fontFamily: "M_Bold",
            }}
          >
            {" "}
            About Alset Capital Inc.
          </span>
          <br />
          <br />
          Alset Capital Inc. is an investment issuer that is focused on
          investment in diversified industries such as technology, healthcare,
          industrial, special situations, operating businesses through both debt
          and equity using cash resources or shares in its capital. The Company
          is led by an experienced, entrepreneurial group of executives having a
          diverse industry and capital markets background.
          <br />
          <br />
          Alset Capital Inc.’s investment portfolio comprises 49% ownership of
          Cedarcross International Technologies Inc. and 49% ownership of Vertex
          AI Ventures Inc.
          <span
            style={{
              fontFamily: "M_Bold",
            }}
          >
            {" "}
            About Vertex AI Ventures Inc.
          </span>
          <br />
          <br />
          Vertex is at the forefront of intellectual property (IP)
          identification and acquisition, as well as providing AI data
          management services. The company’s mission is to discover and acquire
          innovative AI intellectual property from early-stage ventures, fueling
          advancements in artificial intelligence. In the realm of data
          management, Vertex aims to excel in delivering premier services that
          harness the power of AI to streamline operations, minimize costs, and
          secure a competitive edge in the increasingly data-driven world.
          <br />
          <br />
          The comprehensive suite of services offered by Vertex ensures superior
          data quality and security, offering advanced observability insights,
          machine performance optimization, and real-time analytics tailored to
          AI computing infrastructures. Specializing in data retention and
          disposition solutions, Vertex addresses critical issues of data
          security and compliance, setting the standard for safeguarding the
          integrity and privacy of digital information in today’s complex
          digital landscape. <br />
          <br />
          <span
            style={{
              fontFamily: "M_Bold",
            }}
          >
            {" "}
            About Nom Nom AI Inc.
          </span>
          <br />
          <br />
          Nom Nom is a pioneer in data engineering & automation as a service.
          Our knowledge and expertise delivers the best outcomes with artificial
          intelligence.  Our solutions reduce OpEx/CapEx, streamline operations,
          and ensure a competitive edge. In today’s digital landscape,
          generating an unprecedented volume of data, businesses are in dire
          need of sophisticated, real-time analytics and adaptable, scalable
          solutions. Nom Nom emerges as a leader in revolutionizing data
          management, thanks to our innovative cutting-edge approach. We address
          the complex challenge of managing, integrating, and automating vast
          volumes of data for businesses, offering services designed to simplify
          complex data ecosystems. This crucial shift and urgent need for
          advanced data management, makes Nom Nom a key partner in the
          data-driven world and artificial intelligence. 
        </div>
        <div className="sec_2">
          <span
            style={{
              fontFamily: "M_Bold",
            }}
          >
            {" "}
            Forward-Looking Statement Cautions
          </span>
          <br />
          <br />
          Certain statements in this press release may contain forward-looking
          information (within the meaning of Canadian securities legislation),
          including, without limitation, the perceived benefits of the Master
          Services Agreement amongst Vertex and Nom Nom, the demand and growth
          of the AI industry, and the consummation of the transactions
          contemplated by the Master Services Agreement . These statements
          address future events and conditions and, as such, involve known and
          unknown risks, uncertainties, and other factors, which may cause the
          actual results, performance, or achievements to be materially
          different from any future results, performance, or achievements
          expressed or implied by the statements. Forward-looking statements
          speak only as of the date those statements are made. Although the
          Company believes the expectations expressed in such forward-looking
          statements are based on reasonable assumptions, such statements are
          not guarantees of future performance and actual results may differ
          materially from those in the forward-looking statements. Factors that
          could cause the actual results to differ materially from those in
          forward-looking statements include regulatory actions, market prices,
          and continued availability of capital and financing, and general
          economic, market or business conditions. Investors are cautioned that
          any such statements are not guarantees of future performance and
          actual results or developments may differ materially from those
          projected in the forward-looking statements. Forward-looking
          statements are based on the beliefs, estimates and opinions of the
          Company’s management on the date the statements are made. Except as
          required by applicable law, the Company assumes no obligation to
          update or to publicly announce the results of any change to any
          forward-looking statement contained or incorporated by reference
          herein to reflect actual results, future events or developments,
          changes in assumptions, or changes in other factors affecting the
          forward-looking statements. If the Company updates any forward-looking
          statement(s), no inference should be drawn that it will make
          additional updates with respect to those or other forward-looking
          statements.
          <br />
          <br />
          <span
            style={{
              fontFamily: "M_Bold",
            }}
          >
            {" "}
            Neither TSX Venture Exchange nor its Regulation Services Provider
            (as that term is defined in the policies of the TSX Venture
            Exchange) accepts responsibility for the adequacy or accuracy of
            this release.
          </span>
        </div>
      </div>
    </div>
  );
}

export default PressRelease;
