import React from "react";
import close from "../../Assets/Images/CloseAlset.svg";
import "./Popup.scss";
import useInnerWidth from "../../Hooks/useInnerWidth";

function Popup({ setShowPopup, body, width, showClose, style }) {
  const screenWidth = useInnerWidth();
  // showClose={screenWidth <=768 && true}
  return (
    <div className="Main_popup">
      <div
        className={"Background_popup"}
        onClick={() => {
          setShowPopup(false);
        }}
      ></div>
      <div 

        className="Popup_division"
        style={{
          width: screenWidth < 550 ? "100%" : width ? width : "fit-content", ...style
        }}
      >
        {showClose && (
          <img
            src={close}
            alt="close"
            className="image_close_popup"
            onClick={() => {
              setShowPopup(false);
            }}
          />
        )}
        <div className="Popup_division_top">{body}</div>
      </div>
    </div>
  );
}

export default Popup;
