import React from "react";
import "./PressNews8.scss";
function PressNews8() {
  return (
    <div className="PressNews8">
      <span className="group">
        <p>Press Release</p>
        <div className="heading">
          Alset Capital Inc. Investee Company Cedarcross Technologies Announces
          Nvidia AI Server Agreement, And Signs C$2.3 Million First H100 Server
          Distribution Transaction
        </div>
        <div className="date">April 22, 2024</div>
      </span>
      <span className="group1">
        <p>
          <span className="bold">News Release Highlights:</span>
          <ul>
            <li>
              Cedarcross Technologies, Alset investee company, has entered into
              a distribution agreement with Earthmade Computer, an Authorized
              Distributor of Super Micro Computer, to provide AI companies with
              access to Nvidia H100 servers
            </li>
            <li>
              Cedarcross achieves significant milestone closing its first
              distribution transaction with Ceti AI for total revenue
              consideration of CAD$2.3 million
            </li>
          </ul>
          <span className="bold">
            VANCOUVER, British Columbia – April 22, 2024 – Alset Capital Inc.
            (TSXV:KSUM) (FSE:1R60, WKN:A3ESVQ) (“Alset” or the “Company”){" "}
          </span>{" "}
          is pleased to announce that its investee company, Cedarcross
          International Technologies Inc. (“Cedarcross”), has signed a
          Distribution Agreement (the “Agreement”), in addition to the Supply
          Agreement announced on April 4th, with CoinDigger DBA Earthmade
          Computer Corp. (“Earthmade”). This Agreement, in addition to the
          elements of the Supply Agreement, now also enables Cedarcross to
          distribute Nvidia GPU High-Performance Computing (HPC) servers, and to
          receive assistance from Earthmade with Cedarcross obtaining hardware
          allocation from Super Micro Computer, Inc. (NASDAQ:SMCI) for the
          distribution of Nvidia GPU servers. Alset has also entered into a HPC
          server purchase agreement with Big Energy Investments Inc., doing
          business as Ceti AI (“Ceti AI”). The Agreement involves the sale of
          five (5) Nvidia H100 HGX 8GPU servers that shall be sourced by
          Cedarcross prior to the sale. Such sale will generate Cedarcross
          approximately CAD$2.3M in gross revenue plus applicable taxes.
          Cedarcross intends to leverage this opportunity to purchase its first
          H100 servers from Super Micro and/or other server manufacturers, via
          Earthmade.
        </p>
        <p>
          Super Micro Computer, Inc., a global technology leader committed to
          delivering first-to-market innovation for Enterprise, Cloud, AI,
          Metaverse, and 5G Telco/Edge IT Infrastructure. Super Micro Computer,
          Inc. is renowned for delivering one of the broadest selections of
          Nvidia-Certified systems providing the most performance and efficiency
          from small enterprises to massive, unified AI training clusters with
          the new Nvidia H100 and H200 Tensor Core GPUs.
        </p>
        <p>
          Securing this distribution agreement allows for the distribution of
          Nvidia GPU HPC servers from Super Micro Computer, Inc. (NASDAQ:SMCI),
          and other server manufacturers. This partnership opens up an
          additional business vertical for Cedarcross in addition to its compute
          leasing business vertical. Cedarcross aims to demonstrate the valuable
          relationships such as this to obtain HPC hardware at competitive
          prices and expedited lead times. The Server Purchase Agreement is a
          testament to the demand in the market for Cedarcross to service this
          market, and Cedarcross aims to identity similar transactions in the
          future.
        </p>
        <div className="sub-header">
          “This transaction with Ceti AI represents a significant milestone for
          Cedarcross,” said Morgan Good, CEO of Alset. “It not only enhances
          their revenue streams, but also reinforces their market position as a
          leader in supplying high-performance computing GPU servers. Alset is
          committed to supporting Cedarcross as it continues to expand and excel
          on its business model.”
        </div>
        <div className="excception">Agreement Payment Terms</div>
        <p>
          The total contract value for the HPC servers is CAD$2.3 million + GST,
          with the following terms:
        </p>
        <ul>
          <li>
            Ceti AI will pay a deposit of CAD$140K + GST within two business
            days from the execution date of the Agreement (the “Effective
            Date”).
          </li>
          <li>
            An additional CAD$1.01M + GST will be paid within fourteen days from
            the Effective Date; and
          </li>
          <li>
            The balance of CAD$1.15M + GST is due seven days after the servers
            are received and accepted by Ceti AI, scheduled for no earlier than
            May 17th, 2024.
          </li>
        </ul>
        <p>
          The aggregate return for Cedarcross is expected to be up to CAD$240K
          subject to ordinary course chargebacks, closing costs including
          shipping and other associated expenses.
        </p>
        <p className="excception">On behalf of Alset Capital Inc.</p>
        <p>
          <span className="italic">“Morgan Good”</span>
          <br />
          Morgan Good
          <br />
          Chief Executive Officer
        </p>
        <div className="excception">About Alset Capital Inc.</div>
        <p>
          Alset Capital Inc. is an investment issuer that is focused on
          investment in diversified industries such as technology, healthcare,
          industrial, special situations, operating businesses through both debt
          and equity using cash resources or shares in its capital. The Company
          is led by an experienced, entrepreneurial group of executives having a
          diverse industry and capital markets background.
        </p>
        <p>
          Alset Capital Inc.’s investment portfolio comprises 49% ownership of
          Cedarcross International Technologies Inc. and 49% ownership of Vertex
          AI Ventures Inc.
        </p>
        <div className="excception">
          About Cedarcross International Technologies Inc.
        </div>
        <p>
          Cedarcross is an Artificial Intelligence cloud computing provider,
          with a vision of becoming one of Canada’s largest AI compute
          providers. The Company is dedicated to democratizing access to
          cutting-edge AI computing, offering access to the world’s fastest AI
          servers powered by Nvidia’s H100 HGX 8GPU Servers. Focused on leasing
          compute resources to enterprise clients, Cedarcross anticipates
          significant revenue growth.
        </p>
        <p>
          <span className="excception">
            For further information about Alset Capital Inc., please contact:
            Morgan Good, CEO and Director
          </span>
          <br />
          T: 604.715.4751
          <br />
          E: <a href="mailto:morgan@alsetai.com">morgan@alsetai.com</a>
        </p>
        <div className="excception">
          Cautionary Note regarding Forward Looking Statements
        </div>
        <p>
          Certain statements in this press release may contain forward-looking
          information (within the meaning of Canadian securities legislation),
          including, without limitation, the consummation of the transactions
          contemplated by the Agreement, the Nvidia H100 HGX 8GPU servers’
          revenue generation, growth of the global cloud AI market and the
          review and acceptance of the TSX Venture Exchange. These statements
          address future events and conditions and, as such, involve known and
          unknown risks, uncertainties, and other factors, which may cause the
          actual results, performance, or achievements to be materially
          different from any future results, performance, or achievements
          expressed or implied by the statements. Forward- looking statements
          speak only as of the date those statements are made. Although the
          Company believes the expectations expressed in such forward-looking
          statements are based on reasonable assumptions, such statements are
          not guarantees of future performance and actual results may differ
          materially from those in the forward-looking statements. Factors that
          could cause the actual results to differ materially from those in
          forward-looking statements include regulatory actions, market prices,
          and continued availability of capital and financing, and general
          economic, market or business conditions. Investors are cautioned that
          any such statements are not guarantees of future performance and
          actual results or developments may differ materially from those
          projected in the forward-looking statements. Forward-looking
          statements are based on the beliefs, estimates and opinions of the
          Company’s management on the date the statements are made. Except as
          required by applicable law, the Company assumes no obligation to
          update or to publicly announce the results of any change to any
          forward-looking statement contained or incorporated by reference
          herein to reflect actual results, future events or developments,
          changes in assumptions, or changes in other factors affecting the
          forward-looking statements. If the Company updates any forward-
          looking statement(s), no inference should be drawn that it will make
          additional updates with respect to those or other forward-looking
          statements.
        </p>
        <p className="bold">
          Neither TSX Venture Exchange nor its Regulation Services Provider (as
          that term is defined in the policies of the TSX Venture Exchange)
          accepts responsibility for the adequacy or accuracy of this release.
        </p>
        <p>
          <span className="bold">SOURCE:</span> Alset Capital Inc.
        </p>
      </span>
    </div>
  );
}

export default PressNews8;
