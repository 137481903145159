import React from "react";
import "./PressNews10.scss";
function PressNews10() {
  return (
    <div className="PressNews10">
      <div>Press Release</div>

      <div class="heading">
        Alset Capital Announces Investee Company Cedarcross Enters Into Supply
        Agreement To Purchase Nvidia AI Computing Hardware From Earthmade
        Computer Inc., An Authorized Distributor Of Super Micro Computer, Inc.
      </div>

      <div class="date">April 4, 2024</div>

      <p>
        <span className="bold">
          {" "}
          VANCOUVER, British Columbia – April 4, 2024 – Alset Capital Inc.
          (TSXV:KSUM) (FSE:1R60, WKN:A3ESVQ) (“Alset” or the “Company”)
        </span>{" "}
        is pleased to announce that its investee company, Cedarcross
        International Technologies Inc. (“Cedarcross”), has entered into a
        Supply Agreement (the “Agreement”) with CoinDigger DBA Earthmade
        Computer Corp. (“Earthmade”). This Agreement grants Cedarcross the
        authority to engage directly with Super Micro Computer, Inc.
        (NASDAQ:SMCI) for the acquisition of Nvidia GPU High Performance
        Computing (HPC) Hardware.
      </p>

      <p>
        Super Micro Computer, Inc., a global technology leader committed to
        delivering first-to-market innovation for Enterprise, Cloud, AI,
        Metaverse, and 5G Telco/Edge IT Infrastructure. Super Micro Computer,
        Inc. is renowned for delivering one of the broadest selections of
        Nvidia-Certified systems providing the most performance and efficiency
        from small enterprises to massive, unified AI training clusters with the
        new Nvidia H100 and H200 Tensor Core GPUs.
      </p>

      <p>
        The surge in demand for Nvidia GPU HPC hardware has underscored
        heightened costs and extended delivery timelines. Through this strategic
        alliance, Cedarcross secures unique access to Nvidia HPC hardware at
        competitive price points, while effectively mitigating lead time
        challenges. This enables Cedarcross to fortify its position in the
        market as it endeavors to expand its Nvidia HPC hardware fleet,
        bolstering scalability and competitiveness.
      </p>

      <p>
        “We are very enthusiastic about Cedarcross’s strategic collaboration
        with Earthmade to purchase Nvidia HPC hardware directly from Super Micro
        Computer, Inc.” commented Morgan Good, CEO of Alset. “This Agreement not
        only ensures the seamless procurement of vital AI computing hardware but
        also solidifies Cedarcross’s competitive positioning in the industry.”
      </p>

      <p>
        “This Agreement is a testament to our commitment to providing HPC AI
        hardware solutions”, said Greg Lui, CEO of Earthmade. “By facilitating
        Cedarcross’s access to Nvidia’s GPU HPC hardware, we are not only
        strengthening our relationship with Alset and Cedarcross but also
        reinforcing our position in the AI computing supply chain. We look
        forward to the innovative advancements that Cedarcross will achieve with
        this high-performance computing power.”
      </p>

      <p>The commercial terms of the Agreement are confidential.</p>
      <ol>
        <li className="italic">Super Micro Computer</li>
        <li className="italic">Tom’s Hardware</li>
      </ol>

      <div class="sub-header">On behalf of Alset Capital Inc.</div>

      <p>
        <span className="italic">“Morgan Good”</span> Morgan Good
      </p>
      <p>Chief Executive Officer</p>

      <div class="sub-header">About Alset Capital Inc.</div>

      <p>
        Alset Capital Inc. is an investment issuer that is focused on investment
        in diversified industries such as technology, healthcare, industrial,
        special situations, operating businesses through both debt and equity
        using cash resources or shares in its capital. The Company is led by an
        experienced, entrepreneurial group of executives having a diverse
        industry and capital markets background.
      </p>

      <p>
        Alset Capital Inc.’s investment portfolio comprises 49% ownership of
        Cedarcross International Technologies Inc. and 49% ownership of Vertex
        AI Ventures Inc.
      </p>

      <div class="sub-header">
        About Cedarcross International Technologies Inc.
      </div>

      <p>
        Cedarcross is an Artificial Intelligence cloud computing provider, with
        a vision of becoming one of Canada’s largest AI compute providers. The
        Company is dedicated to democratizing access to cutting-edge AI
        computing, offering access to the world’s fastest AI servers powered by
        Nvidia’s H100 HGX 8GPU Servers. Focused on leasing compute resources to
        enterprise clients, Cedarcross anticipates significant revenue growth.
      </p>

      <p className="bold">
        For further information about Alset Capital Inc., please contact:
      </p>

      <p className="bold">Morgan Good, CEO and Director</p>

      <p>T: 604.715.4751</p>

      <p>E: morgan@alsetai.com</p>

      <div class="bold">
        Cautionary Note regarding Forward Looking Statements
      </div>

      <p>
        Certain statements in this press release may contain forward-looking
        information (within the meaning of Canadian securities legislation),
        including, without limitation, the intended use of the Loan, the
        anticipated growth of the global cloud AI market and the review and
        acceptance of the TSX Venture Exchange. These statements address future
        events and conditions and, as such, involve known and unknown risks,
        uncertainties, and other factors, which may cause the actual results,
        performance, or achievements to be materially different from any future
        results, performance, or achievements expressed or implied by the
        statements. Forward-looking statements speak only as of the date those
        statements are made. Although the Company believes the expectations
        expressed in such forward-looking statements are based on reasonable
        assumptions, such statements are not guarantees of future performance
        and actual results may differ materially from those in the
        forward-looking statements. Factors that could cause the actual results
        to differ materially from those in forward-looking statements include
        regulatory actions, market prices, and continued availability of capital
        and financing, and general economic, market or business conditions.
        Investors are cautioned that any such statements are not guarantees of
        future performance and actual results or developments may differ
        materially from those projected in the forward-looking statements.
        Forward-looking statements are based on the beliefs, estimates and
        opinions of the Company’s management on the date the statements are
        made. Except as required by applicable law, the Company assumes no
        obligation to update or to publicly announce the results of any change
        to any forward-looking statement contained or incorporated by reference
        herein to reflect actual results, future events or developments, changes
        in assumptions, or changes in other factors affecting the
        forward-looking statements. If the Company updates any forward-looking
        statement(s), no inference should be drawn that it will make additional
        updates with respect to those or other forward-looking statements.
      </p>

      <p className="bold">
        Neither TSX Venture Exchange nor its Regulation Services Provider (as
        that term is defined in the policies of the TSX Venture Exchange)
        accepts responsibility for the adequacy or accuracy of this release.
      </p>
    </div>
  );
}

export default PressNews10;
