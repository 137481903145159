import React, { useEffect, useRef, useState } from "react";
import "./Navbar.scss";
import logo from "../../Assets/Images/AlsetLogoSmall.svg";
import { useNavigate } from "react-router-dom";
import useInnerWidth from "../../Hooks/useInnerWidth";
import Button from "../Button/Button";
import { FiMenu } from "react-icons/fi";
import { motion } from "framer-motion";
import { scroller } from "react-scroll";

function Navbar() {
  const navigate = useNavigate();
  const links = [
    "Cloud Computing",
    "Solutions",
    "Investors",
    "News & Media",
    "Contact Us",
  ];

  const width = useInnerWidth();

  const [open, setOpen] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    if (open) {
      ref.current.focus();
    }
  }, [open]);

  const handleClick = () => {
    navigate("/");
    setTimeout(() => {
      scroller.scrollTo("AboutAlset", {
        duration: 500,
        delay: 0,
        smooth: "easeInOut",
      });
    }, 100);
  };

  return (
    <>
      {width > 1100 ? (
        <div className="navbar_main_div">
          <img
            className="navbar_logo"
            src={logo}
            alt="logo"
            onClick={() => navigate("/")}
          />
          <div className="navbar_links">
            {links.map((link, index) => {
              if (link === "About Us") {
                return (
                  <div onClick={handleClick} className="navbar_link">
                    {link}
                  </div>
                );
              } else {
                return (
                  <div
                    className="navbar_link"
                    key={index}
                    onClick={() =>
                      navigate("/" + link.split(" ").join("-").toLowerCase())
                    }
                  >
                    {link}
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : (
        <div className="navbar_main_div">
          <img
            className="navbar_logo"
            src={logo}
            alt="logo"
            onClick={() => navigate("/")}
          />
          <div className="sidenav_bar_mobile">
            <div
              className="icon"
              onClick={() => {
                setOpen(!open);
              }}
            >
              <FiMenu size={"1.5rem"} />
            </div>
            <motion.div
              className="background"
              onClick={() => {
                setOpen(false);
              }}
              initial={{
                height: 0,
              }}
              animate={{
                height: open ? "100%" : "0",
              }}
            />
            <motion.div
              className="menu"
              initial={{
                y: -1000,
                overflow: "hidden",
              }}
              animate={{
                y: open ? 0 : -1000,
                overflow: "hidden",
                transition: {
                  duration: 0.3,
                },
              }}
              onBlur={() => {
                setOpen(false);
              }}
              ref={ref}
            >
              <div className="pages">
                {links.map((item, index) => {
                  return (
                    <motion.div
                      className="singlePage"
                      key={index}
                      onClick={() =>
                        navigate("/" + item.split(" ").join("-").toLowerCase())
                      }
                      // animate={{
                      //   color:
                      //     selectedPage === item.title.toLowerCase()
                      //       ? "#fff"
                      //       : "var(--lightWhite)",
                      // }}
                      whileHover={{
                        cursor: "pointer",
                        color: "#fff",
                      }}
                    >
                      {item}
                    </motion.div>
                  );
                })}
                <Button
                  className={"contact_us_button"}
                  whileHover={{
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/contactUs")}
                >
                  Contact Us
                </Button>
              </div>
            </motion.div>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
