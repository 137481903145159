import React, { useEffect, useState } from "react";
import "./Executive_team.scss";
import { motion } from "framer-motion";
import background_team from "../../Assets/Images/background_team.svg";
import RogerHe from "../../Assets/Images/Alset_Roger_He.png";
import facebook from "../../Assets/Images/facebook.svg";
import instagram from "../../Assets/Images/instagram.svg";
import linkedin from "../../Assets/Images/linkedin.svg";
import telegram from "../../Assets/Images/telegram.svg";
import { useLocation, useNavigate } from "react-router-dom";
import useInnerWidth from "../../Hooks/useInnerWidth";

function Executive_team() {
  const team = [
    {
      name: "Morgan Good",
      position: "CEO & Director",
      description:
        "Morgan Good is a seasoned Venture Capitalist and capital markets expert with almost two decades of experience. His expertise spans finance, M&A, corporate restructuring, development, and marketing. He has played a pivotal role in raising over $100 million CAD for numerous private and public issuers throughout his career and has served on various sector-specific boards.",
      facebook: "https://www.facebook.com/",
      linkedin: "https://www.linkedin.com/",
      instagram: "https://www.instagram.com/",
      telegram: "https://www.telegram.com/",
      image: background_team,
    },
    {
      name: "Roger He",
      position: "Head of AI Investments, Director",
      description:
        "Roger He has been in the AI sector for nearly a decade, specializing in AI high-performance computing infrastructure. He is a member of The Chartered Professional Accountants in British Columbia and Saskatchewan, as well as a member of the CFA Society in Vancouver. He holds a graduate degree from Simon Fraser University and an undergraduate degree from the University of Saskatchewan.",
      facebook: "https://www.facebook.com/",
      linkedin: "https://www.linkedin.com/",
      instagram: "https://www.instagram.com/",
      telegram: "https://www.telegram.com/",
      image: RogerHe,
    },
    // {
    //   name: "Zelong (Roger) He 1",
    //   position: "VP of Business Development & Director",
    //   description:
    //     "Mr. He is a member of The Chartered Professional Accountants in British Columbia and Saskatchewan, as well as a member of the CFA Society in Vancouver. He holds a graduate degree from Simon Fraser University and an undergraduate degree from the University of Saskatchewan. He also sits on the board of ARCpoint Inc. (TSXV:ARC)",
    //   facebook: "https://www.facebook.com/",
    //   linkedin: "https://www.linkedin.com/",
    //   instagram: "https://www.instagram.com/",
    //   telegram: "https://www.telegram.com/",
    // },
    // {
    //   name: "Zelong (Roger) He 1",
    //   position: "VP of Business Development & Director",
    //   description:
    //     "Mr. He is a member of The Chartered Professional Accountants in British Columbia and Saskatchewan, as well as a member of the CFA Society in Vancouver. He holds a graduate degree from Simon Fraser University and an undergraduate degree from the University of Saskatchewan. He also sits on the board of ARCpoint Inc. (TSXV:ARC)",
    //   facebook: "https://www.facebook.com/",
    //   linkedin: "https://www.linkedin.com/",
    //   instagram: "https://www.instagram.com/",
    //   telegram: "https://www.telegram.com/",
    // },
    // {
    //   name: "Zelong (Roger) He 1",
    //   position: "VP of Business Development & Director",
    //   description:
    //     "Mr. He is a member of The Chartered Professional Accountants in British Columbia and Saskatchewan, as well as a member of the CFA Society in Vancouver. He holds a graduate degree from Simon Fraser University and an undergraduate degree from the University of Saskatchewan. He also sits on the board of ARCpoint Inc. (TSXV:ARC)",
    //   facebook: "https://www.facebook.com/",
    //   linkedin: "https://www.linkedin.com/",
    //   instagram: "https://www.instagram.com/",
    //   telegram: "https://www.telegram.com/",
    // },
    // {
    //   name: "Zelong (Roger) He 1",
    //   position: "VP of Business Development & Director",
    //   description:
    //     "Mr. He is a member of The Chartered Professional Accountants in British Columbia and Saskatchewan, as well as a member of the CFA Society in Vancouver. He holds a graduate degree from Simon Fraser University and an undergraduate degree from the University of Saskatchewan. He also sits on the board of ARCpoint Inc. (TSXV:ARC)",
    //   facebook: "https://www.facebook.com/",
    //   linkedin: "https://www.linkedin.com/",
    //   instagram: "https://www.instagram.com/",
    //   telegram: "https://www.telegram.com/",
    // },
    // {
    //   name: "Zelong (Roger) He 1",
    //   position: "VP of Business Development & Director",
    //   description:
    //     "Mr. He is a member of The Chartered Professional Accountants in British Columbia and Saskatchewan, as well as a member of the CFA Society in Vancouver. He holds a graduate degree from Simon Fraser University and an undergraduate degree from the University of Saskatchewan. He also sits on the board of ARCpoint Inc. (TSXV:ARC)",
    //   facebook: "https://www.facebook.com/",
    //   linkedin: "https://www.linkedin.com/",
    //   instagram: "https://www.instagram.com/",
    //   telegram: "https://www.telegram.com/",
    // },
    // {
    //   name: "Zelong (Roger) He 1",
    //   position: "VP of Business Development & Director",
    //   description:
    //     "Mr. He is a member of The Chartered Professional Accountants in British Columbia and Saskatchewan, as well as a member of the CFA Society in Vancouver. He holds a graduate degree from Simon Fraser University and an undergraduate degree from the University of Saskatchewan. He also sits on the board of ARCpoint Inc. (TSXV:ARC)",
    //   facebook: "https://www.facebook.com/",
    //   linkedin: "https://www.linkedin.com/",
    //   instagram: "https://www.instagram.com/",
    //   telegram: "https://www.telegram.com/",
    // },
    // {
    //   name: "Zelong (Roger) He 1",
    //   position: "VP of Business Development & Director",
    //   description:
    //     "Mr. He is a member of The Chartered Professional Accountants in British Columbia and Saskatchewan, as well as a member of the CFA Society in Vancouver. He holds a graduate degree from Simon Fraser University and an undergraduate degree from the University of Saskatchewan. He also sits on the board of ARCpoint Inc. (TSXV:ARC)",
    //   facebook: "https://www.facebook.com/",
    //   linkedin: "https://www.linkedin.com/",
    //   instagram: "https://www.instagram.com/",
    //   telegram: "https://www.telegram.com/",
    // },
    // {
    //   name: "Zelong (Roger) He 1",
    //   position: "VP of Business Development & Director",
    //   description:
    //     "Mr. He is a member of The Chartered Professional Accountants in British Columbia and Saskatchewan, as well as a member of the CFA Society in Vancouver. He holds a graduate degree from Simon Fraser University and an undergraduate degree from the University of Saskatchewan. He also sits on the board of ARCpoint Inc. (TSXV:ARC)",
    //   facebook: "https://www.facebook.com/",
    //   linkedin: "https://www.linkedin.com/",
    //   instagram: "https://www.instagram.com/",
    //   telegram: "https://www.telegram.com/",
    // },
    // {
    //   name: "Zelong (Roger) He 1",
    //   position: "VP of Business Development & Director",
    //   description:
    //     "Mr. He is a member of The Chartered Professional Accountants in British Columbia and Saskatchewan, as well as a member of the CFA Society in Vancouver. He holds a graduate degree from Simon Fraser University and an undergraduate degree from the University of Saskatchewan. He also sits on the board of ARCpoint Inc. (TSXV:ARC)",
    //   facebook: "https://www.facebook.com/",
    //   linkedin: "https://www.linkedin.com/",
    //   instagram: "https://www.instagram.com/",
    //   telegram: "https://www.telegram.com/",
    // },
    // {
    //   name: "Zelong (Roger) He 1",
    //   position: "VP of Business Development & Director",
    //   description:
    //     "Mr. He is a member of The Chartered Professional Accountants in British Columbia and Saskatchewan, as well as a member of the CFA Society in Vancouver. He holds a graduate degree from Simon Fraser University and an undergraduate degree from the University of Saskatchewan. He also sits on the board of ARCpoint Inc. (TSXV:ARC)",
    //   facebook: "https://www.facebook.com/",
    //   linkedin: "https://www.linkedin.com/",
    //   instagram: "https://www.instagram.com/",
    //   telegram: "https://www.telegram.com/",
    // },
    // {
    //   name: "Zelong (Roger) He 2",
    //   position: "VP of Business Development & Director",
    //   description:
    //     "Mr. He is a member of The Chartered Professional Accountants in British Columbia and Saskatchewan, as well as a member of the CFA Society in Vancouver. He holds a graduate degree from Simon Fraser University and an undergraduate degree from the University of Saskatchewan. He also sits on the board of ARCpoint Inc. (TSXV:ARC)",
    //   facebook: "https://www.facebook.com/",
    //   linkedin: "https://www.linkedin.com/",
    //   instagram: "https://www.instagram.com/",
    //   telegram: "https://www.telegram.com/",
    // },
    // {
    //   name: "Zelong (Roger) He 3",
    //   position: "VP of Business Development & Director",
    //   description:
    //     "Mr. He is a member of The Chartered Professional Accountants in British Columbia and Saskatchewan, as well as a member of the CFA Society in Vancouver. He holds a graduate degree from Simon Fraser University and an undergraduate degree from the University of Saskatchewan. He also sits on the board of ARCpoint Inc. (TSXV:ARC)",
    //   facebook: "https://www.facebook.com/",
    //   linkedin: "https://www.linkedin.com/",
    //   instagram: "https://www.instagram.com/",
    //   telegram: "https://www.telegram.com/",
    // },
    // {
    //   name: "Zelong (Roger) He 4",
    //   position: "VP of Business Development & Director",
    //   description:
    //     "Mr. He is a member of The Chartered Professional Accountants in British Columbia and Saskatchewan, as well as a member of the CFA Society in Vancouver. He holds a graduate degree from Simon Fraser University and an undergraduate degree from the University of Saskatchewan. He also sits on the board of ARCpoint Inc. (TSXV:ARC)",
    //   facebook: "https://www.facebook.com/",
    //   linkedin: "https://www.linkedin.com/",
    //   instagram: "https://www.instagram.com/",
    //   telegram: "https://www.telegram.com/",
    // },
    // {
    //   name: "Zelong (Roger) He 5",
    //   position: "VP of Business Development & Director",
    //   description:
    //     "Mr. He is a member of The Chartered Professional Accountants in British Columbia and Saskatchewan, as well as a member of the CFA Society in Vancouver. He holds a graduate degree from Simon Fraser University and an undergraduate degree from the University of Saskatchewan. He also sits on the board of ARCpoint Inc. (TSXV:ARC)",
    //   facebook: "https://www.facebook.com/",
    //   linkedin: "https://www.linkedin.com/",
    //   instagram: "https://www.instagram.com/",
    //   telegram: "https://www.telegram.com/",
    // },
  ];

  const [selectedIndex, setSelectedIndex] = useState(0);

  const { pathname } = useLocation();

  const width = useInnerWidth();

  useEffect(() => {
    setSelectedIndex(0);
  }, [pathname]);

  return (
    <div className="executive_team_main">
      <div className="Executive_team_title">
        {pathname?.includes("/executive-team")
          ? "Executive Team"
          : "Board Of Directors"}
      </div>
      <div className="bottom_section">
        <div
          className="team_members"
          style={{
            ...(width < 850 && { width: "100%" }),
          }}
        >
          {team?.map((member, index) => {
            return (
              <motion.div
                className="team_member"
                key={index}
                initial={{
                  opacity: 0,
                }}
                whileInView={{
                  opacity: 1,
                }}
                animate={{
                  backgroundColor:
                    selectedIndex === index ? "#242424" : "#24242480",
                }}
                whileHover={{
                  backgroundColor: "#242424",
                }}
                onClick={() => setSelectedIndex(index)}
              >
                <div className="member_name">{member.name}</div>
                <div className="member_position">{member.position}</div>
                {width < 850 && (
                  <motion.div
                    className="mobile_desc"
                    initial={{
                      height: 0,
                      opacity: 0,
                      overflow: "hidden",
                    }}
                    animate={{
                      height: selectedIndex === index ? "auto" : 0,
                      opacity: selectedIndex === index ? 1 : 0,
                    }}
                  >
                    <div className="flex_div">
                      <img className="mobile_image" src={team[selectedIndex].image} />
                      <div className="team_description">
                        {team[selectedIndex].description}
                      </div>
                    </div>
                    <div className="bottom">
                      <img
                        src={facebook}
                        alt="facebook_icon"
                        className="icon"
                        onClick={() => {
                          window.open(team[selectedIndex].facebook, "_blank");
                        }}
                      />
                      <img
                        src={instagram}
                        alt="instagram_icon"
                        className="icon"
                        onClick={() => {
                          window.open(team[selectedIndex].instagram, "_blank");
                        }}
                      />
                      <img
                        src={linkedin}
                        alt="linkedin_icon"
                        className="icon"
                        onClick={() => {
                          window.open(team[selectedIndex].linkedin, "_blank");
                        }}
                      />
                      <img
                        src={telegram}
                        alt="telegram_icon"
                        className="icon"
                        onClick={() => {
                          window.open(team[selectedIndex].telegram, "_blank");
                        }}
                      />
                    </div>
                  </motion.div>
                )}
              </motion.div>
            );
          })}
        </div>
        {width > 850 && (
          <div className="more_details">
            <img src={team[selectedIndex].image} alt="team_img" className="team_img" />
            <div className="inner_text">
              <div className="top">
                <div className="team_name">{team[selectedIndex].name}</div>
                <div className="team_position">
                  {team[selectedIndex].position}
                </div>
                <div className="team_description">
                  {team[selectedIndex].description}
                </div>
              </div>
              <div className="bottom">
                <img
                  src={facebook}
                  alt="facebook_icon"
                  className="icon"
                  onClick={() => {
                    window.open(team[selectedIndex].facebook, "_blank");
                  }}
                />
                <img
                  src={instagram}
                  alt="instagram_icon"
                  className="icon"
                  onClick={() => {
                    window.open(team[selectedIndex].instagram, "_blank");
                  }}
                />
                <img
                  src={linkedin}
                  alt="linkedin_icon"
                  className="icon"
                  onClick={() => {
                    window.open(team[selectedIndex].linkedin, "_blank");
                  }}
                />
                <img
                  src={telegram}
                  alt="telegram_icon"
                  className="icon"
                  onClick={() => {
                    window.open(team[selectedIndex].telegram, "_blank");
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Executive_team;
