import React from "react";
import "./PressNews17.scss";
function PressNews17() {
  return (
    <div className="PressNews17">
      <div>Press Release</div>

      <div class="heading">
        Alset Capital Inc. Closes Previously Announced Debt Settlement
      </div>

      <div class="date">February 16, 2024</div>

      <p>
        <span className="bold">
          February 16, 2024 – Vancouver, Canada – ALSET CAPITAL INC.
        </span>{" "}
        (TSX-V:KSUM.H) (“<span className='bold'>ALSET</span>” or the “<span className='bold'>Company</span>”) announces that further to its
        news releases dated November 14, 2023, the Company has settled an
        aggregate of $798,093.27 of debt (the “<span className='bold'>Debt</span>”) owed to 10 creditors (the
        “<span className='bold'>Creditors</span>”) of the Company (the “<span className='bold'>Debt Settlement</span>”). In settlement of
        the Debt, the Company issued an aggregate of 15,436,863 units of the
        Company (each, a “<span className='bold'>Debt Unit</span>”) at a deemed price of CAD$0.05 per Debt
        Unit. Each Debt Unit consists of one (1) common share in the capital of
        the Company (each, a “<span className='bold'>Common Share</span>”) and one (1) Common Share purchase
        warrant (each, a “<span className='bold'>Warrant</span>”). Each Warrant will entitle the holder
        thereof to acquire an additional Common Share (each, a “<span className='bold'>Warrant Share</span>”)
        at an exercise price of CAD$0.05 per Warrant Share, subject to
        adjustment in certain circumstances, for a period of 12 months from the
        date of issuance.
      </p>

      <p>
        One of the Creditors who participated in the Debt Settlement is a
        corporation wholly owned by an officer and director of the Company (the
        “<span className='bold'>Insider</span>”). Pursuant to the policies of the TSX Venture Exchange (the
        “<span className='bold'>TSX-V</span>”) and Multilateral Instrument 61-101 – Protection of Minority
        Securityholders in Special Transactions (“<span className='bold'>MI 61-101</span>”), the Insider was
        issued Common Shares only. For greater certainty, the Company issued to
        the Insider an aggregate of 525,000 Common Shares at a deemed price of
        $0.05 per Common Share to settle $26,250.00 of the Debt. The issuance of
        the Common Shares to the Insider constitutes a “related party
        transaction” as defined in MI 61-101. The Company is relying on the
        exemption from valuation requirement and minority approval pursuant to
        subsection 5.5(a) and 5.7(1)(a) of MI 61-101, respectively, for the debt
        settlement with the Insider, as the deemed value of the Common Shares
        issued to such Insider does not represent more than 25% of the Company’s
        market capitalization, as determined in accordance with MI 61-101.
      </p>

      <p>
        All securities issued in connection with the Debt Settlement are subject
        to a statutory hold period of four months and one day following the date
        of issuance in accordance with applicable Canadian securities laws. In
        addition to the aforementioned statutory resale restrictions, an
        aggregate of 8,421,640 Common Shares (the “<span className='bold'>Escrowed Shares</span>”) issued in
        connection with the Debt Settlement are subject to a three (3) year Tier
        2 Value Security Escrow Agreement in accordance with Policy 5.4 –
        Escrow, Vendor Consideration and Resale Restrictions of the TSX-V,
        pursuant to which ten percent (10.0%) of the Escrowed Shares will be
        released concurrently with the publication of the final TSX-V bulletin
        regarding the Debt Settlement, and 15.0% of the Escrowed Shares will be
        released every six (6) months thereafter, until released in full.
      </p>

      <p>
        This press release does not constitute an offer to sell or a
        solicitation of an offer to buy any securities in the United States or
        to any “U.S. Person” (as such term is defined in Regulation S under the
        U.S. Securities Act of 1933, as amended (the “<span className='bold'>U.S. Securities Act</span>”)) of
        any equity or other securities of the Company. The securities described
        herein have not been, and will not be, registered under the U.S.
        Securities Act or under any state securities laws and may not be offered
        or sold in the United States or to a U.S. Person absent registration
        under the 1933 Act and applicable state securities laws or an applicable
        exemption therefrom. Any failure to comply with these restrictions may
        constitute a violation of U.S. securities laws.
      </p>

      <div class="sub-header">On behalf of ALSET CAPITAL INC.</div>

      <p><span className='italic'>“Morgan Good”</span> Morgan Good</p>

      <p>Chief Executive Officer</p>

      <div class="sub-header">About ALSET CAPITAL INC.</div>

      <p>
        Alset Capital Inc. is an investment issuer that is focused on investment
        in diversified industries such as technology, healthcare, industrial,
        special situations, operating businesses through both debt and equity
        using cash resources or shares in its capital. The Company is led by an
        experienced, entrepreneurial group of executives having a diverse
        industry and capital markets background.
      </p>

      <p className="bold">For further information about ALSET CAPITAL INC., please contact:</p>

      <p className="bold">Morgan Good, CEO and Director</p>

      <p>T: 604.715.4751</p>

      <p>E: morganrgood@gmail.com</p>
        <div class="sub-header">
          Cautionary Note regarding Forward Looking Statements
        </div>

        <p>
          Certain statements in this press release may contain forward-looking
          information (within the meaning of Canadian securities legislation),
          including, without limitation, the release of the Escrowed Shares
          issued in connection with the Debt Settlement and the issuance of
          Warrant Shares upon exercise of the Warrants. These statements address
          future events and conditions and, as such, involve known and unknown
          risks, uncertainties, and other factors, which may cause the actual
          results, performance, or achievements to be materially different from
          any future results, performance, or achievements expressed or implied
          by the statements. Forward-looking statements speak only as of the
          date those statements are made. Although the Company believes the
          expectations expressed in such forward-looking statements are based on
          reasonable assumptions, such statements are not guarantees of future
          performance and actual results may differ materially from those in the
          forward-looking statements. Factors that could cause the actual
          results to differ materially from those in forward-looking statements
          include regulatory actions, market prices, and continued availability
          of capital and financing, and general economic, market, or business
          conditions. Investors are cautioned that any such statements are not
          guarantees of future performance and actual results or developments
          may differ materially from those projected in the forward-looking
          statements. Forward-looking statements are based on the beliefs,
          estimates, and opinions of the Company’s management on the date the
          statements are made. Except as required by applicable law, the Company
          assumes no obligation to update or to publicly announce the results of
          any change to any forward-looking statement contained or incorporated
          by reference herein to reflect actual results, future events or
          developments, changes in assumptions, or changes in other factors
          affecting the forward-looking statements. If the Company updates any
          forward-looking statement(s), no inference should be drawn that it
          will make additional updates with respect to those or other
          forward-looking statements.
        </p>

        <p className="bold">
          Neither TSX Venture Exchange nor its Regulation Services Provider (as
          that term is defined in the policies of the TSX Venture Exchange)
          accepts responsibility for the adequacy or accuracy of this release.
        </p>
      </div>
  );
}

export default PressNews17;
