import React from "react";
import { useNavigate } from "react-router-dom";
import "./NewsCard.scss";
function NewsCard({ img, title, date, description, link, type, time }) {
  const navigate = useNavigate();
  return (
    <div className="news_card_main_div" onClick={() => link && navigate(link)}>
      <div className="image_container">
        <img src={img} alt="news" className="news_card_img" />
      </div>
      <div className="news_card_content">
        <div className="news_card_title">{title}</div>
        <div className="news_card_desc">{description}</div>
        <div className="news_card_section">
          <div className="newCard_box_section">
            <div className="news_card_date">{type}</div>
            <div className="news_card_time">{time}</div>
          </div>
          <div className="news_card_time">{date}</div>
        </div>
      </div>
    </div>
  );
}

export default NewsCard;
