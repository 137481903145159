import { useEffect } from "react";
import AllRoutes from "./AllRoutes";
import "./App.css";
import { useLocation } from "react-router-dom";

function App() {
  const pathname = useLocation();
  //on change pathname scroll to top
  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
  }, [pathname]);
  return (
    <div className="App">
      <AllRoutes />
    </div>
  );
}

export default App;
