import React from "react";
import "./PressNews7.scss";
function PressNews7() {
  return (
    <div className="PressNews7">
      <span className="group">
        <p>Press Release</p>
        <div className="heading">
          Alset Capital Inc. Announces C$5.5 Million Two-Year AI Compute Leasing
          Agreement Signed By Investee Company Cedarcross Technologies
        </div>
        <div className="date">April 25, 2024</div>
      </span>
      <span className="group1">
        <p>
          <span className="bold">
            VANCOUVER, BC / ACCESSWIRE / April 25, 2024 / Alset Capital Inc.
            (TSXV:KSUM)(FSE:1R60, WKN:A3ESVQ) (“Alset” or the “Company”){" "}
          </span>{" "}
          is pleased to announce that its 49% owned investee company, Cedarcross
          International Technologies Inc. (“Cedarcross Technologies”), has
          entered into a two-year AI Computing leasing agreement (the
          “Agreement”), with an arm’s length counterparty. The Agreement is
          expected to generate approximately CAD$5.5 million in total revenue,
          with aggregate gross margin of approximately CAD$4.4 million over the
          contract’s duration.
        </p>
        <p>
          Under the terms of the Agreement, Cedarcross Technologies will provide
          approximately 700,000 compute hours annually, totaling 1.4 million AI
          compute hours over the contract’s duration. This agreement, with an
          esteemed arm’s length third-party enterprise client, underscores
          Cedarcross Technologies growing influence and capabilities in the
          realm of AI infrastructure.
        </p>
        <p>
          The Agreement outlines a structured monthly payment plan, with
          Cedarcross Technologies expecting to receive approximately CAD$223k
          monthly.
        </p>
        <div className="excception">
          Cedarcross Technologies to Address Surging Demand for AI Computing
          Capacity
        </div>
        <p>
          Cedarcross Technologies’ mission to democratize access to
          high-performance AI computing is propelled by the overwhelming global
          demand for compute power, as highlighted in a recent interview with
          Sam Altman, CEO of OpenAI. Altman emphasized the critical role of
          computing power, suggesting that “compute” will be the “currency of
          the future.” He stressed the need for substantial investments to
          increase computing capabilities, comparing the market for computing
          chips to that of mobile phones and predicting a significant difference
          in demand.<sup>1</sup> Cedarcross Technologies, aligned with this
          vision, is strategically positioned to address this escalating demand
          by providing access to the world’s fastest AI servers, leveraging
          Nvidia’s H100 HGX GPUs.
        </p>
        <p>
          Furthermore, Cedarcross Technologies’ initiatives are in sync with the
          Canadian Government’s recent announcement of a $2.4 Billion AI Budget,
          with a substantial portion allocated to funding computing capabilities
          and technical infrastructure.<sup>2</sup> This significant investment
          underscores the critical importance of AI computing in driving
          technological innovation and economic growth.
        </p>
        <p>
          Through a strategic partnership with a major North American data
          center provider boasting over 40 facilities, Cedarcross Technologies’
          ensures seamless connectivity and reliability, supported by fiber
          optic connectivity, UPS backup, and localized computing capabilities.
          By leasing compute power to enterprise clients, Cedarcross
          Technologies’ facilitates AI workload training, driving revenue and
          scalability while meeting market demand for computing power.
        </p>
        <p style={{ fontSize: "0.8rem" }}>
          1{" "}
          <a
            rel="noopener noreferrer"
            href="https://www.tradingview.com/news/benzinga:c6a72f698094b:0-compute-not-fiat-or-bitcoin-will-be-the-currency-of-the-future-says-sam-altman-as-nvidia-s-jensen-huang-highlights-100-trillion-ai-opportunity/"
          >
            TradingView
          </a>
        </p>
        <p style={{ fontSize: "0.8rem" }}>
          2{" "}
          <a
            rel="noopener noreferrer"
            href="https://www.pm.gc.ca/en/news/news-releases/2024/04/07/securing-canadas-ai"
          >
            Securing Canada’s AI Advantage
          </a>
        </p>
        <p className="bold">On behalf of Alset Capital Inc.</p>
        <p>
          <span className="italic">“Morgan Good”</span>
          <br />
          Morgan Good
          <br />
          Chief Executive Officer
        </p>
        <div className="sub-header">About Alset Capital Inc.</div>
        <p>
          Alset Capital Inc. is an investment issuer that is focused on
          investment in diversified industries such as technology, healthcare,
          industrial, special situations, operating businesses through both debt
          and equity using cash resources or shares in its capital. The Company
          is led by an experienced, entrepreneurial group of executives having a
          diverse industry and capital markets background.
        </p>
        <p>
          Alset Capital Inc.’s investment portfolio comprises 49% ownership of
          Cedarcross International Technologies Inc. and 49% ownership of Vertex
          AI Ventures Inc.
        </p>
        <div className="sub-header">
          About Cedarcross International Technologies Inc.
        </div>
        <p>
          Cedarcross is an Artificial Intelligence cloud computing provider,
          with a vision of becoming one of Canada’s largest AI compute
          providers. The Company is dedicated to democratizing access to
          cutting-edge AI computing, offering access to the world’s fastest AI
          servers powered by Nvidia’s H100 HGX 8GPU Servers. Focused on leasing
          compute resources to enterprise clients, Cedarcross anticipates
          significant revenue growth.
        </p>
        <p>For further information about Alset Capital Inc., please contact:</p>
        <p>
          <span className="bold">Morgan Good, CEO and Director</span>
          <br />
          T: 604.715.4751
          <br />
          E: <a href="mailto:morgan@alsetai.com">morgan@alsetai.com</a>
        </p>
        <div className="bold">
          Cautionary Note regarding Forward Looking Statements
        </div>
        <p>
          Certain statements in this press release may contain forward-looking
          information (within the meaning of Canadian securities legislation),
          including, without limitation, the consummation of the transactions
          contemplated by the Agreement, the Canadian Government’s AI budget
          plan, the demand of AI computing, and the anticipated growth of the
          global cloud AI market. These statements address future events and
          conditions and, as such, involve known and unknown risks,
          uncertainties, and other factors, which may cause the actual results,
          performance, or achievements to be materially different from any
          future results, performance, or achievements expressed or implied by
          the statements. Forward-looking statements speak only as of the date
          those statements are made. Although the Company believes the
          expectations expressed in such forward-looking statements are based on
          reasonable assumptions, such statements are not guarantees of future
          performance and actual results may differ materially from those in the
          forward-looking statements. Factors that could cause the actual
          results to differ materially from those in forward-looking statements
          include regulatory actions, market prices, and continued availability
          of capital and financing, and general economic, market or business
          conditions. Investors are cautioned that any such statements are not
          guarantees of future performance and actual results or developments
          may differ materially from those projected in the forward-looking
          statements. Forward-looking statements are based on the beliefs,
          estimates and opinions of the Company’s management on the date the
          statements are made. Except as required by applicable law, the Company
          assumes no obligation to update or to publicly announce the results of
          any change to any forward-looking statement contained or incorporated
          by reference herein to reflect actual results, future events or
          developments, changes in assumptions, or changes in other factors
          affecting the forward-looking statements. If the Company updates any
          forward-looking statement(s), no inference should be drawn that it
          will make additional updates with respect to those or other
          forward-looking statements.
        </p>
        <p className="bold">
          Neither TSX Venture Exchange nor its Regulation Services Provider (as
          that term is defined in the policies of the TSX Venture Exchange)
          accepts responsibility for the adequacy or accuracy of this release.
        </p>
        <p><span className="bold">SOURCE:</span> Alset Capital Inc.</p>
      </span>
    </div>
  );
}

export default PressNews7;
