import React, { useEffect, useRef, useState } from "react";
import "./SideNavbar.scss";
import logo from "../../Assets/Images/AlsetLogoSmall.svg";
import { motion } from "framer-motion";
import arrow from "../../Assets/Images/arrow.png";
import Button from "../Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import useInnerWidth from "../../Hooks/useInnerWidth";
import { FiMenu } from "react-icons/fi";

function SideNavbar() {
  const data = [
    {
      title: "Summary",
      link: "/summary",
    },
    {
      title: "Executive Team",
      link: "/executive-team",
    },
    {
      title: "Board Of Directors",
      link: "/board-of-directors",
    },
    {
      title: "News & Media",
      link: "/news-&-media",
    },
    {
      title: "Downloads",
      link: "/downloads",
    },
  ];

  const [selectedPage, setSelectedPage] = useState("");

  const { pathname } = useLocation();

  useEffect(() => {
    const path = pathname.split("/")[1];
    setSelectedPage(path.split("-").join(" "));
  }, []);

  const navigate = useNavigate();

  const width = useInnerWidth();

  const [open, setOpen] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    if (open) {
      ref.current.focus();
    }
  }, [open]);

  return (
    <>
      {width > 1100 ? (
        <div className="side_navbar_main">
          <div className="padding_inner">
            <img
              src={logo}
              alt="Logo"
              className="logo_icon"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            />
            <div className="pages">
              {data.map((item, index) => {
                return (
                  <motion.div
                    className="singlePage"
                    key={index}
                    onClick={() => {
                      setSelectedPage(
                        item.title.split("-").join(" ").toLowerCase()
                      );
                      navigate(item.link);
                    }}
                    animate={{
                      color:
                        selectedPage === item.title.toLowerCase()
                          ? "#fff"
                          : "var(--lightWhite)",
                    }}
                    whileHover={{
                      cursor: "pointer",
                      color: "#fff",
                    }}
                  >
                    {item.title}
                  </motion.div>
                );
              })}
            </div>
            <div className="bottom_data" onClick={() => navigate("/")}>
              <motion.div
                className="go_back"
                whileHover={{
                  cursor: "pointer",
                  color: "#fff",
                }}
              >
                <img src={arrow} alt="Arrow" className="arrow_img" />
                <div className="go_back_text">Back To Site</div>
              </motion.div>
            </div>
          </div>
          <Button
            className={"contact_us_button"}
            whileHover={{
              cursor: "pointer",
            }}
            onClick={() => navigate("/contact-us")}
          >
            Contact Us
          </Button>
        </div>
      ) : (
        <div className="sidenav_bar_mobile">
          <div
            className="icon"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <FiMenu size={"1.5rem"} />
          </div>
          <motion.div
            className="background"
            onClick={() => {
              setOpen(false);
            }}
            initial={{
              height: 0,
            }}
            animate={{
              height: open ? "100%" : "0",
            }}
          />
          <motion.div
            className="menu"
            initial={{
              y: -1000,
              overflow: "hidden",
            }}
            animate={{
              y: open ? 0 : -1000,
              overflow: "hidden",
              transition: {
                duration: 0.3,
              },
            }}
            onBlur={() => {
              setOpen(false);
            }}
            ref={ref}
          >
            <div className="pages">
              <motion.div
                className="singlePage"
                onClick={() => {
                  navigate("/");
                }}
                whileHover={{
                  cursor: "pointer",
                  color: "#fff",
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <img src={arrow} alt="arrow" />
                {"Back to Site"}
              </motion.div>
              {data.map((item, index) => {
                return (
                  <motion.div
                    className="singlePage"
                    key={index}
                    onClick={() => {
                      setSelectedPage(
                        item.title.split("-").join(" ").toLowerCase()
                      );
                      navigate(item.link);
                      setOpen(false);
                    }}
                    animate={{
                      color:
                        selectedPage === item.title.toLowerCase()
                          ? "#fff"
                          : "var(--lightWhite)",
                    }}
                    whileHover={{
                      cursor: "pointer",
                      color: "#fff",
                    }}
                  >
                    {item.title}
                  </motion.div>
                );
              })}

              <Button
                className={"contact_us_button"}
                whileHover={{
                  cursor: "pointer",
                }}
                onClick={() => navigate("/contact-us")}
              >
                Contact Us
              </Button>
            </div>
          </motion.div>
        </div>
      )}
    </>
  );
}

export default SideNavbar;
