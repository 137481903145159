import React, { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import homeImg from "../../../Assets/Images/bgHome.png";
import home from "../../../Assets/videos/home.webm";
import Navbar from "../../../Components/Navbar/Navbar";
import "./Home.scss";
import HomeBody from "./HomeBody/HomeBody";
function Home() {
  const videoRef = useRef(null);
  const [ref2, inView2] = useInView({
    triggerOnce: true, // Change this to false to trigger the effect every time the video comes into view
    threshold: 0.3, // Adjust this value to determine what percentage of the video needs to be in view to trigger the effect
  });

  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px", // Adjust root margin as needed
  });

  // Function to play the video
  // Function to play the video

  // useEffect(() => {
  //   if (isPlaying) {
  //     setTimeout(() => {
  //       setReached(true);
  //     }, 4000);
  //   } else {
  //     setReached(false);
  //   }
  // }, [isPlaying]);

  useEffect(() => {
    if (inView && !inView2) {
      videoRef.current.play();
      setTimeout(() => {
        videoRef.current.pause();
      }, 1700);
    } else if (!inView) {
      videoRef.current.pause();
    }
  }, [inView, inView2]);

  //if the video is in the center of the screen, play the video
  useEffect(() => {
    if (inView2) {
      videoRef.current.play();
      setTimeout(() => {
        videoRef.current.play();
      }, 1700);
    }
  }, [inView2]);
  console.log(inView2);
  return (
    <div className="home_main_div" ref={ref}>
      <Navbar />

      <div className="overLay"></div>

      <HomeBody />
      <span ref={ref2}>
        <video
          // autoPlay
          className="main_video_sec1"
          ref={videoRef}
          muted
          playsInline
          poster={homeImg}
        >
          <source src={home} type="video/mp4" />
        </video>
      </span>

      <div className="home_footerbox"></div>
    </div>
  );
}

export default Home;
