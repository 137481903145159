import React from "react";
import "./CloudComputing.scss";
import cedarsCross from "../../Assets/Images/cedarcross.svg";
import computingImage from "../../Assets/Images/computingimage.svg";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
function CloudComputing() {
  const navigate = useNavigate();
  return (
    <div className="CloudComputing">
      <Navbar />
      <div className="cloud-computing-hero">
        <div className="hero-tag">Cloud Computing</div>
        <div className="hero-title">High-Performance AI Computing</div>
        <p>
          Our portfolio company, Cedarcross’s mission is to democratize access
          to high-performance AI computing.
          <br />
          Cedarcross offers access to the world’s fastest AI servers, heralding
          a new era of technological advancement. By
          <br />
          offering access to the world’s fastest AI servers, powered by NVIDIA’s
          H100 HGX GPUs, Cedarcross empowers
          <br />
          enterprises with computing capabilities, with computing capabilities of approximately 700,000 hours, with
          plans to increase its computings
          <br />
          capabilities in the future.
        </p>
        <img
          src={cedarsCross}
          alt="CloudComputing"
          style={{ width: "30rem" }}
        />
      </div>
      <div className="home_footerbox"></div>

      <div className="cloud-computing-body">
        <div className="upper">
          <div className="left-section">
            <img
              src={computingImage}
              alt="computingImage"
              style={{ width: "100%" }}
            />
          </div>
          <div className="right-section">
            <div className="text">
              Through strategic collaboration with a leading North American data
              center provider,boasting an extensive network of over 40
              facilities across key markets, Cedarcross
              <br />
              ensures seamless connectivity and reliability. This partnership
              fortifies Cedarcross’s infrastructure with essential features such
              as fiber optic connectivity, UPS backup, and
              <br />
              localized computing capabilities.
              <br />
              <br />
              <br />
              Cedarcross’s strategic approach to leasing compute power to
              enterprises clients demonstrates a clear pathway to revenue
              growth. By facilitating the training of AI
              <br />
              workloads, Cedarcross plans to generate cash flows and scale its
              hardware infrastructure fleet to meet evolving market demand for
              compute power. Leveraging its industry-leading partners,
              Cedarcross is poised to drive streamlined growth and innovation in
              the
              <br />
              AI sector.
            </div>
          </div>
        </div>
        <div className="lower">
          <div className="contact">
            <div className="left-section">
              <div>Discover the Power of Cloud Computing</div>
              <p
                style={{
                  fontSize: "1rem",
                }}
              >
                Contact the Alset team to get started.
              </p>
            </div>
            <div className="right-section">
              <button
                className="submit"
                onClick={() => navigate("/contact-us")}
              >
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CloudComputing;
