import React from "react";
import "./PressNews11.scss";
function PressNews11() {
  return (
    <div className="PressNews11">
      <div>Press Release</div>

      <div class="heading">
        Alset Capital Inc. Announces Secured Loan To AI Investee Company
        Cedarcross International Technologies, Cedarcross To Purchase 10 Nvidia
        H100 HGX Servers
      </div>

      <div class="date">April 2, 2024</div>

      <div class="highlights">
        <span className="bold italic">News Release Highlights:</span>
        <ul>
          <li>
            Alset provides $3.7 million secured loan to Cedarcross to purchase
            10 Nvidia H100 HGX 8GPU servers capable of 700,000 compute hours per
            annum.
          </li>
          <li>
            Alset’s portfolio company, Cedarcross, aims to become leading AI
            cloud computing provider, planning to leverage industry network and
            capital to grow aggressively.
          </li>
          <li>
            At a billable rate of USD $2.80 per chip hour, 10 Nvidia H100 HGX
            8GPU servers are capable of compute leasing revenue of approx. USD
            $2 million per annum, with associated anticipated net gross margin
            of approx. USD $1.6 million per annum.
          </li>
          <li>
            With the rapid ascent in deploying AI infrastructure, Alset stands
            out as one of the first publicly listed companies with exposure to
            an AI computing hub fully installed and operating in a Tier 3 data
            center, through its 49% ownership stake in Cedarcross.
          </li>
        </ul>
      </div>

      <p>
        <span className="bold">
          VANCOUVER, British Columbia – April 2, 2024
        </span>{" "}
        – Alset Capital Inc. (TSX-V:KSUM) (FSE:1R60, WKN:A3ESVQ) (“Alset” or the
        “Company”) is pleased to announce it has entered into a secured loan
        agreement (the “Loan Agreement”) with Cedarcross International
        Technologies Inc. (the “Cedarcross”) with respect to a loan for a
        principal amount of $3,700,000 (the “Loan”), to purchase 10 Nvidia H100
        HGX 8GPU servers capable of leasing 700,000 compute hours. The Company
        is also pleased to share a corporate and operational update from its
        investee company Cedarcross.
      </p>

      <div class="sub-header">Cedarcross Corporate & Operational Update</div>

      <p>
        Cedarcross’s vision is to become one of Canada’s largest
        high-performance AI computing providers.
      </p>

      <p>
        Cedarcross’s mission is to democratize access to high-performance AI
        computing through offering Venture-backed, telecommunications, and other
        such companies with access to leading Nvidia hardware. Cedarcross offers
        access to the world’s fastest AI computing servers, which are arguably
        heralding a new era of technological advancement, and as such, the next
        industrial revolution. Upon completion of Cedarcross’s purchase order,
        its fleet of 10 H100s will be capable of 700,000 compute hours per
        annum.
      </p>

      <p>
        Five of the ten servers have arrived and are configured in a Tier 3 data
        center located in the Vancouver, British Columbia. The data center is
        owned and operated by a leading North American data center provider,
        boasting an extensive network of over 40+ facilities across key markets.
        The data center offers Cedarcross essential features such as fiber optic
        connectivity, UPS backup, and localized computing capabilities, which
        facilitates high speed connectivity to better serve its clients.
      </p>

      <p>
        Cedarcross plans to facilitate the training of AI models and Large
        Language Models (“LLM”), and other such advanced computing workloads.
        Cedarcross aims to scale its hardware infrastructure fleet to meet
        evolving market demand for compute. Leveraging its industry-leading
        partners, Cedarcross is poised to drive streamlined growth and
        innovation in the AI sector.
      </p>

      <p>
        Cedarcross’s initial AI computing hub of 5 Nvidia H100 HGX servers is
        fully installed and are interconnected using the Infiniti brand network.
        Each server is linked to eight 400GB optical modules, ensuring optimal
        efficiency between the servers. Cedarcross expects receipt and
        installation of the additional five Nvidia HGX H100 GPU servers in the
        coming weeks.
      </p>

      <p>
        Cedarcross has the foresight and knowledge of the lead time and
        execution required to build AI computing hubs, extending beyond simply
        the acquisition of GPU servers. Over the last several months, Cedarcross
        has worked to source, order, set up, and initiate this AI computing hub.
        Powered by the Nvidia HGX H100 8GPU servers, Cedarcross boasts GPU
        infrastructure that when fully delivered and installed, is expected to
        immediately start to generate USD ~$2 million per annum, with associated
        anticipated net cash inflows of USD ~$1.6 million per annum.
      </p>

      <div class="sub-header">Alset Capital’s Management Commentary</div>

      <p>
        In consideration of the structuring of the Loan, Alset’s management and
        board of directors reviewed arguably similar transactions in the AI
        sector including Magnetar Capital’s and Blackstone’s $2.3B debt facility
        provided to CoreWeave in 2023, which facility was secured by Nvidia
        GPUs.
      </p>

      <p>
        “The strategic alliance and loan between Alset and Cedarcross signifies
        our commitment to fostering innovation in the AI sector,” said Morgan
        Good, CEO of Alset. “By providing Cedarcross with the necessary
        financial support, we aim to facilitate the growth of cutting-edge
        technologies that have the potential to revolutionize a multitude of
        industries.”
      </p>

      <p>
        The global cloud AI market size is projected to grow from $60.35 billion
        in 2023 to $397.81 billion by 2030, at a CAGR of 30.9% during the
        forecast period.
      </p>

      <p>
        In a recent interview, Sam Altman, the CEO of OpenAI, shared his
        thoughts on the future of technology, emphasizing the significance of
        computing power. He thinks “compute” will be the “currency of the
        future” instead of fiat or cryptocurrency king. Altman, in a
        conversation on the Lex Fridman Podcast, expressed his belief that
        “compute” will become the most valuable commodity in the world. He
        stressed the need for substantial investments to increase computing
        capabilities. Altman compared the market for computing chips to that of
        mobile phones, noting that the demand for compute will be significantly
        different. He suggested that the world’s need for compute will be
        difficult to predict and that the price of the technology will heavily
        influence it.
      </p>

      <div class="sub-header">Transaction Overview</div>

      <p>
        Alset has agreed to provide a $3,700,000 secured loan to Cedarcross. The
        Loan will be secured against all present and after acquired property of
        Cedarcross, including specific asset security against five Nvidia 8GPU
        HGX H100 Servers. The Loan is repayable in full on July 1, 2025 (the
        “Maturity Date”) and will bear interest at a rate of 15% per annum.
        Interest is payable every quarter with the first payment due July 1,
        2024.
      </p>

      <p>
        As security for the payment of the Cedarcross’s obligations and
        fulfilment and satisfaction of all covenants and agreements made under
        the Loan Agreement, Cedarcross has, concurrently with the Loan
        Agreement, entered into a general security agreement with the Company
        pursuant to which the Cedarcross granted the Company a security interest
        in specific property of the Cedarcross. The Company is not issuing any
        securities, or paying any bonus, commission or finder’s fees on the
        loan.
      </p>

      <p>
        The Company has a 49% ownership stake in Cedarcross and is a “related
        party” of the Company within the meaning of Multilateral Instrument
        61-101 – Protection of Minority Security Holders in Special Transactions
        (“MI 61-101”). As such, the Loan constitutes a “related party
        transaction” within the meaning of MI 61-101. The Company is relying on
        the exemption from valuation requirement and minority approval pursuant
        to subsection 5.5(a) and 5.7(a) of MI 61-101, respectively, as the
        principal amount of the Loan does not represent more than 25% of the
        Company’s market capitalization, as determined in accordance with MI
        61-101.
      </p>

      <p>
        The Loan Agreement is subject to review and acceptance by the TSX
        Venture Exchange.
        <ol>
          <li>Reuters</li>
          <li>Fortune Business Insights</li>
          <li>Tradingview</li>
        </ol>
      </p>

      <div class="sub-header">On behalf of Alset Capital Inc.</div>

      <p>
        <span className="italic">“Morgan Good”</span> Morgan Good
      </p>

      <p>Chief Executive Officer</p>

      <div class="sub-header">About Alset Capital Inc.</div>

      <p>
        Alset Capital Inc. is an investment issuer that is focused on investment
        in diversified industries such as technology, healthcare, industrial,
        special situations, operating businesses through both debt and equity
        using cash resources or shares in its capital. The Company is led by an
        experienced, entrepreneurial group of executives having a diverse
        industry and capital markets background.
      </p>

      <div class="sub-header">About Cedarcross Technologies Inc.</div>

      <p>
        Cedarcross is an Artificial Intelligence cloud computing provider, with
        a vision of becoming one of Canada’s largest AI compute providers. The
        Company is dedicated to democratizing access to cutting-edge AI
        computing, offering access to the world’s fastest AI servers powered by
        Nvidia’s H100 HGX 8GPU Servers. Focused on leasing compute resources to
        enterprise clients, Cedarcross anticipates significant revenue growth.
      </p>

      <p className="bold">
        For further information about Alset Capital Inc., please contact:
      </p>

      <p className="bold">Morgan Good, CEO and Director</p>

      <p>T: 604.715.4751</p>

      <p>E: morgan@alsetai.com</p>

      <div class="sub-header">
        Cautionary Note regarding Forward Looking Statements
      </div>

      <p>
        Certain statements in this press release may contain forward-looking
        information (within the meaning of Canadian securities legislation),
        including, without limitation, the intended use of the Loan, the
        anticipated growth of the global cloud AI market and the review and
        acceptance of the TSX Venture Exchange. These statements address future
        events and conditions and, as such, involve known and unknown risks,
        uncertainties, and other factors, which may cause the actual results,
        performance, or achievements to be materially different from any future
        results, performance, or achievements expressed or implied by the
        statements. Forward-looking statements speak only as of the date those
        statements are made. Although the Company believes the expectations
        expressed in such forward-looking statements are based on reasonable
        assumptions, such statements are not guarantees of future performance
        and actual results may differ materially from those in the
        forward-looking statements. Factors that could cause the actual results
        to differ materially from those in forward-looking statements include
        regulatory actions, market prices, and continued availability of capital
        and financing, and general economic, market or business conditions.
        Investors are cautioned that any such statements are not guarantees of
        future performance and actual results or developments may differ
        materially from those projected in the forward-looking statements.
        Forward-looking statements are based on the beliefs, estimates and
        opinions of the Company’s management on the date the statements are
        made. Except as required by applicable law, the Company assumes no
        obligation to update or to publicly announce the results of any change
        to any forward-looking statement contained or incorporated by reference
        herein to reflect actual results, future events or developments, changes
        in assumptions, or changes in other factors affecting the
        forward-looking statements. If the Company updates any forward-looking
        statement(s), no inference should be drawn that it will make additional
        updates with respect to those or other forward-looking statements.
      </p>

      <p className="bold">
        Neither TSX Venture Exchange nor its Regulation Services Provider (as
        that term is defined in the policies of the TSX Venture Exchange)
        accepts responsibility for the adequacy or accuracy of this release.
      </p>
    </div>
  );
}

export default PressNews11;
