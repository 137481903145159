import React, { useEffect } from "react";
import "./SummaryPage.scss";
import table2 from "../../Assets/Images/table2.svg";
import image1 from "../../Assets/Images/unparalelledAccess.svg";
import image2 from "../../Assets/Images/leadingNational.svg";
import image3 from "../../Assets/Images/DirectAccess.svg";
import image4 from "../../Assets/Images/ExperiencedManagement.svg";
import image5 from "../../Assets/Images/CompoundingGrowth.svg";
import image6 from "../../Assets/Images/boxes-in-box.svg";

const SummaryPage = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbol: "TSXV:KSUM",
      width: "100%",
      height: "100%",
      locale: "en",
      dateRange: "12M",
      hideSymbolLogo: false,
      colorTheme: "dark",
      trendLineColor: "rgba(172, 149, 142, 1)",
      underLineColor: "rgba(172, 149, 142, 0.7)",
      underLineBottomColor: "rgba(172,149,142,0.11)",
      isTransparent: true,
      autosize: true,
      largeChartUrl: "",
      chartOnly: false,
    });

    document
      .querySelector(".tradingview-widget-container__widget")
      ?.appendChild(script);

    const script2 = document.createElement("script");

    script2.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js";
    script2.type = "text/javascript";
    script2.async = true;
    script2.innerHTML = JSON.stringify({
      symbol: "GETTEX:1R60",
      width: "100%",
      height: "100%",
      locale: "en",
      dateRange: "12M",
      hideSymbolLogo: false,
      colorTheme: "dark",
      trendLineColor: "rgba(172, 149, 142, 1)",
      underLineColor: "rgba(172, 149, 142, 0.7)",
      underLineBottomColor: "rgba(172,149,142,0.11)",
      isTransparent: true,
      autosize: true,
      largeChartUrl: "",
      chartOnly: false,
    });

    document
      .querySelector(".tradingview-widget-container__widget2")
      ?.appendChild(script2);

    const script3 = document.createElement("script");

    script3.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js";
    script3.type = "text/javascript";
    script3.async = true;
    script3.innerHTML = JSON.stringify({
      symbol: "OTC:ALSCF",
      width: "100%",
      height: "100%",
      locale: "en",
      dateRange: "12M",
      hideSymbolLogo: false,
      colorTheme: "dark",
      trendLineColor: "rgba(172, 149, 142, 1)",
      underLineColor: "rgba(172, 149, 142, 0.7)",
      underLineBottomColor: "rgba(172,149,142,0.11)",
      isTransparent: true,
      autosize: true,
      largeChartUrl: "",
      chartOnly: false,
    });

    document
      .querySelector(".tradingview-widget-container__widget3")
      ?.appendChild(script3);
  }, []);

  const cards = [
    {
      img: image1,
      title: "Unparalleled Access to Hardware",
      desc: `Alset has established relationships with suppliers for Nvidia Hardware, for attractive pricing (30% discount) and estimated delivery in under 3 months (vs Industry Avg. of 9 months)`,
    },
    {
      img: image2,
      title: "Leading National Data Centre Infrastructure",
      desc: `Secured data centre and power capacity from leading national provider, allowing for immediate hosting upon machinery delivery`,
    },
    {
      img: image3,
      title: "Direct Access Customer Pipeline",
      desc: `Forging direct relationships with end-customers with significant cloud computing demand (avoiding middlemen for sales)`,
    },
    {
      img: image4,
      title: "Experienced Management Team",
      desc: `Highly specialized in the field of AI cloud computing, bringing extensive expertise and knowledge to the forefront of their operations`,
    },
    {
      img: image5,
      title: "Compounding Growth from Immediate Cash Flow",
      desc: `Secured a contract to lease 700,000 computing hours to an enterprise client, anticipating USD $4M in gross revenue throughout the agreement`,
    },
    {
      img: image6,
      title: "PaaS Strategy to Build Long Term Value",
      desc: `Strategy to provide platform as a service (PaaS) offerings to clients seeking product efficiency and optimization`,
    },
  ];

  return (
    <div className="SummaryPage">
      <div className="right-section">
        <div className="summary_main">
          <div className="tradingview-widget-container">
            <div
              className="tradingview-widget-container__widget"
              style={{ height: "50vh" }}
            ></div>
            <div
              className="tradingview-widget-container__widget3"
              style={{ height: "50vh" }}
            ></div>
            <div
              className="tradingview-widget-container__widget2"
              style={{ height: "50vh" }}
            ></div>
          </div>
          {/* <div className="tables">
            <div className="table_1">
              <div className="table_1_title">Summary</div>
              <div className="table_1_body">
                <div className="single_row">
                  <div className="left_data">
                    <div className="left_data_title">Open</div>
                    <div className="left_data_value">0.25</div>
                  </div>
                  <div className="right_data">
                    <div className="right_data_title">Market Cap</div>
                    <div className="right_data_value">12.40M</div>
                  </div>
                </div>
                <div className="single_row">
                  <div className="left_data">
                    <div className="left_data_title">High</div>
                    <div className="left_data_value">0.29</div>
                  </div>
                  <div className="right_data">
                    <div className="right_data_title">52-wk Hight</div>
                    <div className="right_data_value">0.31</div>
                  </div>
                </div>
                <div className="single_row">
                  <div className="left_data">
                    <div className="left_data_title">Low</div>
                    <div className="left_data_value">0.23</div>
                  </div>
                  <div className="right_data">
                    <div className="right_data_title">52-wk low</div>
                    <div className="right_data_value">0.22</div>
                  </div>
                </div>
                <div className="single_row">
                  <div className="left_data">
                    <div className="left_data_title">Close</div>
                    <div className="left_data_value">0.28</div>
                  </div>
                  <div className="right_data">
                    <div className="right_data_title">P/E Ratio</div>
                    <div className="right_data_value">-</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="table_2">
              <div className="table_2_title">
                <div className="title_text">Most Recent News</div>
                <div className="title_sub_text">View all</div>
              </div>
              <div className="table_2_body">
                <img src={table2} alt="table_2" className="table_2_img" />
                <div className="table_2_texts">
                  <div className="text_1">
                    Alset Capital Inc. Announces Completion Of $0.03 Units And
                    Convertible Debenture Private Placements For $581,374.91
                  </div>
                  <div className="text_2">
                    November 28, 2023 - Vancouver, Canada - ALSET CAPITAL INC.
                    (TSX-V:KSUM.H) (“ALSET” or the “Company”) is pleased to
                    announce that it has ….
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="left-section">
        <div className="title">Investment Highlights</div>
        <div className="cards">
          {cards.length > 0 &&
            cards.map((card, index) => (
              <div className="card" key={index}>
                <div className="card-image">
                  <img src={card.img} alt="image1" />
                </div>
                <div className="card-title">{card.title}</div>
                <div className="card-text">{card.desc}</div>
                {index < cards.length - 1 && <hr className="card-hr" />}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SummaryPage;
