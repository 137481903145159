import React from "react";
import "./PressNews15.scss";
function PressNews15() {
  return (
    <div className="PressNews15">
      <div class="header">Press Release</div>

      <div class="heading">
        Alset Capital Inc. Announces Closing Of $4.77M First Tranche Of $6M
        Non-Brokered Private Placement
      </div>

      <div class="date">March 15, 2024</div>

      <p>
        <span className="bold" >VANCOUVER, British Columbia</span> – March 15, 2024 –  <span className="bold" >Alset Capital Inc.</span>
        (TSX-V:KSUM.H) (“ <span className="bold" >Alset</span>” or the “ <span className="bold" >Company</span>”) is pleased to announce that it
        has completed the first tranche of its non-brokered private placement of
        units of the Company (the “ <span className="bold" >Units</span>”) at a price of $0.15 per Unit, for
        aggregate gross proceeds of $4,777,323 (the “ <span className="bold" >Offering</span>”).
      </p>

      <p>
        Each Unit is comprised of one (1) Common Share and one-half of one
        Common Share purchase warrant (each, whole warrant, a “ <span className="bold" >Warrant</span>”). Each
        Warrant entitles the holder thereof to acquire one (1) additional Common
        Share (each, a “ <span className="bold" >Warrant Share</span>”) at a price of $0.25 per Warrant Share
        for a period of 36 months from the date of issuance.
      </p>

      <p>
        In connection with closing of the first tranche of the Offering, the
        Company paid finders’ fees of approximately $289,696.88 and issued an
        aggregate of 1,711,154 Common Share purchase warrants (the “ <span className="bold" >Finders’
        Warrants</span>”) to eligible finders. Each Finders’ Warrant entitles the
        holder thereof to acquire one (1) Common Share (each, a “ <span className="bold" >Finders’
        Warrant Share</span>”) at a price of $0.15 per Finders’ Warrant Share for a
        period of 36 months from the date of issuance.
      </p>

      <p>
        The Company intends on using the net proceeds from the Offering for
        capital expenditures, working capital, and general corporate purposes.
      </p>

      <p>
        All securities issued pursuant to the Offering are subject to a
        statutory hold period of four (4) months plus one (1) day from the date
        of issuance, in accordance with applicable securities legislation.
      </p>

      <p>
        This press release does not constitute an offer to sell or a
        solicitation of an offer to buy any securities in the United States or
        to any “U.S. Person” (as such term is defined in Regulation S under the
        U.S. Securities Act of 1933, as amended (the “ <span className="bold" >U.S. Securities Act</span>”)) of
        any equity or other securities of the Company. The securities described
        herein have not been, and will not be, registered under the U.S.
        Securities Act or under any state securities laws and may not be offered
        or sold in the United States or to a U.S. Person absent registration
        under the 1933 Act and applicable state securities laws or an applicable
        exemption therefrom. Any failure to comply with these restrictions may
        constitute a violation of U.S. securities laws.
      </p>

      <div class="sub-header">On behalf of Alset Capital Inc.</div>

      <p> <span className="italic" >“Morgan Good”</span> Morgan Good</p>

      <p>Chief Executive Officer</p>

      <div class="sub-header">About Alset Capital Inc.</div>

      <p>
        Alset Capital Inc. is an investment issuer that is focused on investment
        in diversified industries such as technology, healthcare, industrial,
        special situations, operating businesses through both debt and equity
        using cash resource or shares in its capital. The Company is led by an
        experienced, entrepreneurial group of executives having a diverse
        industry and capital markets background.
      </p>

      <p className="bold">For further information about Alset Capital Inc., please contact:</p>

      <p className="bold">Morgan Good, CEO and Director</p>

      <p>T: 604.715.4751</p>

      <p>E: morgan@alsetai.com</p>

        <div class="sub-header">
          Cautionary Note regarding Forward Looking Statements
        </div>

        <p>
          Certain statements in this press release may contain forward-looking
          information (within the meaning of Canadian securities legislation),
          including, without limitation, the intended use of proceeds, the
          issuance of the Warrant Shares upon exercise of the Warrants and the
          issuance of the Finders’ Warrant Shares upon exercise of the Finders’
          Warrants. These statements address future events and conditions and,
          as such, involve known and unknown risks, uncertainties, and other
          factors, which may cause the actual results, performance, or
          achievements to be materially different from any future results,
          performance, or achievements expressed or implied by the statements.
          Forward-looking statements speak only as of the date those statements
          are made. Although the Company believes the expectations expressed in
          such forward-looking statements are based on reasonable assumptions,
          such statements are not guarantees of future performance and actual
          results may differ materially from those in the forward-looking
          statements. Factors that could cause the actual results to differ
          materially from those in forward-looking statements include regulatory
          actions, market prices, and continued availability of capital and
          financing, and general economic, market, or business conditions.
          Investors are cautioned that any such statements are not guarantees of
          future performance and actual results or developments may differ
          materially from those projected in the forward-looking statements.
          Forward-looking statements are based on the beliefs, estimates, and
          opinions of the Company’s management on the date the statements are
          made. Except as required by applicable law, the Company assumes no
          obligation to update or to publicly announce the results of any change
          to any forward-looking statement contained or incorporated by
          reference herein to reflect actual results, future events or
          developments, changes in assumptions, or changes in other factors
          affecting the forward-looking statements. If the Company updates any
          forward-looking statement(s), no inference should be drawn that it
          will make additional updates with respect to those or other
          forward-looking statements.
        </p>

        <p className="bold">
          Neither TSX Venture Exchange nor its Regulation Services Provider (as
          that term is defined in the policies of the TSX Venture Exchange)
          accepts responsibility for the adequacy or accuracy of this release.
        </p>
      </div>
  );
}

export default PressNews15;
