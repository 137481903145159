import React from "react";
import "./PressNews12.scss";
function PressNews12() {
  return (
    <div className="PressNews12">
      <div>Press Release</div>

      <div class="heading">
        Alset Capital Inc. Announces Frankfurt Stock Exchange Listing And
        Engagement Of Corporate Awareness Providers, Including Omni8
        Communications Inc.
      </div>

      <div class="date">March 27, 2024</div>

      <p>
        <span className="bold">VANCOUVER, British Columbia – March 27, 2024 – Alset Capital Inc.
        (TSX-V:KSUM) (FSE:1R60) (“Alset” or the “Company”)</span> is pleased to
        announce the listing of the common shares in the capital of the Company
        (the “Common Shares”) on the Frankfurt Stock Exchange (the “FSE“) under
        the trading symbol “1R60“, WKN: A3ESVQ. The Common Shares are now
        cross-listed on the Toronto Stock Exchange Venture (the “TSXV”) in
        Canada, and the FSE in Germany.
      </p>

      <p>
        The FSE is one of the world’s largest international trading centers for
        securities. Operated by the Deutsche Boerse AG, FSE is the largest of
        Germany’s seven stock exchanges, and is responsible for approximately 90
        percent of all securities traded in Germany. The FSE facilitates
        advanced electronic trading, settlement, and information systems and
        enables cross-border trading for international investors.
      </p>

      <div class="sub-header">Corporate Awareness Engagement</div>

      <p>
        The Company is also pleased to announce that it has engaged Omni8
        Communications Inc. DBA Omni8 Global (“<span className="bold">Omni8</span>”), a content-focused
        digital marketing agency to increase investor awareness and interest in
        the Company as well as attracting new investors through various online
        platforms and a comprehensive digital media marketing campaign for the
        Company. The services to be provided by Omni8 will include assisting
        Alset to develop a corporate marketing strategy and provide marketing
        and public relations advisory services, help coordinate marketing, news
        flow, and events, making introductions to Omni8’s network of media
        contacts, internet and social media marketers, and other such providers,
        as well as to assist with various other public relations efforts. The
        services provided will be facilitated by way of digital venues such as
        programmatic digital marketing, social media marketing, select
        television broadcasts and radio advertising, email marketing, influencer
        outreach, and placement of related marketing content on public websites.
      </p>

      <p>
        The agreement is for a 2-month term commencing March 27, 2024, with a
        cost of $100,000. The principal of Omni8 is Ryan Johnson, 1075 West
        Georgia Street, V6E 3C9, Phone: 1-855-939-2189; Email:
        ryan@omni8media.com. Omni8 and its principals are arm’s length to the
        Company. No stock options are being granted to Omni8 under the terms of
        its engagement, and to the knowledge of the Company, Omni8 does not own
        any Common Shares or other securities of the Company. The governing
        agreement described herein is subject to TSXV approval and may be
        renewed or extended by the Company and Omni8 at the end of the initial
        term.
      </p>

      <p>
        The Company also announces that it has engaged Aktiencheck.de AG
        (“<span className ="bold">Aktiencheck</span>“) to provide a European marketing awareness program.
        Services include editorial write-ups and distribution, standalone email
        marketing campaigns, search engine marketing, and native advertising.
        These efforts will be focused on disseminating editorial reports to
        active investors. Additionally, a specialized German Social Media
        campaign will target financial investors, distributing editorial content
        across relevant platforms such as aktiencheck.de and others.
      </p>

      <p>
        The agreement is for a 2-month term commencing April 8, 2024, with a
        cost of The principal of Aktiencheck is Stefan Lindam, Phone: +49 2661
        9890020; Email: stefan.lindam@aktiencheck.de. Aktiencheck and its
        principals are arm’s length to the Company. No stock options are being
        granted to Aktiencheck under the terms of its engagement, and to the
        knowledge of the Company, Aktiencheck does not own any Common Shares or
        other securities of the Company. The governing agreement described above
        is subject to TSXV approval and may be renewed or extended at the end of
        the initial term.
      </p>

      <p>
        The Company also announces that it has engaged Shore Thing Media, LLC.
        (“<span className ="bold">STM</span>“) to coordinate with the Company’s management concerning marketing
        ideas, re-distribution of press releases, and memorandum describing the
        Company’s business together with such other materials as may be
        reasonably required for marketing of the Company.
      </p>

      <p>
        The agreement is for a 2-month term commencing April 8, 2024, with a
        cost of The principal of STM is Michael Mctigue, 625 Abbott Avenue, Toms
        River, NJ 08753, Phone: ; Email: fnmmichael@gmail.com. STM and its
        principals are arm’s length to the Company. No stock options are being
        granted to STM under the terms of its engagement, and to the knowledge
        of the Company, STM does not own any Common Shares or other securities
        of the Company. The governing agreement described above is subject to
        TSXV approval.
      </p>

      <div class="sub-header">On behalf of Alset Capital Inc.</div>

      <p><span className="italic">“Morgan Good”</span> Morgan Good</p>

      <p>Chief Executive Officer</p>

      <div class="sub-header">About Alset Capital Inc.</div>

      <p>
        Alset Capital Inc. is an investment issuer that is focused on investment
        in diversified industries such as technology, healthcare, industrial,
        special situations, operating businesses through both debt and equity
        using cash resources or shares in its capital. The Company is led by an
        experienced, entrepreneurial group of executives having a diverse
        industry and capital markets background.
      </p>

      <p className="bold">For further information about Alset Capital Inc., please contact:</p>

      <p className="bold">Morgan Good, CEO and Director</p>

      <p>T: 604.715.4751</p>

      <p>E: morgan@alsetai.com</p>

        <div class="sub-header">
          Cautionary Note regarding Forward Looking Statements
        </div>

        <p>
          Certain statements in this press release may contain forward-looking
          information (within the meaning of Canadian securities legislation),
          including, without limitation, the intended services to be provided by
          each of Omni8, Aktiencheck, and STM, the consideration to be paid to
          each of Omni8, Aktiencheck, and STM, and the Company obtaining TSXV
          approval. These statements address future events and conditions and,
          as such, involve known and unknown risks, uncertainties, and other
          factors, which may cause the actual results, performance, or
          achievements to be materially different from any future results,
          performance, or achievements expressed or implied by the statements.
          Forward-looking statements speak only as of the date those statements
          are made. Although the Company believes the expectations expressed in
          such forward-looking statements are based on reasonable assumptions,
          such statements are not guarantees of future performance and actual
          results may differ materially from those in the forward-looking
          statements. Factors that could cause the actual results to differ
          materially from those in forward-looking statements include regulatory
          actions, market prices, and continued availability of capital and
          financing, and general economic, market or business conditions.
          Investors are cautioned that any such statements are not guarantees of
          future performance and actual results or developments may differ
          materially from those projected in the forward-looking statements.
          Forward-looking statements are based on the beliefs, estimates, and
          opinions of the Company’s management on the date the statements are
          made. Except as required by applicable law, the Company assumes no
          obligation to update or to publicly announce the results of any change
          to any forward-looking statement contained or incorporated by
          reference herein to reflect actual results, future events or
          developments, changes in assumptions, or changes in other factors
          affecting the forward-looking statements. If the Company updates any
          forward-looking statement(s), no inference should be drawn that it
          will make additional updates with respect to those or other
          forward-looking statements.
        </p>

        <p className="bold">
          Neither TSX Venture Exchange nor its Regulation Services Provider (as
          that term is defined in the policies of the TSX Venture Exchange)
          accepts responsibility for the adequacy or accuracy of this release.
        </p>
      </div>
  );
}

export default PressNews12;
