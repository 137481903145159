import React from "react";
import "./PressNews.scss";
import cardImg from "../../../../Assets/Images/news2.png";
function PressNews() {
  return (
    <div className="PressNews">
      <div className="container_with_image">
        <img src={cardImg} alt="" />
        <div>
          <span className="group">
            <div class="heading">
              Alset Capital Inc. Provides Corporate And Operational Update
            </div>
            <div class="date">May 29, 2024</div>
          </span>
          <span className="group1">
            <p>
              <span class="bold" style={{ paddingLeft: "0px" }}>
                VANCOUVER, British Columbia – May 29, 2024 – Alset Capital Inc.
                (TSXV:KSUM) (OTC:ALSCF) (FSE:1R60, WKN:A3ESVQ) (“Alset” or the
                “Company”)
              </span>{" "}
              is pleased to provide a corporate and operational update.
            </p>
            <p>
              It has been a busy and exciting year so far for Alset as the
              Company continues to grow its Artificial Intelligence investment
              portfolio, focusing on generating long-term value for
              shareholders. We are pleased to report that this year to date, our
              portfolio companies have made some significant advancements. Alset
              seeks to build a vertically integrated portfolio in the AI sector,
              and aims to be a value-add partner in the businesses it invests
              in.
            </p>

            <div class="sub-header">Corporate Update</div>

            <div class="bold">Artificial Intelligence Advisory Board</div>
            <p>
              On May 23, 2024, the Company announced the formation of its
              Artificial Intelligence Advisory Board (“AI Advisory Board”),
              alongside the appointments of former Google and Panasonic AI
              Executive Willy Sun and Web 3.0 Pioneer Michael Gord. The AI
              Advisory Board will assist the Company’s board of directors and
              executive officers to provide strategic guidance and insights on
              the Company’s AI initiatives. Comprising diverse experts, the
              board will advise on AI strategy, identify emerging trends, ensure
              compliance with ethical standards, manage risks, evaluate AI
              performance, and foster innovation.
            </p>
            <div class="bold">Financing</div>
            <p>
              In March and May of 2024, Alset completed non-brokered private
              placements for total gross proceeds of approximately $7.6 million.
            </p>

            <div class="bold">Stock Exchange Listings</div>
            <p>
              On March 27, 2024, the Company announced the listing of its common
              shares (the “Common Shares”) on the Frankfurt Stock Exchange (the
              “FSE”) under the trading symbol “1R60”, WKN: A3ESVQ, and ISIN:
              CA02115L2003.
            </p>
            <p>
              On May 2, 2024, the Company announced that its Common Shares have
              commenced trading on the OTC Market under the symbol “ALSCF”
              effective April 26, 2024. Additionally, the Company has received
              Depository Trust Company (DTC) eligibility in the United States,
              facilitating easier and broader trading access for US investors.
            </p>
            <p>
              The Common Shares are currently listed on the Toronto Stock
              Exchange Venture (the “TSXV”) in Canada, the OTC in the U.S., and
              the FSE in Germany.
            </p>
          </span>
        </div>
      </div>
      <span className="group1">
        <div class="sub-header">Operational Update</div>

        <div class="bold">
          Cedarcross International Technologies Inc. (“Cedarcross”)
        </div>
        <p>
          The Company’s investee company, Cedarcross, has made significant
          strides in its pursuit to become a major AI compute provider.
        </p>
        <p>
          On April 2, 2024, the Company announced a $3.7 million secured loan
          (the “Loan”) to Cedarcross to purchase 10 Nvidia H100 HGX 8GPU servers
          capable of 700,000 compute hours per annum. The Loan is secured
          against all present and after acquired property of Cedarcross,
          including specific asset security against five (5) Nvidia 8GPU HGX
          H100 Servers (“Nvidia H100 Servers”). The Loan is repayable in full on
          July 1, 2025, and will bear interest at a rate of 15% per annum.
        </p>

        <div class="bold">AI Computing Leasing</div>
        <p>
          On April 25, 2024, Cedarcross entered into a two-year AI computing
          leasing agreement expected to generate approximately $5.5 million in
          total revenue, with aggregate gross margin of approximately $4.4
          million over the contract’s duration. Cedarcross will provide
          approximately 700,000 compute hours annually, totaling 1.4 million AI
          compute hours over the contract’s duration. This agreement underscores
          Cedarcross’ growing influence and capabilities in the realm of AI
          infrastructure.
        </p>
        <p>
          As highlighted in a recent interview with Sam Altman, CEO of OpenAI,
          Altman emphasized the critical role of computing power, suggesting
          that “compute” will be the “currency of the future.”<sup>1</sup>{" "}
          Cedarcross, aligned with this vision, is strategically positioned to
          address this escalating demand by providing access to the world’s
          fastest AI servers, leveraging Nvidia’s H100 HGX GPUs.
        </p>
        <p>
          Cedarcross has established an AI computing hub, powered by Nvidia H100
          Servers. Currently, five (5) Nvidia H100 Servers are fully installed
          and interconnected using Infiniti brand network components. Each
          server is linked to eight 400GB optical modules, ensuring optimal
          efficiency. Cedarcross sold five (5) of its previously purchased
          Nvidia H100 Servers to Big Energy Investments Inc., DBA Ceti AI (“Ceti
          AI”), (as described in HPC Computing Server Distribution section). The
          proceeds from this sale funded a purchase order made through
          CoinDigger DBA Earthmade Computer Corp. (“Earthmade”) for five (5)
          additional Nvidia H100 Servers from Super Micro Computer, Inc.
          (NASDAQ: SMCI), solidifying Cedarcross’ relationships with both
          Earthmade and Super Micro Computer, Inc.
        </p>
        <p>
          Cedarcross has begun accruing revenue from its initial five (5) Nvidia
          H100 Servers and anticipates incremental revenue from its additional
          five (5) Nvidia H100 Servers to start accruing soon after the delivery
          and installation of the additional servers sourced from Earthmade and
          Super Micro Computer, Inc. (NASDAQ: SMCI).
        </p>

        <div class="bold">HPC Computing Server Distribution</div>
        <p>
          On April 22, 2024, Cedarcross entered into a Distribution Agreement
          incremental to its previously announced Supply Agreement with
          Earthmade. The Distribution Agreement enables Cedarcross to distribute
          Nvidia GPU High-Performance Computing (HPC) servers and receive
          assistance from Earthmade in obtaining hardware allocation from Super
          Micro Computer, Inc. (NASDAQ:SMCI).
        </p>
        <p>
          Additionally, Cedarcross entered into an HPC server purchase agreement
          with Ceti AI for the sale of five (5) Nvidia H100 HGX 8GPU servers,
          generating approximately C$2.3 million in gross revenue. The aggregate
          return for Cedarcross is expected to be up to $240K, subject to
          ordinary course chargebacks and associated expenses.
        </p>
        <p>
          On May 16, 2024, Cedarcross signed an agreement with an arm’s length
          counterparty to distribute 64 Nvidia H100 Servers, generating $26
          million in revenue for Cedarcross. The estimated gross margin from
          this agreement will be approximately $2.3 million.
        </p>
        <p>
          Cedarcross has now secured cumulative revenue year-to-date of
          approximately $28 million through its HPC server distribution business
          vertical alone. These agreements underscore the robust demand and
          strategic market positioning of Cedarcross in the HPC landscape.
        </p>

        <div class="bold">Vertex AI Ventures Inc. (“Vertex”)</div>
        <p>
          On May 21, 2024, Vertex announced a strategic partnership with Nom Nom
          AI Inc., formalized through a Master Services Agreement. This
          collaboration focuses on AI data engineering and automation to enhance
          business intelligence and innovation. Vertex aims to offer advanced
          data management tools, while Nom Nom specializes in data engineering,
          security, and automation, providing solutions to streamline
          operations, reduce expenses, and maintain competitive edges. This
          partnership will benefit future clients by improving data
          observability, providing real-time analytics, and optimizing machine
          performance.
        </p>

        <div class="sub-header">Outlook</div>
        <p>
          Cedarcross aims to secure additional HPC Server Distribution
          contracts, service its computing leasing client, and leverage expected
          and future cash flows to acquire additional servers to grow its
          computing leasing business. Cedarcross is also exploring
          compute-for-equity structured transactions in the event of excess
          compute capacity with future HPC servers, potentially lending
          significant upside to Cedarcross.
        </p>
        <p>
          Vertex plans to leverage its partnership with Nom Nom to drive
          innovation in managed data services and AI, enhancing data quality,
          security, and infrastructure performance. This collaboration is
          expected to bring forth numerous benefits for future clients,
          including enhanced data observability, insights, optimization of
          machine performance, and real-time analytics, particularly for those
          utilizing Alset’s portfolio companies’ AI computing infrastructure.
        </p>
        <p>
          The Company is also actively seeking potential accretive investments,
          acquisitions, and/or business alliances in other AI-focused sectors,
          including data centres, power infrastructure, HPC server cooling
          systems, and energy usage reduction software.
        </p>

        <p>1 TradingView</p>

        <p className="italic">
          All amounts in Canadian Dollars unless otherwise specified.
        </p>

        <p className="bold">On behalf of Alset Capital Inc.</p>

        <p>
          <span class="italic" style={{ paddingLeft: "0px" }}>
            “Morgan Good”{" "}
          </span>
          <span>Morgan Good</span>
          <br />
          Chief Executive Officer
        </p>

        <div class="sub-header">About Alset Capital Inc.</div>
        <p>
          Alset Capital Inc. is an investment issuer that is focused on
          investment in diversified industries such as technology, healthcare,
          industrial, special situations, operating businesses through both debt
          and equity using cash resources or shares in its capital. The Company
          is led by an experienced, entrepreneurial group of executives having a
          diverse industry and capital markets background.
        </p>
        <p>
          Alset Capital Inc.’s investment portfolio comprises 49% ownership of
          Cedarcross International Technologies Inc. and 49% ownership of Vertex
          AI Ventures Inc.
        </p>

        <div class="sub-header">
          About Cedarcross International Technologies Inc.
        </div>
        <p>
          Cedarcross Technologies is an Artificial Intelligence cloud computing
          provider, with a vision of becoming one of Canada’s largest AI compute
          providers. The Company is dedicated to democratizing access to
          cutting-edge AI computing, offering access to the world’s fastest AI
          servers powered by Nvidia’s H100 HGX 8GPU Servers. Focused on leasing
          compute resources to enterprise clients, Cedarcross Technologies
          anticipates significant revenue growth.
        </p>

        <div class="sub-header">About Vertex AI Ventures Inc.</div>
        <p>
          Vertex is at the forefront of intellectual property (IP)
          identification and acquisition, as well as providing AI data
          management services. The company’s mission is to discover and acquire
          innovative AI intellectual property from early-stage ventures, fueling
          advancements in artificial intelligence. In the realm of data
          management, Vertex aims to excel in delivering premier services that
          harness the power of AI to streamline operations, minimize costs, and
          secure a competitive edge in the increasingly data-driven world.
        </p>
        <p>
          The comprehensive suite of services offered by Vertex ensures superior
          data quality and security, offering advanced observability insights,
          machine performance optimization, and real-time analytics tailored to
          AI computing infrastructures. Specializing in data retention and
          disposition solutions, Vertex addresses critical issues of data
          security and compliance, setting the standard for safeguarding the
          integrity and privacy of digital information in today’s complex
          digital landscape.
        </p>

        <p>
          <span class="bold" style={{ paddingLeft: "0px" }}>
            For further information about Alset Capital Inc., please contact:
          </span>
          <br />
          <span className="bold" style={{ paddingLeft: "0px" }}>
            Morgan Good, CEO and Director
          </span>
          <br />
          T: 604.715.4751
          <br />
          E: <a href="mailto:morgan@alsetai.com">morgan@alsetai.com</a>
        </p>

        <div class="bold">Forward-Looking Statement Cautions</div>
        <p>
          Certain statements in this press release may contain forward-looking
          information (within the meaning of Canadian securities legislation),
          including, without limitation, the perceived benefits of the Master
          Services Agreement amongst Vertex and Nom Nom, the demand and growth
          of the AI industry, the consummation of the transactions contemplated
          by the Master Services Agreement, Cedarcross’s repayment of the Loan
          and accrued interest thereon, possible investments by the Company in
          additional investee companies, the consummation of the transactions
          contemplated by the Distribution Agreement, the consummation of the
          transactions contemplated by the agreements with Earthmade and Super
          Micro Computer, Inc. and the perceived revenues to be derived from the
          transactions disclosed herein.. These statements address future events
          and conditions and, as such, involve known and unknown risks,
          uncertainties, and other factors, which may cause the actual results,
          performance, or achievements to be materially different from any
          future results, performance, or achievements expressed or implied by
          the statements. Forward-looking statements speak only as of the date
          those statements are made. Although the Company believes the
          expectations expressed in such forward-looking statements are based on
          reasonable assumptions, such statements are not guarantees of future
          performance and actual results may differ materially from those in the
          forward-looking statements. Factors that could cause the actual
          results to differ materially from those in forward-looking statements
          include regulatory actions, market prices, and continued availability
          of capital and financing, and general economic, market or business
          conditions. Investors are cautioned that any such statements are not
          guarantees of future performance and actual results or developments
          may differ materially from those projected in the forward-looking
          statements. Forward-looking statements are based on the beliefs,
          estimates and opinions of the Company’s management on the date the
          statements are made. Except as required by applicable law, the Company
          assumes no obligation to update or to publicly announce the results of
          any change to any forward-looking statement contained or incorporated
          by reference herein to reflect actual results, future events or
          developments, changes in assumptions, or changes in other factors
          affecting the forward-looking statements. If the Company updates any
          forward-looking statement(s), no inference should be drawn that it
          will make additional updates with respect to those or other
          forward-looking statements.
        </p>
        <p className="bold">
          Neither TSX Venture Exchange nor its Regulation Services Provider (as
          that term is defined in the policies of the TSX Venture Exchange)
          accepts responsibility for the adequacy or accuracy of this release.
        </p>
      </span>
    </div>
  );
}

export default PressNews;
