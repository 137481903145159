import React from "react";
import "./PressNews19.scss";
function PressNews19() {
  return (
    <div className="PressNews19">
      <div>Press Release</div>
      <div className="heading">
        Alset Capital Inc. Announces Investment In Vertex AI Ventures Inc.
      </div>
      <div className="date">January 11, 2024</div>
      <p>
        <span className="bold">
          VANCOUVER, British Columbia – January 11, 2024 –

        Alset Capital Inc. (TSXV: KSUM.H) (“Alset”
        or the “Company”)</span> is pleased to announce
        that it has entered into a share exchange agreement (the “
        <span className="bold">Agreement</span>”) dated January 9, 2024, between
        the Company, Vertex AI Ventures Inc. (“
        <span className="bold">Vertex</span>”) and the shareholders of Vertex
        (the “<span className="bold">Vertex Shareholders</span>”), pursuant to
        which the Company will acquire 49.0% of the current issued and
        outstanding common shares in the capital of Vertex (“
        <span className="bold">Vertex Shares</span>”) (the “
        <span className="bold">Transaction</span>”).
      </p>
      <div className="sub-header">About Vertex</div>
      <p>
        Vertex is a Canadian-based artificial intelligence company focused on
        the identification, acquisition, development, and licensing of
        intellectual property (“<span className="bold">IP</span>”) within the
        artificial intelligence (“<span className="bold">AI</span>”) cloud
        computing industry. Additionally, Vertex provides leading data
        management services.
      </p>
      <p>
        Vertex provides solutions to meet the IP and data management
        requirements of businesses. This includes the identification and
        acquisition of IP assets and offering tailored licensing solutions to
        meet the unique needs of clients. Additionally, Vertex delivers Data
        Management Services, encompassing data engineering, automation,
        observability, secure data storage, and seamless integration with other
        systems.
      </p>
      <p>
        Vertex is led by Nikolaos (Niko) Kontogiannis. Mr. Kontogiannis,
        renowned for his entrepreneurial acumen and contributions to the fintech
        sector, has a proven track record of success. As the co-founder and
        financier of VersaPay, he played a pivotal role in establishing VersaPay
        as one of North America’s top emerging growth companies, as published in
        Profit Magazine. He also co-founded and financed PayFirma Corporation,
        the first fintech company to deploy mobile payments on smartphones.
      </p>
      <p>
        In 2018, Mr. Kontogiannis orchestrated a successful merger and
        acquisition of PayFirma Corporation by Merrco Payments Inc., positioning
        the company as a global leader in high-risk payment processing for the
        cannabis industry and regulated businesses. Adding to his
        accomplishments, Mr. Kontogiannis launched his family office, PENCK
        Global, and pioneered the concept of “Bitcoin friendly” in the eCommerce
        space through his earlier venture in 2011.
      </p>
      <p className="italic">
        “We are thrilled to have Alset onboard as an investor and strategic
        partner in our company,” said Niko Kontogiannis, Director of Vertex.
        “Together, we aim to create a meaningful synergy that not only
        strengthens our IP portfolio but also enhances our ability to provide
        cutting-edge data management services to a broader range of customers. I
        am confident that our combined expertise will propel us to the forefront
        of the rapidly evolving artificial intelligence landscape.”
      </p>
      <div className="sub-header">About the Proposed Transaction</div>
      <p>
        Pursuant to the terms of the Agreement, the Company will acquire 49.0%
        of the issued and outstanding Vertex Shares (the “
        <span className="bold">Vertex Interest</span>”). As consideration for
        the Vertex Interest, the Company will issue an aggregate of 12,000,000
        common shares in the capital of the Company (the “
        <span className="bold">Payment Shares</span>”) at a deemed price of
        $0.05 per Payment Share, for total consideration of $600,000. As
        currently proposed, concurrently with closing of the Transaction, the
        Company will enter into a right of first refusal agreement (the “
        <span className="bold">ROFR</span>”) to acquire the balance of the
        Vertex Shares. The Company’s ROFR will commence on closing of the
        Transaction and will be valid for a two (2) year period.
      </p>
      <p>
        Completion of the proposed Transaction is subject to a number of
        conditions, including but not limited to, TSX Venture Exchange,
        including the NEX Board (collectively, the “
        <span className="bold">Exchange</span>”) acceptance. There can be no
        assurance that the Transaction will be completed as proposed or at all.
      </p>
      <p>
        All securities issued in connection with the Transaction are subject to
        a statutory hold period of four (4) months and one (1) day from the date
        of issuance, in accordance with applicable securities legislation.
      </p>
      <p>
        The securities of the Company referred to in this news release have not
        been and will not be registered under the United States Securities Act
        of 1933, as amended (the “
        <span className="bold">U.S. Securities Act</span>“), or any state
        securities laws. Accordingly, the securities of the Company may not be
        offered or sold within the United States unless registered under the
        U.S. Securities Act and applicable state securities laws or pursuant to
        an exemption from the registration requirements of the U.S. Securities
        Act and applicable state securities laws. This news release does not
        constitute an offer to sell or a solicitation of any offer to buy any
        securities of the Company in any jurisdiction in which such offer,
        solicitation or sale would be unlawful.
      </p>
      Upon closing of the Transaction, the Company will have completed its
      second investment in the AI sector. “We are excited to join forces with
      Vertex and Niko,” commented Morgan Good, Chief Executive Officer of the
      Company. “This strategic investment aligns with our commitment to
      providing shareholders of Alset with exposure to a diverse range of
      investments in the AI sector. Vertex’s expertise in AI intellectual
      property and data management compliments our investment in Cedarcross
      International Technologies Inc. made last year.”
      <div className="sub-header">About Alset Capital Inc.</div>
      <p>
        Alset is an investment issuer that is focused on investment in
        diversified industries such as technology, health care, industrial and
        special situations, and operating businesses through both debt and
        equity using cash resources or shares in its capital. The company is led
        by an experienced, entrepreneurial group of executives having a diverse
        industry and capital market background.
      </p>
      <div className="sub-header">On Behalf of the Board of Directors</div>
      <p>
        <span className="italic">“Morgan Good”</span> Morgan Good, Director
      </p>
      <p className="bold">FOR FURTHER INFORMATION, PLEASE CONTACT</p>
      <p>Alset Capital Inc.</p>
      <p>Tel: 604-715-4751</p>
      <p>Email: morganrgood@gmail.com</p>
      <div className="sub-header">Forward-Looking Statement Cautions</div>
      <p className="italic">
        Certain statements contained in this news release may be deemed
        “forward–looking statements” within the meaning of applicable Canadian
        securities laws. These forward–looking statements, by their nature,
        require Alset and Vertex to make certain assumptions and necessarily
        involve known and unknown risks and uncertainties that could cause
        actual results to differ materially from those expressed or implied in
        these forward–looking statements. Forward–looking statements are not
        guarantees of performance. Words such as “may”, “will”, “would”,
        “could”, “expect”, “believe”, “plan”, “anticipate”, “intend”,
        “estimate”, “continue”, or the negative or comparable terminology, as
        well as terms usually used in the future and the conditional, are
        intended to identify forward–looking statements. Information contained
        in forward–looking statements, including, but not limited to, the future
        outlook and anticipated events, consummation and timing of the proposed
        Transaction, the issuance of the Payment Shares, the approval of the
        Exchange, the satisfaction of conditions precedent to the Transaction
        and the execution of the right of first refusal agreement, are based
        upon certain material assumptions that were applied in drawing a
        conclusion or making a forecast or projection, including management’s
        perceptions of historical trends, current conditions and expected future
        developments, public disclosure from operators of the relevant mines, as
        well as other considerations that are believed to be appropriate in the
        circumstances. Alset and Vertex consider their respective assumptions to
        be reasonable based on information currently available but cautions the
        reader that their assumptions regarding future events, many of which are
        beyond the control of Alset and Vertex, may ultimately prove to be
        incorrect since they are subject to risks and uncertainties that affect
        Alset and Vertex, and their respective businesses.
      </p>
      <p className="italic">
        The forward–looking statements set forth herein concerning Alset reflect
        management’s expectations as at the date of this news release and are
        subject to change after such date. Alset and Vertex disclaim any
        intention or obligation to update or revise any forward–looking
        statements, whether as a result of new information, future events or
        otherwise, other than as required by law.
      </p>
      <p className="italic bold">
        Neither TSX Venture Exchange nor its Regulations Services Provider (as
        that term is defined in the policies of the TSX Venture Exchange)
        accepts responsibility for the adequacy or accuracy of this news
        release.
      </p>
    </div>
  );
}

export default PressNews19;
