import React from "react";
import "./Solutions.scss";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import solutionsImage from "../../Assets/Images/solutionsImage.svg";

function Solutions() {
  return (
    <div className="Solutions">
      <div className="header">
        <Navbar />
        <div className="solutions-hero">
          <div className="hero-tag">Solutions</div>
          <div className="hero-title">
            AI Intellectual Property &<br /> Data Management Solutions
          </div>
          <p>
            Alset’s investment portfolio of companies are committed to offering
            solutions that redefine
            <br />
            the AI industry. Discover how they’re harnessing the power of AI to
            build the future, today.
          </p>
          <img
            src={solutionsImage}
            alt="Solutions"
            style={{ width: "30rem" }}
          />
        </div>
      </div>
      <div className="home_footerbox"></div>

      <div className="solutions-body">
        <div className="upper">
          <div className="left-section">
            <div className="title">
              AI Intellectual Property & Data management
            </div>
          </div>
          <div className="right-section">
            <div className="text">
              Our portfolio company , Vertex AI Ventures is focused on
              identifying and acquiring intellectual property (IP) and providing
              data management services. In the realm of intellectual property
              (IP), Vertex AI Ventures is focused on the identification and
              acquisition of innovative AI IP from early-stage ventures. On the
              Data Management front, Vertex AI Ventures pioneers in providing
              top-tier data management services. By leveraging the power of
              artificial intelligence, the company provides innovative solutions
              designed to streamline operations, reduce both operational and
              capital expenditures, and secure a competitive advantage in our
              increasingly data-centric world.
              <br />
              <br />
              <br />
              The suite of services offered by Vertex AI Ventures empowers
              clients with superior data quality and security, alongside
              advanced observability insights. These offerings are further
              enhanced by the optimization of machine performance and the
              provision of real-time analytics, specifically tailored for
              clients leveraging AI computing infrastructure. Specializing in
              data retention and disposition solutions, Vertex AI Ventures
              addresses key concerns of data security and compliance, marking
              itself as a pioneer in safeguarding the integrity and privacy of
              digital information in today’s complex digital landscape.
              <br />
              <br />
              <br />
              Vertex AI Ventures is led by Niko Kontogiannis. Mr. Kontogiannis,
              a serial entrepreneur and fintech pioneer, co-founded and financed
              VersaPay, a leading North American growth company. He then played
              a key role in founding and financing PayFirma, the first fintech
              company to implement mobile payments on smartphones. In 2018, he
              facilitated the M&A with Merrco, establishing a global leadership
              position in high-risk payment processing for the Cannabis industry
              and regulated businesses. He launched his family office, PENCK, in
              2018.
            </div>
          </div>
        </div>
        <div className="lower">
          <div className="contact">
            <div className="left-section">
              <div
                style={{
                  fontFamily: "M_Bold",
                  fontSize: "3rem",
                }}
              >
                Explore Our Solutions
              </div>
              <p
                style={{
                  fontSize: "1rem",
                }}
              >
                Whether you’re looking to enhance your business with our
                offerings or want to learn more, we’re here to help.
              </p>
            </div>
            <div className="right-section">
              <button className="submit">Contact Us</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Solutions;
