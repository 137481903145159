import React, { useEffect, useState } from "react";
import "./Section4.scss";
import Button from "../../../Components/Button/Button";
import clipBoard from "../../../Assets/Images/clipBoard.svg";
import OurMission from "../OurMission/OurMission";
import News from "../News/News";
import { useNavigate } from "react-router-dom";
import jsonp from "jsonp";
import MailchimpSubscribe from "react-mailchimp-subscribe";

function Section4() {
  const navigate = useNavigate();
  const [velocity] = useState(0.5);
  const [error, setError] = useState("");
  const url =
    "//alsetai.us22.list-manage.com/subscribe/post?u=03d65d06cf556c4db612e3545&amp;id=a4860d2f81&amp;f_id=0060cee1f0";

  useEffect(() => {
    const update = () => {
      const pos =
        document.documentElement.scrollTop + 100 ||
        document.body.scrollTop + 100 ||
        0;
      document.querySelectorAll(".section4_main_div").forEach((element) => {
        const $element = element;
        const height = $element.clientHeight;
        $element.style.backgroundPosition = `50% ${Math.round(
          (height - pos) * velocity
        )}px`;
      });
    };

    window.addEventListener("scroll", update);

    return () => {
      window.removeEventListener("scroll", update);
    };
  }, [velocity]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error]);
  const CustomForm = ({ status, message, onValidated }) => {
    let email, name;
    const submit = () =>
      email &&
      name &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
        FULLNAME: name.value,
        tags: 7878,
      });

    return (
      <>
        <form
          className="section4_content_content2"
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          <div className="section4_content_sec3_input1_div">
            <input
              className="section4_content_sec3_input1"
              id="mce-FULLNAME"
              name="FULLNAME"
              placeholder="Full Name"
              required
              type="text"
              ref={(node) => (name = node)}
            />
          </div>
          <div className="section4_content_sec3_input2_div">
            <input
              className="section4_content_sec3_input2"
              placeholder="Email Address"
              type="email"
              id="mce-EMAIL"
              name="EMAIL"
              required
              ref={(node) => (email = node)}
            />
          </div>
          <Button className="subscribe_btn" animate>
            Subscribe
          </Button>
        </form>
        {status === "error" && (
          <div dangerouslySetInnerHTML={{ __html: message }} />
        )}
        {status === "success" && (
          <div dangerouslySetInnerHTML={{ __html: message }} />
        )}
      </>
    );
  };
  return (
    <div className="section4_main_div">
      <div className="section4_content">
        <div className="section4_content_sec1">
          <div className="section4_content_sec1_left">
            <div className="section4_content_sec1_title" id="AboutAlset">
              About Alset
            </div>
            <div className="section4_content_sec1_subtitle">
              Alset leads the charge in pioneering AI technologies. With our
              strategic investments and deep industry expertise, we’re not just
              part of the AI revolution; we’re driving it.
            </div>
            <div className="section4_content_sec1_left_box">
              <span className="section4_content_sec1_left_box_text">
                Our Investment Portfolio
              </span>
            </div>
          </div>
          <div className="section4_content_sec1_right">
            <Button
              className="learn_more_btn"
              onClick={() => navigate("/investors")}
              animate
            >
              Learn More
            </Button>
          </div>
        </div>

        <div className="section4_content_sec2">
          <div className="section4_content_sec2_box">
            <div className="overlay_section4"></div>

            <div className="section4_content_sec2_box_title">Cedarcross</div>
            <div className="section4_content_sec2_box_subtitle">
              Cedarcross’ mission is to democratize access to high-performance
              AI computing by providing access to the world’s fastest AI
              servers, equipped with NVIDIA’s H100 HGX GPUs. The company
              supports enterprises by offering significant computing
              capabilities, currently at 700,000 hours per annum, with plans for
              future expansion.
            </div>
          </div>
          <div className="section4_content_sec2_box">
            <div className="section4_content_sec2_box_title">
              Vertex AI Ventures
            </div>
            <div className="section4_content_sec2_box_subtitle">
              Vertex AI Venture's is focused on identifying, acquiring, and
              licensing intellectual property (IP) and providing data management
              services. Vertex AI Ventures tailored solutions address the
              evolving IP and data management needs of businesses, offering
              cutting-edge data engineering, automation, secure storage, and
              seamless integration services. Vertex AI Ventures enables clients
              to unlock the full potential of their IP and data assets in
              today’s complex digital landscape.
            </div>
          </div>
        </div>

        <div className="section4_content_sec3">
          <div className="section4_content_content1">
            <div className="section4_content_sec3_text">
              <div className="section4_content_sec1_title">
                Subscribe To Our Newsletter & Stay Updated
              </div>
              <div className="section4_content_sec1_subtitle">
                Subscribe for updates on our company progress and industry news.
              </div>
            </div>
            <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                <CustomForm
                  status={status}
                  message={message}
                  onValidated={(formData) => subscribe(formData)}
                />
              )}
            />
            {error && <div className="error">{error}</div>}
          </div>
          <img
            src={clipBoard}
            className="section4_content_sec3_clipboard"
            alt="clipBoard"
          />
        </div>

        <OurMission />
        <News />
      </div>
    </div>
  );
}

export default Section4;
