import React, { useState } from "react";
import "./Download.scss";
import { motion } from "framer-motion";
import download from "../../Assets/Images/download.svg";

function Download() {
  const section = [
    // "General Information",
    // "AGM Materials",
    "Financials",
    // "Corporate Governance",
  ];

  const [selectedDownload, setSelectedDownload] = useState(0);
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch("../../../files/Alset-Q2-2024-FS.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Alset-Q2-2024-FS.pdf";
        alink.click();
      });
    });
  };

  const onButtonClick2 = () => {
    // using Java Script method to get PDF file
    fetch("../../../files/Alset-Q2-2024-MDA.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Alset-Q2-2024-MDA.pdf";
        alink.click();
      });
    });
  };

  const onButtonClick3 = () => {
    // using Java Script method to get PDF file
    fetch("../../../files/Alset-Q1-2024-FS.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Alset-Q1-2024-FS.pdf";
        alink.click();
      });
    });
  };

  const onButtonClick4 = () => {
    // using Java Script method to get PDF file
    fetch("../../../files/Alset-Q1-2024-MDA.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Alset-Q1-2024-MDA.pdf";
        alink.click();
      });
    });
  };
  return (
    <div className="download_container">
      <div className="title">Downloads</div>
      <div className="download_main">
        <div className="div_left">
          {section.map((item, index) => {
            return (
              <motion.div
                key={index}
                className="section"
                whileHover={{
                  opacity: 1,
                }}
                animate={{
                  opacity: selectedDownload === index ? 1 : 0.5,
                }}
                onClick={() => setSelectedDownload(index)}
              >
                {item}
              </motion.div>
            );
          })}
        </div>
        <table className="div_right">
          <tr>
            <td>Document</td>
            <td>Type</td>
            <td>Last Updated</td>
            <td>Action</td>
          </tr>
          <tr>
            <td> Alset Financial Statements Q2</td>
            <td>PDF</td>
            <td>8/6/2024</td>
            <td>
              <motion.img
                src={download}
                alt="download_icon"
                className="download"
                whileHover={{
                  cursor: "pointer",
                }}
                onClick={onButtonClick}
              />
            </td>
          </tr>
          <tr>
            <td> Management Discussion & Analysis Q2</td>
            <td>PDF</td>
            <td>8/6/2024</td>
            <td>
              <motion.img
                src={download}
                alt="download_icon"
                className="download"
                whileHover={{
                  cursor: "pointer",
                }}
                onClick={onButtonClick2}
              />
            </td>
          </tr>
          <tr>
            <td>Alset Financial Statements Q1</td>

            <td>PDF</td>
            <td>8/6/2024</td>
            <td>
              <motion.img
                src={download}
                alt="download_icon"
                className="download"
                whileHover={{
                  cursor: "pointer",
                }}
                onClick={onButtonClick3}
              />
            </td>
          </tr>
          <tr>
            <td> Management Discussion & Analysis Q1</td>

            <td>PDF</td>
            <td>8/6/2024</td>
            <td>
              <motion.img
                src={download}
                alt="download_icon"
                className="download"
                whileHover={{
                  cursor: "pointer",
                }}
                onClick={onButtonClick4}
              />
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default Download;
