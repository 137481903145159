import React from "react";
import { useNavigate } from "react-router-dom";
import logo_Gold from "../../Assets/Images/AlsetLogoBrown.svg";
import facebook from "../../Assets/Images/footer_facebook.svg";
import instagram from "../../Assets/Images/footer_instagram.svg";
import linkedin from "../../Assets/Images/footer_linkedin.svg";
import youtube from "../../Assets/Images/youtube.svg";
import "./Footer.scss";
import twitter from "../../Assets/Images/twitter.svg";
function Footer() {
  const links = [
    "Cloud Computing",
    "Solutions",
    "Investors",
    "News & Media",
    "Contact Us",
  ];
  const navigate = useNavigate();
  return (
    <div className="footer_main_div">
      <div className="footer_main_content">
        <div className="footer_main_content_top">
          <img src={logo_Gold} alt="logo" className="footer_logo" />
          <div className="footer_copyRight">
            Copyright 2024 - Alset Capital Inc - All Rights Reserved
          </div>
        </div>
        <div className="footer_divider1"></div>

        <div className="footer_main_content_bottom">
          <div className="footer_main_content_bottom_left">
            {links.map((link, index) => (
              <div
                className="footer_main_content_bottom_left_link"
                onClick={() =>
                  navigate("/" + link.split(" ").join("-").toLowerCase())
                }
                key={index}
              >
                {link}
              </div>
            ))}
          </div>
          <div className="footer_main_content_bottom_right">
            <img
              src={facebook}
              alt="facebook"
              className="footer_social_icon"
              onClick={() =>
                window.open("https://www.facebook.com/AlsetCapital", "_blank")
              }
            />
            <img
              src={youtube}
              alt="youtube"
              className="footer_social_icon"
              onClick={() =>
                window.open("https://www.youtube.com/@AlsetAI", "_blank")
              }
            />
            <img
              src={twitter}
              alt="x"
              className="footer_social_icon"
              onClick={() => window.open(" https://x.com/AlsetAI", "_blank")}
            />

            <img
              src={linkedin}
              alt="linkedin"
              className="footer_social_icon"
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/alset-capital/",
                  "_blank"
                )
              }
            />
            <img
              src={instagram}
              alt="instagram"
              onClick={() =>
                window.open("https://www.instagram.com/alsetcapital/", "_blank")
              }
              className="footer_social_icon"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
