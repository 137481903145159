import React, { useEffect, useState } from "react";
import "./Layout.scss";
import SideNavbar from "../SideNavbar/SideNavbar";
import useInnerWidth from "../../Hooks/useInnerWidth";
import FancyText from "@carefully-coded/react-text-gradient";
import { useSearchParams } from "react-router-dom";
import Popup from "../Popup/Popup";
import PhoneInput from "react-phone-input-2";
import MailchimpSubscribe from "react-mailchimp-subscribe";

function Layout({ children }) {
  const width = useInnerWidth();
  const [searchParam, setSearchParam] = useSearchParams();
  const [showPopup, setShowPopup] = useState(false);
  return (
    <div className="layout_main">
      <div className="padding_box">
        <SideNavbar />
        <div
          className="children_div"
          style={{ ...(width < 1100 && { padding: "1rem" }) }}
        >
          <div className="header">
            {!searchParam.get("title") ? (
              <>
                <div className="fancy_text">
                  <FancyText
                    gradient={{
                      from: "#DFC7C1",
                      to: "#191311",
                      type: "linear",
                    }}
                    animateTo={{ from: "#191311", to: "#DFC7C1" }}
                    animateDuration={4000}
                    style={{
                      fontFamily: "M_Bold",
                      fontSize: "2.5rem",
                      color: "transparent",
                    }}
                  >
                    Welcome To Our
                    {width > 850 ? <br /> : " "}
                    Investor Dashboard
                  </FancyText>
                </div>
                <div className="sub_title_right">
                  <div className="Sub_title" style={{ fontSize: "1.2rem" }}>
                    Download the ALSET Investor Presentation.
                  </div>
                  <div
                    className="download_button"
                    onClick={() => setShowPopup(true)}
                  >
                    Download Now
                  </div>
                </div>
              </>
            ) : (
              <div
                className="header_title"
                style={{
                  ...(width < 850 && { textAlign: "center" }),
                }}
              >
                {searchParam.get("title")}
              </div>
            )}
          </div>
          <div className="children">{children}</div>
        </div>
      </div>
      {showPopup && (
        <Popup
          showClose={width < 768}
          setShowPopup={setShowPopup}
          width={"55%"}
          body={<PopupBody setShowPopup={setShowPopup} />}
        ></Popup>
      )}
    </div>
  );
}

export default Layout;

const PopupBody = ({ setShowPopup }) => {
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch("../../../files/Alset-AI-Presentation.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Alset-AI-Presentation.pdf";
        alink.click();
      });
    });
  };
  const url =
    "https://alsetai.us22.list-manage.com/subscribe/post?u=03d65d06cf556c4db612e3545&amp;id=a4860d2f81&amp;f_id=0061cee1f0";

  const CustomForm = ({ status, message, onValidated }) => {
    let email, phone, lname, fname, update, call;
    const submit = () =>
      email &&
      lname &&
      fname &&
      phone &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
        FNAME: fname.value,
        LNAME: lname.value,
        PHONE: phone.state.formattedNumber,
        UPDATES: update.checked.toString(),
        CALL: call.checked.toString(),
        tags: 7879,
      });

    return (
      <>
        <div className="right-section">
          <div className="title">Download the ALSET Investor Presentation</div>
          <div className="text">
            Get on the priority list for early access and key insights.
          </div>
          <form
            className="form"
            onSubmit={(e) => {
              e.preventDefault();
              submit();
            }}
          >
            <input
              className="input_form"
              type="text"
              name="FNAME"
              required
              placeholder="First Name"
              ref={(node) => (fname = node)}
            />
            <input
              className="input_form"
              type="text"
              name="LNAME"
              required
              placeholder="Last Name"
              ref={(node) => (lname = node)}
            />
            <input
              className="input_form"
              type="email"
              placeholder="Email"
              ref={(node) => (email = node)}
            />
            <PhoneInput
              className="input_form"
              ref={(node) => (phone = node)}
              country={"us"}
              required
              name="PHONE"
              placeholder="Get Text Alert - Phone Number"
              renderStringAsFlag={false}
              isValid={true}
              localization={true}
            />
            <div className="checkbox">
              <input
                type="checkbox"
                ref={(node) => (update = node)}
                name="UPDATE"
              />{" "}
              Receive a call from the investor Relations Team?
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                ref={(node) => (call = node)}
                name="CALL"
              />{" "}
              I agree to receive updates from the company.
            </div>
            <button className="submit">Submit</button>
          </form>{" "}
          {status === "error" && (
            <div dangerouslySetInnerHTML={{ __html: message }} />
          )}
          {status === "success" && (
            <div className="download_deck_div">
              <div className="download_deck" onClick={() => onButtonClick()}>
                Thank you for subscribing! Press here to download the
                presentation.
              </div>
            </div>
          )}
        </div>
      </>
    );
  };
  return (
    <div className="Popup_Comp">
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};
