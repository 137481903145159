import React, { useRef, useState } from "react";
import "./News.scss";
import NewsCard from "./NewsCard/NewsCard";
import cardImg from "../../../Assets/Images/imgCard.svg";
import cardImg2 from "../../../Assets/Images/news2.png";
import cardImg3 from "../../../Assets/Images/news3.png";
function News() {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 1; // Adjust the scroll speed
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  return (
    <div className="news_main_div">
      <div className="news_main_title">Latest News From Alset</div>
      <div className="news_main_subtitle">
      </div>
      <div
        className="news_corousel_div"
        ref={containerRef}
        style={{
          cursor: isDragging ? "grabbing" : "grab",
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <div className="news_corousel">
          <NewsCard
            img={cardImg}
            title="Alset Capital Inc. Announces AI GPU Contract With A $91.7M Option Signed By Investee Company Cedarcross"
            date="June 13, 2024"
            description="News Release"
            type="News"
            link={"/press/press-news0"}
            time="4 min read"
          />
          <NewsCard
            img={cardImg2}
            title="Alset Capital Inc. Provides Corporate And Operational Update"
            date="May 29, 2024"
            description="News Release"
            type="Article"
            link={"/press/press-news1"}
            time=" 5 min read"
          />
          <NewsCard
            img={cardImg3}
            title="Alset Capital Inc. Announces Formation Of Artificial Intelligence Advisory Board And Appointments Of Former Google, Panasonic, Huawei AI Executive And Web 3.0 Pioneer"
            date="May 23, 2024"
            description="News Release"
            link={"/press/press-news2"}
            type="Article"
            time="3 min read"
          />
        </div>
      </div>
    </div>
  );
}

export default News;
