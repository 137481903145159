import React from "react";
import "./LandingPage.scss";
import Home from "./Home/Home";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import Section4 from "./Section4/Section4";
import Footer from "../../Components/Footer/Footer";
function LandingPage() {
  return (
    <div>
      <Home />
      <Section2 />
      <Section3 />
      <Section4 />
      <Footer />
    </div>
  );
}

export default LandingPage;
