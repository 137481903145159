import React from "react";
import "./Section2.scss";
import xImg from "../../../Assets/Images/4xImage.png";
import graph from "../../../Assets/Images/graphImg.svg";
import img2 from "../../../Assets/Images/img2.png";

function Section2() {
  return (
    <div className="section2_main_div">
      <div className="section2_main_box">
        <div className="section2_main_box_title">
          Revolutionizing AI with Nvidia <br />
          H100-Powered Servers
        </div>

        <div className="section2_main_box_content">
          <div className="section2_main_box_title2">
            At the heart of Alset's technological arsenal are our
            state-of-the-art servers, powered by Nvidia's most advanced H100
            Tensor Core GPUs.
          </div>
          <div className="section2_main_box_content_boxes">
            <div className="section2_main_box_content_box_left">
              <div className="section2_main_box_content_box1_left">
                <div className="overlay_section2"></div>
                <img
                  src={graph}
                  className="section2_main_box_content_img"
                  alt="graph"
                />

                <div className="section2_main_box_content_title">
                  Real-Time Deep Learning Inference
                </div>
                <div
                  className="section2_main_box_content_subtitle"
                  style={{
                    marginBottom: 0,
                  }}
                >
                  30X higher AI inference performance on the largest models
                </div>
              </div>
              <div className="section2_main_box_content_box1_left">
                <div className="section2_main_box_content_title">
                  Exascale High-Performance Computing
                </div>
                <div
                  className="section2_main_box_content_subtitle"
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  H100 triples the floating-point operations per second (FLOPS)
                  of double-precision Tensor Cores, delivering 60 teraflops of
                  FP64 computing for HPC.
                </div>
                <img
                  src={img2}
                  className="section2_main_box_content_img"
                  alt="graph"
                />
              </div>
            </div>

            <div className="section2_main_box_content_box_right">
              <div className="section2_main_box_content_div">
                <div className="section2_main_box_content_title">
                  Transformational AI Training
                </div>
                <div className="section2_main_box_content_subtitle">
                  H100 features fourth-generation Tensor Cores and a Transformer
                  Engine with FP8 precision that provides up to 4X faster
                  training over the prior generation for GPT-3 (175B) models.
                </div>
              </div>

              <img
                src={xImg}
                className="section2_main_box_content_img"
                alt="4X"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
