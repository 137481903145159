import React from 'react'
import './Banner.scss'
function Banner() {
  return (
    <div className='Banner'>
      <div className="banner_text">
        Alset Capital Inc. is a publicly traded company: TSXV:KSUM | OTC:ALSCF | FSE:1R60, WKN:A3ESVQ
      </div>
    </div>
  )
}

export default Banner