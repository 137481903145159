import React from "react";
import "./ContactUs.scss";
import email from "../../Assets/Images/email.svg";
import location from "../../Assets/Images/location.svg";
import phone from "../../Assets/Images/phone.svg";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import MailchimpSubscribe from "react-mailchimp-subscribe";

function ContactUs() {
  const [value, setValue] = React.useState();
  const url =
    "https://alsetai.us22.list-manage.com/subscribe/post?u=03d65d06cf556c4db612e3545&amp;id=a4860d2f81&amp;f_id=0061cee1f0";

  const CustomForm = ({ status, message, onValidated }) => {
    const onButtonClick = () => {
      // using Java Script method to get PDF file
      fetch("../../../files/Alset-AI-Presentation.pdf").then((response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);

          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = "Alset-AI-Presentation.pdf";
          alink.click();
        });
      });
    };
    let email, phone, lname, fname, update, call;
    const submit = () =>
      email &&
      lname &&
      fname &&
      phone &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
        FNAME: fname.value,
        LNAME: lname.value,
        PHONE: phone.state.formattedNumber,
        UPDATES: update.checked.toString(),
        CALL: call.checked.toString(),
        tags: 7879,
      });

    return (
      <>
        <div className="right-section">
          <div className="title">Download the ALSET Investor Presentation</div>
          <div className="text">
            Get on the priority list for early access and key insights.
          </div>
          <form
            className="form"
            onSubmit={(e) => {
              e.preventDefault();
              submit();
            }}
          >
            <input
              className="input_form"
              type="text"
              name="FNAME"
              required
              placeholder="First Name"
              ref={(node) => (fname = node)}
            />
            <input
              className="input_form"
              type="text"
              name="LNAME"
              required
              placeholder="Last Name"
              ref={(node) => (lname = node)}
            />
            <input
              className="input_form"
              type="email"
              placeholder="Email"
              ref={(node) => (email = node)}
            />
            <PhoneInput
              className="input_form"
              ref={(node) => (phone = node)}
              country={"us"}
              required
              name="PHONE"
              placeholder="Get Text Alert - Phone Number"
              renderStringAsFlag={false}
              isValid={true}
              localization={true}
            />
            <div className="checkbox">
              <input
                type="checkbox"
                ref={(node) => (update = node)}
                name="UPDATE"
              />{" "}
              Receive a call from the investor Relations Team?
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                ref={(node) => (call = node)}
                name="CALL"
              />{" "}
              I agree to receive updates from the company.
            </div>
            <button className="submit">Submit</button>
          </form>{" "}
          {status === "error" && (
            <div dangerouslySetInnerHTML={{ __html: message }} />
          )}
          {status === "success" && (
            <div className="download_deck_div">
              <div className="download_deck" onClick={() => onButtonClick()}>
                Thank you for subscribing! Press here to download the
                presentation.
              </div>
            </div>
          )}
        </div>
      </>
    );
  };
  return (
    <div className="ContactUs">
      <Navbar />

      <div className="contact-us">
        <div className="container">
          <div className="left-section">
            <div className="title">Contact Us</div>
            <div className="cards">
              <a
                className="card"
                style={{ cursor: "pointer" }}
                href="mailto:admin@alsetai.com"
              >
                <div className="email-tag">Send Us An Email</div>
                <div className="email">
                  <img src={email} alt="email" /> admin@alsetai.com
                </div>
              </a>
              <div className="card">
                <div className="location-tag">
                  Where We're Building This Amazing Company
                </div>
                <div className="location">
                  <img src={location} alt="email" /> 488-1090 West Georgia
                  Street, Vancouver, British Columbia, V6E 3V7
                </div>
              </div>
              <div
                className="card"
                style={{ cursor: "pointer" }}
                onClick={() => window.open("tel:+1 (778) 778-4836")}
              >
                <div className="phone-tag">Reach Us Faster</div>
                <div className="phone">
                  <img src={phone} alt="email" /> +1 (778) 778-4836
                </div>
              </div>
            </div>
          </div>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            )}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
