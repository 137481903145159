import React from "react";
import "./PressNews20.scss";
import cardImg from "../../../../Assets/Images/imgCard.svg";
function PressNews20() {
  return (
    <div className="PressNews">
      <div className="container_with_image">
        <img src={cardImg} alt="" />
        <div>
          <span className="group">
            <div class="heading">
              Alset Capital Inc. Announces AI GPU Contract With A $91.7M Option
              Signed By Investee Company Cedarcross
            </div>
            <div class="date">June 13, 2024</div>
          </span>
          <span className="group1">
            <p className="bold italic">
              NOT FOR DISTRIBUTION TO UNITED STATES NEWSWIRE SERVICES OR FOR
              RELEASE, PUBLICATION, DISTRIBUTION OR DISSEMINATION, DIRECTLY OR
              INDIRECTLY, IN WHOLE OR IN PART, IN OR INTO THE UNITED STATES
            </p>
            <p>
              <span className="bold" style={{ paddingLeft: "0px" }}>
                News Release Highlights:
              </span>
            </p>
            <ul>
              <li>
                Alset investee Cedarcross signs Nvidia H100 GPU server contract
                for an initial three (3) servers (for a purchase price of $1.37
                million), with the customer having an option to acquire an
                additional 200 servers (for a purchase price of $91.7 million)
                valid until September 30, 2024.
              </li>
              <li>
                Cedarcross achieves approx. $29.7 million cumulative revenue
                year to date with its high-performance computing (“HPC”) server
                distribution business vertical alone.
              </li>
              <li>
                Nvidia’s H100 GPU Servers are used in “Transformational AI
                Training”, offering up to “7x higher performance for High
                Performance Computing applications.”<sup>1</sup>
              </li>
            </ul>
          </span>
        </div>
      </div>
      <p>
        <span class="bold" style={{ paddingLeft: "0px" }}>
          VANCOUVER, British Columbia – May 29, 2024 – Alset Capital Inc.
          (TSXV:KSUM) (OTC:ALSCF) (FSE:1R60, WKN:A3ESVQ) (“Alset” or the
          “Company”)
        </span>{" "}
        pleased to announce that its investee company, Cedarcross International
        Technologies Inc. (“Cedarcross”), has signed an agreement with an arm’s
        length counterparty, Big Energy Investments Inc., DBA Ceti AI (“Ceti
        AI”) to distribute three (3) Nvidia H100 HGX 8GPU servers, which is
        anticipated to generate $1.37 million in revenue for Cedarcross, with an
        option for the counterparty to acquire up to 200 H100 HGX 8GPU servers,
        which if exercised would generate $91.7 million in revenue (the
        “Transaction”).
      </p>
      <p>
        Following Cedarcross’ two previously announced distribution agreements
        announced on April 22, 2024 and May 16, 2024, this agreement marks an
        important advancement in Cedarcross’ distribution business within the
        high-performance computing (HPC) server market. Cedarcross has now
        secured cumulative revenue year to date of approximately $29.7 million
        through its HPC server distribution business vertical alone. These
        distribution agreements underscore the significant demand and strategic
        market positioning of Cedarcross in the HPC landscape.
      </p>
      <p>
        “This transaction represents another significant milestone for
        Cedarcross” said Morgan Good, CEO of Alset. “It highlights their strong
        partnerships, which provide access to high-performance computing Nvidia
        GPU servers for both in-house use and third parties. Additionally, this
        distribution vertical enhances their existing compute leasing business
        unit, fostering diversity in their offerings and deeper market
        penetration.”
      </p>

      <p>
        The estimated gross margin from the initial $1.37 million purchase order
        is approximately $176k, and the estimated gross margin assuming all 200
        H100 HGX 8GPU servers are purchased is approximately $11,782,000.
      </p>
      <p>
        As of this announcement, the Company has been informed that Cedarcross
        has received a 10% downpayment for the initial $1.37 million purchase.
        The payment schedule is as follows:
      </p>

      <ul>
        <li>$413,055 due within fourteen (14) days of June 4th, 2024</li>
        <li>$275,370 due when the servers are ready to ship</li>
        <li>$275,370 due upon receipt of the servers by Ceti AI</li>
        <li>
          $275,370 due seven (7) days after Ceti AI receives and accepts the
          servers
        </li>
      </ul>
      <p>
        The sale of up to 200 servers to Ceti AI is subject to Ceti AI obtaining
        approval from the Original Equipment Manufacturer (OEM) compliance.
      </p>
      <p>
        All amounts in Canadian Dollars unless otherwise specified. These
        figures are converted from US dollars at an exchange rate of 1.37 (USD
        to CAD).
      </p>
      <p>
        <sup>1</sup> Nvidia.com
      </p>
      <p className="italic">On behalf of Alset Capital Inc.</p>
      <p>
        <span className="italic" style={{ paddingLeft: "0px" }}>
          “Morgan Good”
        </span>
        <br />
        Morgan Good
        <br />
        Chief Executive Officer
      </p>
      <div className="sub-header">About Alset Capital Inc.</div>
      <p>
        Alset Capital Inc. is an investment issuer that is focused on investment
        in diversified industries such as technology, healthcare, industrial,
        special situations, operating businesses through both debt and equity
        using cash resources or shares in its capital. The Company is led by an
        experienced, entrepreneurial group of executives having a diverse
        industry and capital markets background.
      </p>
      <p>
        Alset Capital Inc.’s investment portfolio comprises 49% ownership of
        Cedarcross International Technologies Inc. and 49% ownership of Vertex
        AI Ventures Inc.
      </p>
      <div className="sub-header">
        About Cedarcross International Technologies Inc.
      </div>
      <p>
        Cedarcross Technologies is an Artificial Intelligence cloud computing
        provider, with a vision of becoming one of Canada’s largest AI compute
        providers. The Company is dedicated to democratizing access to
        cutting-edge AI computing, offering access to the world’s fastest AI
        servers powered by Nvidia’s H100 HGX 8GPU Servers. Focused on leasing
        compute resources to enterprise clients, Cedarcross Technologies
        anticipates significant revenue growth.
      </p>
      <p>For further information about Alset Capital Inc., please contact:</p>
      <p>
        <span className="bold" style={{ paddingLeft: "0px" }}>
          Morgan Good, CEO and Director
        </span>
        <br />
        T: 604.715.4751
        <br />
        E: <a href="mailto:morgan@alsetai.com">morgan@alsetai.com</a>
      </p>
      <div className="bold">
        Cautionary Note regarding Forward Looking Statements
      </div>
      <p>
        Certain statements in this press release may contain forward-looking
        information (within the meaning of Canadian securities legislation),
        including, without limitation, the consummation of the transactions
        contemplated by the distribution and option agreement, the closing of
        the Transaction as contemplated or at all, approval from OEM Compliance
        in the event the option to acquire the additional 200 Nvidia H100 HGX
        8GPU servers is exercised, the Nvidia H100 HGX 8GPU servers’ revenue
        generation, and the growth of the global cloud AI market. These
        statements address future events and conditions and, as such, involve
        known and unknown risks, uncertainties, and other factors, which may
        cause the actual results, performance, or achievements to be materially
        different from any future results, performance, or achievements
        expressed or implied by the statements. Forward-looking statements speak
        only as of the date those statements are made. Although the Company
        believes the expectations expressed in such forward-looking statements
        are based on reasonable assumptions, such statements are not guarantees
        of future performance and actual results may differ materially from
        those in the forward-looking statements. Factors that could cause the
        actual results to differ materially from those in forward-looking
        statements include regulatory actions, market prices, and continued
        availability of capital and financing, and general economic, market or
        business conditions. Investors are cautioned that any such statements
        are not guarantees of future performance and actual results or
        developments may differ materially from those projected in the
        forward-looking statements. Forward-looking statements are based on the
        beliefs, estimates and opinions of the Company’s management on the date
        the statements are made. Except as required by applicable law, the
        Company assumes no obligation to update or to publicly announce the
        results of any change to any forward-looking statement contained or
        incorporated by reference herein to reflect actual results, future
        events or developments, changes in assumptions, or changes in other
        factors affecting the forward-looking statements. If the Company updates
        any forward-looking statement(s), no inference should be drawn that it
        will make additional updates with respect to those or other
        forward-looking statements.
      </p>
      <div className="bold">
        Cautionary Note Regarding Future Oriented Financial Information
      </div>
      <p>
        This news release also contains future-oriented financial information
        and financial outlook (collectively, “FOFI”) about the Cedarcross’
        forecasted revenues from the sale of the three Nvidia H100 HGX 8GPU
        servers and the option it has granted for the sale of an additional 200
        Nvidia H100 HGX 8GPU servers and the receipt of OEM’s approval if the
        option to acquire the additional 200 Nvidia H100 HGX 8GPU servers is
        exercised, which is subject to the same assumptions, risk factors,
        limitations, and qualifications as set forth in the above paragraphs.
        FOFI contained in this news release was made as of the date of this news
        release and was provided for the purpose of providing further
        information about Cedarcross’ anticipated future business operations.
        The Company disclaims any intention or obligation to update or revise
        any FOFI contained in this news release, whether as a result of new
        information, future events or otherwise, unless required pursuant to
        applicable law. Readers are cautioned that the FOFI contained in this
        news release should not be used for purposes other than for which it is
        disclosed herein.
      </p>
      <div className="bold">
        NEITHER THE TSX VENTURE EXCHANGE NOR ITS REGULATION SERVICES PROVIDER
        (AS THAT TERM IS DEFINED IN THE POLICIES OF THE TSX VENTURE EXCHANGE)
        ACCEPTS RESPONSIBILITY FOR THE ADEQUACY OR ACCURACY OF THIS RELEASE.
      </div>
    </div>
  );
}

export default PressNews20;
