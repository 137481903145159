import React from "react";
import "./OurMission.scss";
function OurMission() {
  return (
    <div className="our_mission_main_div" id="howitworks">
      <div className="our_mission_title">Our Mission</div>
      <div className="our_mission_subtitle">
        Drive significant shareholder value through the acquisition and
        development of AI assets. Alset aims to become the largest Canadian
        publicly listed owner of AI hardware and IP.
      </div>
      <div className="section4_divider1"></div>
      <div className="our_mission_title">Problems We're Solving</div>
      <div className="our_mission_subtitle2">Computing Infrastructure is:</div>

      <div className="ourMissionList">
        <div className="ourMissionList_item">
          <div className="ourMissionList_item1">Very Expensive</div>
          <div className="ourMissionList_item2">
            High-cost barrier to entry for high-performance computing hardware
          </div>
        </div>
        <div className="section4_divider1"></div>
        <div className="ourMissionList_item">
          <div className="ourMissionList_item1">Hard to find </div>
          <div className="ourMissionList_item2">
            Nvidia tightly controls the distribution of their GPUs
          </div>
        </div>
        <div className="section4_divider1"></div>
      </div>
      <div className="ourMissionList_item">
        <div className="ourMissionList_item1">Long lead time </div>
        <div className="ourMissionList_item2">
          Can take up to 9 months from the purchase order to receive the
          hardware
        </div>
      </div>
      <div className="section4_divider1"></div>
      <div className="ourMissionList_item">
        <div className="ourMissionList_item1">Highly Specialized</div>
        <div className="ourMissionList_item2">
          Each use case requires unique technical specifications
        </div>
      </div>
    </div>
  );
}

export default OurMission;
