import React from "react";
import "./Section3.scss";
import img3 from "../../../Assets/Images/img3.png";
import img4 from "../../../Assets/Images/img4.png";
function Section3() {
  return (
    <div className="section3_main_div">
      <div className="section3_divider1"></div>

      <div className="section3_main_content">
        <div className="section3_main_left">
          <div className="section3_main_title">
            Unmatched Computational Power
          </div>
          <div className="section3_main_subtitle">
            Our Nvidia H100-powered servers represent the pinnacle of AI
            computing hardware. With each server equipped with 8 NVIDIA® H100
            SXM GPUs, Alset offers unparalleled processing capabilities.
          </div>
        </div>
        <img src={img3} className="section3_main_img" alt="img3" />
      </div>
      <div className="section3_divider2"></div>
      <div
        className="section3_main_content"
        style={{
          marginTop: "4rem",
          marginBottom: "3rem",
        }}
      >
        <img src={img4} className="section3_main_img2" alt="img3" />
        <div className="section3_main_left2">
          <div className="section3_main_title">Tailored for AI Innovation</div>
          <div className="section3_main_subtitle">
            The H100 GPUs are engineered specifically for AI workloads,
            providing the flexibility to handle a diverse range of AI
            applications—from large language models to sophisticated deep
            learning algorithms
          </div>
        </div>
      </div>
      <div className="section3_divider1"></div>
    </div>
  );
}

export default Section3;
