import React from "react";
import "./PressNews4.scss";
function PressNews4() {
  return (
    <div className="PressNews4">
      <span class="group">
        <p>Press Release</p>
        <div class="heading">
          Alset Capital Inc. Closes First Tranche Of Previously Announced
          Non-Brokered Private Placement For Gross Proceeds Of $1.41M
        </div>
        <div class="date">May 15, 2024</div>
      </span>
      <p class="warning">
        NOT FOR DISTRIBUTION TO UNITED STATES NEWSWIRE SERVICES OR FOR RELEASE,
        PUBLICATION, DISTRIBUTION OR DISSEMINATION, DIRECTLY OR INDIRECTLY, IN
        WHOLE OR IN PART, IN OR INTO THE UNITED STATES
      </p>
      <span class="group1">
        <p>
          <span class="bold">
            VANCOUVER, BC / ACCESSWIRE / May 15, 2024 – Alset Capital Inc.
            (TSXV:KSUM)(OTC PINK:ALSCF)(FSE:1R60, WKN:A3ESVQ) (“Alset” or the
            “Company”)
          </span>
          is pleased to announce that it has completed the first tranche (the
          “First Tranche”) of its non-brokered private placement of units of the
          Company (the “Units”) at a price of $0.25 per Unit, for aggregate
          gross proceeds of $1,406,200.00 for the issuance of 5,624,800 Units
          (the “Offering”). The remainder of the Offering may close in one or
          more additional tranches.
        </p>
        <p>
          Each Unit is comprised of one (1) Common Share and one-half of one
          Common Share purchase warrant (each, whole warrant, a “Warrant”). Each
          whole Warrant entitles the holder thereof to acquire one (1)
          additional Common Share (each, a “Warrant Share”) at a price of $0.40
          per Warrant Share for a period of 36 months from the date of issuance.
        </p>
        <p>
          In connection with closing of the First Tranche, the Company paid
          finders’ fees of $1,000 and issued an aggregate of 20,000 Common Share
          purchase warrants (the “Finders’ Warrants”) to eligible arms’ length
          finders. Each Finders’ Warrant entitles the holder thereof to acquire
          one (1) Common Share (each, a “Finders’ Warrant Share”) at a price of
          $0.25 per Finders’ Warrant Share for a period of 36 months from the
          date of issuance.
        </p>
        <p>
          The Company intends on closing its second tranche of the Offering (the
          “Second Tranche”) on Friday, May 17, 2024. As currently contemplated,
          the Company will issue an additional 800,000 Units for aggregate gross
          proceeds of $200,000 in connection with closing of the Second Tranche.
        </p>
        <p>
          The Company intends on using the net proceeds from the Offering for
          capital expenditures, working capital and general corporate purposes.
        </p>
        <p>
          All securities issued pursuant to the Offering are subject to a
          statutory hold period of four (4) months plus one (1) day from the
          date of issuance, in accordance with applicable securities
          legislation.
        </p>
        <p>
          Beacon Securities Limited has been engaged as financial advisor to the
          Company and will receive 600,000 Finder’s Warrants in connection with
          the engagement.
        </p>
        <p>
          This press release does not constitute an offer to sell or a
          solicitation of an offer to buy any securities in the United States or
          to any “U.S. Person” (as such term is defined in Regulation S under
          the U.S. Securities Act of 1933, as amended (the “U.S. Securities
          Act”)) of any equity or other securities of the Company. The
          securities described herein have not been, and will not be, registered
          under the U.S. Securities Act or under any state securities laws and
          may not be offered or sold in the United States or to a U.S. Person
          absent registration under the 1933 Act and applicable state securities
          laws or an applicable exemption therefrom. Any failure to comply with
          these restrictions may constitute a violation of U.S. securities laws.
        </p>
        <p className="bold">On behalf of Alset Capital Inc.</p>
        <p>“Morgan Good”</p>
        <p>
          Morgan Good
          <br />
          Chief Executive Officer
        </p>
        <p>
          <span class="bold">
            About Alset Capital Inc.
          </span>
        </p>
        <p>
          Alset Capital Inc. is an investment issuer that is focused on
          investment in diversified industries such as technology, healthcare,
          industrial, special situations, operating businesses through both debt
          and equity using cash resources or shares in its capital. The Company
          is led by an experienced, entrepreneurial group of executives having a
          diverse industry and capital markets background.
        </p>
        <p>
          Alset Capital Inc.’s investment portfolio comprises 49% ownership of
          Cedarcross International Technologies Inc. and 49% ownership of Vertex
          AI Ventures Inc.
        </p>
        <p>
          <span class="bold">
            About Cedarcross International Technologies Inc.
          </span>
        </p>
        <p>
          Cedarcross is an Artificial Intelligence cloud computing provider,
          with a vision of becoming one of Canada’s largest AI compute
          providers. The Company is dedicated to democratizing access to
          cutting-edge AI computing, offering access to the world’s fastest AI
          servers powered by Nvidia’s H100 HGX 8GPU Servers. Focused on leasing
          compute resources to enterprise clients, Cedarcross anticipates
          significant revenue growth.
        </p>
        <p className="bold">For further information about Alset Capital Inc., please contact:</p>
        <p>
          <span className="bold">Morgan Good, CEO and Director</span>
          <br />
          T: 604.715.4751
          <br />
          E: <a href="mailto:morgan@alsetai.com">morgan@alsetai.com</a>
        </p>
        <p>
          <span class="bold">
            Cautionary Note regarding Forward Looking Statements
          </span>
        </p>
        <p>
          This news release contains statements and information that, to the
          extent that they are not historical fact, constitute “forward-looking
          information” within the meaning of applicable securities legislation.
          Forward-looking information is based on the reasonable assumptions,
          estimates, analysis and opinions of management made in light of its
          experience and its perception of trends, current conditions and
          expected developments, as well as other factors that management
          believes to be relevant and reasonable in the circumstances at the
          date that such statements are made, but which may prove to be
          incorrect. Forward-looking information involves known and unknown
          risks, uncertainties and other factors that may cause the actual
          results, performance or achievements of the Company to differ
          materially from any future results, performance or achievements
          expressed or implied by the forward-looking information, including,
          but not limited to, statements relating to the Company’s financial
          performance, business development, results of operations, and those
          listed in filings made by the Company with the Canadian securities
          regulatory authorities (which may be viewed at www.sedarplus.ca).
        </p>
        <p>
          Accordingly, readers should not place undue reliance on any such
          forward-looking information. Further, any forward-looking statement
          speaks only as of the date on which such statement is made. New
          factors emerge from time to time, and it is not possible for the
          Company’s management to predict all of such factors and to assess in
          advance the impact of each such factor on the Company’s business or
          the extent to which any factor, or combination of factors, may cause
          actual results to differ materially from those contained in any
          forward-looking statements. The Company does not undertake any
          obligation to update any forward-looking information to reflect
          information, events, results, circumstances or otherwise after the
          date hereof or to reflect the occurrence of unanticipated events,
          except as required by law including securities laws.
          </p>
        <p className="bold">
          Neither the TSX
          Venture Exchange nor its Regulation Services Provider (as that term is
          defined in the policies of the TSX Venture Exchange) accepts
          responsibility for the adequacy or accuracy of this news release.
        </p>
        <p><span className="bold">SOURCE:</span> Alset Capital Inc.</p>
      </span>
    </div>
  );
}

export default PressNews4;
