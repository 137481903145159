import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import SummaryPage from "./Pages/SummaryPage/SummaryPage";
import LandingPage from "./Pages/LandingPage/LandingPage";
import Executive_team from "./Pages/Executive_team/Executive_team";
import Layout from "./Components/Layout/Layout";
import Press from "./Pages/Press/Press";
import InPressSingle from "./Pages/Press/PressComponents/InPressSingle/InPressSingle";
import PressRelease from "./Pages/Press/PressComponents/PressRelease/PressRelease";
import PressRelease2 from "./Pages/Press/PressComponents/PressRelease copy/PressRelease";
import PressRelease3 from "./Pages/Press/PressComponents/PressRelease copy 2/PressRelease";
import Download from "./Pages/Download/Download";
import ContactUs from "./Pages/ContactUs/ContactUs";
import CloudComputing from "./Pages/CloudComputing/CloudComputing";
import Solutions from "./Pages/Solutions/Solutions";
import BoardOfDirectors from "./Pages/BoardOfDirectors/BoardOfDirectors.js";
import PressNews from "./Pages/Press/PressComponents/PressNews/PressNews.js";
import PressNews1 from "./Pages/Press/PressComponents/PressNews1/PressNews1.js";
import PressNews2 from "./Pages/Press/PressComponents/PressNews2/PressNews2.js";
import PressNews3 from "./Pages/Press/PressComponents/PressNews3/PressNews3.js";
import PressNews4 from "./Pages/Press/PressComponents/PressNews4/PressNews4.js";
import PressNews5 from "./Pages/Press/PressComponents/PressNews5/PressNews5.js";
import PressNews6 from "./Pages/Press/PressComponents/PressNews6/PressNews6.js";
import PressNews7 from "./Pages/Press/PressComponents/PressNews7/PressNews7.js";
import PressNews8 from "./Pages/Press/PressComponents/PressNews8/PressNews8.js";
import PressNews9 from "./Pages/Press/PressComponents/PressNews9/PressNews9.js";
import PressNews10 from "./Pages/Press/PressComponents/PressNews10/PressNews10.js";
import PressNews11 from "./Pages/Press/PressComponents/PressNews11/PressNews11.js";
import PressNews12 from "./Pages/Press/PressComponents/PressNews12/PressNews12.js";
import PressNews13 from "./Pages/Press/PressComponents/PressNews13/PressNews13.js";
import PressNews14 from "./Pages/Press/PressComponents/PressNews14/PressNews14.js";
import PressNews15 from "./Pages/Press/PressComponents/PressNews15/PressNews15.js";
import PressNews16 from "./Pages/Press/PressComponents/PressNews16/PressNews16.js";
import PressNews17 from "./Pages/Press/PressComponents/PressNews17/PressNews17.js";
import PressNews18 from "./Pages/Press/PressComponents/PressNews18/PressNews18.js";
import PressNews19 from "./Pages/Press/PressComponents/PressNews19/PressNews19.js";
import PressNews20 from "./Pages/Press/PressComponents/PressNews20/PressNews20.js";
import Banner from "./Components/Banner/Banner.js";

function AllRoutes() {
  const { pathname } = useLocation();

  return (
    <>
      <Banner />
      {pathname === "/" ? (
        <Routes>
          <Route path="/" element={<LandingPage />} />
        </Routes>
      ) : pathname === "/contact-us" ? (
        <Routes>
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      ) : pathname === "/cloud-computing" ? (
        <Routes>
          <Route path="/cloud-computing" element={<CloudComputing />} />
        </Routes>
      ) : pathname === "/solutions" ? (
        <Routes>
          <Route path="/solutions" element={<Solutions />} />
        </Routes>
      ) : (
        <Layout>
          <Routes>
            <Route path="/investors" element={<SummaryPage />} />
            <Route path="/news-&-media" element={<Press />} />
            <Route path="/summary" element={<SummaryPage />} />
            <Route path="/board-of-directors" element={<BoardOfDirectors />} />
            <Route path="/executive-team" element={<Executive_team />} />
            <Route path="/press" element={<Press />} />
            <Route path="/press/in-press-single" element={<InPressSingle />} />
            <Route path="/press/press-release0" element={<PressRelease />} />
            <Route path="/press/press-release1" element={<PressRelease2 />} />
            <Route path="/press/press-release2" element={<PressRelease3 />} />

            <Route path="/press/press-news0" element={<PressNews20 />} />
            <Route path="/press/press-news1" element={<PressNews />} />
            <Route path="/press/press-news2" element={<PressNews1 />} />
            <Route path="/press/press-news3" element={<PressNews2 />} />
            <Route path="/press/press-news4" element={<PressNews3 />} />
            <Route path="/press/press-news5" element={<PressNews4 />} />
            <Route path="/press/press-news6" element={<PressNews5 />} />
            <Route path="/press/press-news7" element={<PressNews6 />} />
            <Route path="/press/press-news8" element={<PressNews7 />} />
            <Route path="/press/press-news9" element={<PressNews8 />} />
            <Route path="/press/press-news10" element={<PressNews9 />} />
            <Route path="/press/press-news11" element={<PressNews10 />} />
            <Route path="/press/press-news12" element={<PressNews11 />} />
            <Route path="/press/press-news13" element={<PressNews12 />} />
            <Route path="/press/press-news14" element={<PressNews13 />} />
            <Route path="/press/press-news15" element={<PressNews14 />} />
            <Route path="/press/press-news16" element={<PressNews15 />} />
            <Route path="/press/press-news17" element={<PressNews16 />} />
            <Route path="/press/press-news18" element={<PressNews17 />} />
            <Route path="/press/press-news19" element={<PressNews18 />} />
            <Route path="/press/press-news20" element={<PressNews19 />} />

            <Route path="/downloads" element={<Download />} />
          </Routes>
        </Layout>
      )}
    </>
  );
}

export default AllRoutes;
