import React from "react";
import "./PressNews1.scss";
import cardImg from "../../../../Assets/Images/news3.png";
function PressNews1() {
  return (
    <div className="PressNews1">
      <div className="container_with_image">
        <img src={cardImg} alt="" />
        <div>
      <span className="group">
        <div class="heading">
          Alset Capital Inc. Announces Formation Of Artificial Intelligence
          Advisory Board And Appointments Of Former Google, Panasonic, Huawei AI
          Executive And Web 3.0 Pioneer
        </div>
        <div class="date">May 23, 2024</div>
      </span>
      <span className="group1">
        <p>
          <span class="bold">
            VANCOUVER, British Columbia – May 23, 2024 – Alset Capital Inc.
            (TSXV:KSUM) (OTC:ALSCF) (FSE:1R60, WKN:A3ESVQ) (“Alset” or the
            “Company”)
          </span>{" "}
          is pleased to announce the formation of its Artificial Intelligence
          Advisory Board (“AI Advisory Board”), alongside the appointments of
          Willy Sun and Michael Gord to its AI Advisory Board.
        </p>
        <p>
          The AI Advisory Board will assist the Company’s board of directors and
          executive officers to provide strategic guidance, expert insights, and
          oversight on the company’s AI initiatives. Comprising diverse experts,
          the board will advise on AI strategy, identify emerging trends, ensure
          compliance with ethical standards, manage risks, evaluate AI
          performance, and foster innovation. The AI Advisory Board will report
          to the company’s Board of Directors.
        </p>
        <p>
          “The appointment of Willy Sun and Michael Gord to our AI Advisory
          Board marks a significant milestone in Alset’s journey towards
          positioning its investment portfolio to become leaders in AI computing
          infrastructure and data management solutions,” said Morgan Good, CEO
          of Alset. “Their combined expertise and leadership will be
          instrumental in guiding our strategic initiatives and driving
          innovation in the rapidly evolving field of artificial intelligence.”
        </p>
        <div class="bold">Willy Sun</div>
        <p>
          Willy Sun, a seasoned executive with a distinguished background in
          artificial intelligence (AI) from leading technology firms including
          Google LLC (NASDAQ:GOOG), Panasonic Holdings Corporation (TYO:6752),
          and Huawei Technologies Co., Ltd., brings extensive experience in AI
          research and development. Willy’s visionary leadership positions him
          as an ideal candidate to guide Alset in its AI initiatives.
        </p>
        <p>
          Willy’s illustrious career began at National Taiwan University’s
          Department of Electrical Engineering, where he distinguished himself
          by publishing three lead-authored papers and delivering a presentation
          at a prominent IEEE conference during his doctoral studies – IEEE is
          the world’s largest technical professional organization dedicated to
          advancing technology for the benefit of humanity. His pioneering work,
          especially in speech emotion recognition, set new benchmarks for
          accuracy, underscoring its potential to revolutionize human-computer
          interaction.
        </p>
        </span>
        </div>
      </div>
      <span className="group1">
        <p>
          Transitioning from academia to the tech industry, Willy made
          substantial contributions to setting global standards in noise
          reduction for mobile devices at HTC and enhancing live streaming
          content monitoring regulations. His tenure at Google in the UK saw
          groundbreaking work on language-independent emotion recognition and
          advancements in Long Short-Term Memory (LSTM) research.
        </p>
        <p>
          As the Chief Engineer for AI development at Huawei Technologies’ 2012
          Laboratory and an AI Scientist at Panasonic, Willy spearheaded
          initiatives in deep learning applications for speech processing,
          dialogue systems, and interactive interface design. His innovations in
          real-time monitoring of sensitive content in digital media have set
          new industry standards for safety and compliance.
        </p>
        <p>
          In recent years, Willy has dedicated himself to harnessing AI to
          improve interactions between users and their home environments,
          pushing the boundaries of convenience, intelligence, and
          sustainability in living spaces. His vision for smart homes integrates
          advanced AI voice and dialogue systems, transforming daily life by
          enhancing efficiency, comfort, and security.
        </p>

        <div class="bold">Michael Gord</div>
        <p>
          Michael Gord, a serial entrepreneur and investor in Web 3.0, bringing
          his extensive experience in the digital asset space. Michael has
          successfully exited three startups and facilitated four acquisitions.
          He is an investor with over 70 portfolio companies, including four
          that have grown from seed stage to unicorn status.
        </p>
        <p>
          Michael is the co-founder & CEO of the GDA Group of Companies, which
          are focused across the digital asset capital market and include GDA
          Capital, Acronym Asset Management, NFT BAZL, and Life DeFi. He is also
          a founder and board member of Metaverse Group, a metaverse technology
          company that was acquired by Tokens.com and subsequently by StoryFire,
          a SocialFi application with over 2.5 million users.
        </p>
        <p>
          Previously, Michael was the CEO of MLG Blockchain, which focused on
          enterprise and government consulting and development, later merging
          with Secure Digital Markets to form GDA Capital. He also founded
          Bitcoin Canada, served on the board of the Bitcoin Association of
          Canada, which was acquired by the Chamber of Digital Commerce, and
          played a key role in the international expansion of the Blockchain
          Education Network. Michael was also the first enterprise blockchain
          developer at TD Bank.
        </p>
        <p>
          Michael holds a B.Comm from The Desautels Faculty of Management at
          McGill University. He is fluent in English and French, and is learning
          Spanish, Portuguese, and Swedish. He has traveled to 80 countries
          across all continents and has lived in five countries across three
          continents.
        </p>

        <p className="bold">On behalf of Alset Capital Inc.</p>
        <p>
          <span class="italic">“Morgan Good” </span>
          <span>Morgan Good</span>
          <br />
          Chief Executive Officer
        </p>

        <div class="sub-header">About Alset Capital Inc.</div>
        <p>
          Alset Capital Inc. is an investment issuer that is focused on
          investment in diversified industries such as technology, healthcare,
          industrial, special situations, operating businesses through both debt
          and equity using cash resources or shares in its capital. The Company
          is led by an experienced, entrepreneurial group of executives having a
          diverse industry and capital markets background.
        </p>
        <p>
          Alset Capital Inc.’s investment portfolio comprises 49% ownership of
          Cedarcross International Technologies Inc. and 49% ownership of Vertex
          AI Ventures Inc.
        </p>

        <p>
          <span class="bold">
            For further information about Alset Capital Inc., please contact:
          </span>
          <br />
          <span className="bold">Morgan Good, CEO and Director</span>
          <br />
          T: 604.715.4751
          <br />
          E: <a href="mailto:morgan@alsetai.com">morgan@alsetai.com</a>
        </p>

        <div class="bold">
          Cautionary Note regarding Forward Looking Statements
        </div>
        <p>
          Certain statements in this press release may contain forward-looking
          information (within the meaning of Canadian securities legislation),
          including, without limitation, the creation of the advisory board and
          the appointment of Greg Johnson to the Company’s board of directors.
          These statements address future events and conditions and, as such,
          involve known and unknown risks, uncertainties, and other factors,
          which may cause the actual results, performance, or achievements to be
          materially different from any future results, performance, or
          achievements expressed or implied by the statements. Forward-looking
          statements speak only as of the date those statements are made.
          Although the Company believes the expectations expressed in such
          forward-looking statements are based on reasonable assumptions, such
          statements are not guarantees of future performance and actual results
          may differ materially from those in the forward-looking statements.
          Factors that could cause the actual results to differ materially from
          those in forward-looking statements include regulatory actions, market
          prices, and continued availability of capital and financing, and
          general economic, market or business conditions. Investors are
          cautioned that any such statements are not guarantees of future
          performance and actual results or developments may differ materially
          from those projected in the forward-looking statements.
          Forward-looking statements are based on the beliefs, estimates and
          opinions of the Company’s management on the date the statements are
          made. Except as required by applicable law, the Company assumes no
          obligation to update or to publicly announce the results of any change
          to any forward-looking statement contained or incorporated by
          reference herein to reflect actual results, future events or
          developments, changes in assumptions, or changes in other factors
          affecting the forward-looking statements. If the Company updates any
          forward-looking statement(s), no inference should be drawn that it
          will make additional updates with respect to those or other
          forward-looking statements.
        </p>
        <p className="bold">
          Neither TSX Venture Exchange nor its Regulation Services Provider (as
          that term is defined in the policies of the TSX Venture Exchange)
          accepts responsibility for the adequacy or accuracy of this release.
        </p>
      </span>
    </div>
  );
}

export default PressNews1;
