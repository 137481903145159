import React from "react";
import "./Button.scss";
import { motion } from "framer-motion";

function Button({
  children,
  className,
  style,
  onClick,
  animate,
  initial,
  whileHover,
}) {
  return (
    <motion.button
      className={"button_main " + className}
      style={{ ...style }}
      onClick={onClick}
      animate={animate}
      initial={initial}
      whileHover={whileHover}
    >
      {children}
    </motion.button>
  );
}

export default Button;
