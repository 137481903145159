import React from "react";
import "./PressNews9.scss";

function PressNews9() {
  return (
    <div className="PressNews9">
      <span className="group">
        <p style={{ paddingLeft: "0px" }}>Press Release</p>
        <div class="heading">
          Alset Capital Inc. Announces Certain Shareholders Have Entered Into
          18-Month Voluntary Pooling Agreements And Undertakings, And Announces
          Non-Brokered Private Placement
        </div>
        <div class="date">April 17, 2024</div>
      </span>
      <span className="group1">
        <p>
          <span class="bold">
            VANCOUVER, BC / ACCESSWIRE / April 17, 2024 / Alset Capital Inc.
            (TSXV:KSUM)(FSE:1R60)(W9KN:A3ESVQ) (“Alset” or the “Company”)
          </span>{" "}
          is pleased to announce a positive development that certain
          shareholders have entered into a Voluntary Pooling Agreement (the
          “Pooling Agreement“) or Undertakings, which shall collectively place
          voluntary restrictions on the disposition or sale of 12,726,665 units
          of the Company. The detailed Release Schedule is provided below.
        </p>

        <p>
          “We are encouraged by the Applicable Subscribers’ decision to enter
          into the Pooling Agreement and Undertakings, as it signifies their
          strong belief in Alset’s long-term vision and our collective journey
          towards value creation for all shareholders,” said Morgan Good, CEO of
          Alset.
        </p>

        <div class="sub-header" style={{textDecoration: "underline"}}>Release Schedule</div>

        <p>
          The Securities under the Pooling Agreement will be released as
          follows:
        </p>
        <p>
          30.0% of the Securities shall be released on the date that is four (4)
          months and one (1) day from the date of issuance;
        </p>
        <p>
          30.0% of the Securities shall be released on the date that is eight
          (8) months and one (1) day from the date of issuance; and
        </p>
        <p>
          30.0% of the Securities shall be released on the date that is twelve
          (12) months and one (1) day from the date of issuance.
        </p>
        <p>
          10.0% of the Securities shall be released on the date that is eighteen
          (18) months and one (1) day from the date of issuance.
        </p>

        <div class="sub-header" style={{textDecoration: "underline"}}>Private Placement</div>

        <p>
          The Company also announces a Non-Brokered Private Placement of Units
          of the Company (the “<span className= "bold">Units</span>“) at a price of $0.25 per Unit, for
          aggregate gross proceeds of up to $2,500,000 (the “<span className= "bold">Offering</span>“).
        </p>

        <p>
          Each Unit is comprised of one (1) Common Share and one-half of one
          Common Share purchase warrant (each, whole warrant, a “<span className= "bold">Warrant</span>“). Each
          Warrant entitles the holder thereof to acquire one (1) additional
          Common Share (each, a “<span className= "bold">Warrant Share</span>“) at a price of $0.40 per Warrant
          Share for a period of 36 months from the date of issuance.
        </p>

        <p>
          In connection with the Offering, the Company may pay finders’ fees in
          cash or in finders warrants, or a combination of both, as permitted by
          the policies of the TSX Venture Exchange (the “<span className= "bold">TSX-V</span>“). If finders’
          fees are paid in connection with the Offering, it is expected that any
          cash finder’s fees will be equal to 8% of the proceeds placed by the
          applicable finder, and any finder’s fee paid in warrants (the
          “<span className= "bold">Finder’s Warrants</span>“) will be equal to 8% of the number of Units placed
          by the applicable finder. Each Finders’ Warrant entitles the holder
          thereof to acquire one (1) Common Share (each, a “<span className= "bold">Finders’ Warrant
          Share</span>“) at a price of $0.25 per Finders’ Warrant Share for a period of
          36 months from the date of issuance.
        </p>

        <p>
          The Company intends on using the net proceeds from the Offering for
          capital expenditures, working capital and general corporate purposes.
        </p>

        <p>
          All securities issued pursuant to the Offering are subject to a
          statutory hold period of four (4) months plus one (1) day from the
          date of issuance, in accordance with applicable securities
          legislation. Closing of the Offering is subject to a number of
          prescribed conditions, including, without limitations, approval of the
          TSX-V.
        </p>

        <div class="sub-header" style={{textDecoration: "underline"}}>Amendment to Prior News Release</div>

        <p>
          The Company would also like to clarify that there was an error in its
          prior news release filed on SEDAR+ on March 27, 2024 (the “<span className= "bold">Prior News
          Release</span>“). The Prior News Release inadvertently referred to the
          Company’s long-term incentive plan rather than omnibus incentive plan,
          which was adopted by the Company’s Board of Directors on March 27,
          2024 (the “<span className= "bold">Omnibus Plan</span>“). The Omnibus Plan replaces the Company’s
          current share option plan dated January 31, 2018, as amended and
          restated on October 28, 2022 and restricted share unit plan dated
          September 16, 2016. The Company confirms that pursuant to the
          disclosure provided in the Prior News Release, the Company granted
          incentive stock options (the “<span className= "bold">Options</span>“) to purchase up to 6,000,000
          common shares in the capital of the Company and restricted share units
          (“<span className= "bold">RSUs</span>“) to purchase up to 3,000,000 common shares in the capital of
          the Company to certain directors, officers and consultants (the
          “<span className= "bold">Participants</span>“) of the Company pursuant to the Company’s Omnibus Plan.
          All other details pertaining to the Options and RSUs granted on March
          27, 2024, remain the same.
        </p>

        <p>
          The Omnibus Plan and grant of Options and RSUs to the Participants are
          subject to TSX-V and shareholder approval.
        </p>

        <p>On behalf of Alset Capital Inc.</p>

        <p>“Morgan Good“</p>

        <p>Morgan Good</p>

        <p>Chief Executive Officer</p>

        <div class="sub-header">About Alset Capital Inc.</div>

        <p>
          Alset Capital Inc. is an investment issuer that is focused on
          investment in diversified industries such as technology, healthcare,
          industrial, special situations, operating businesses through both debt
          and equity using cash resources or shares in its capital. The Company
          is led by an experienced, entrepreneurial group of executives having a
          diverse industry and capital markets background.
        </p>

        <p>
          Alset Capital Inc.’s investment portfolio comprises 49% ownership of
          Cedarcross International Technologies Inc. and 49% ownership of Vertex
          AI Ventures Inc.
        </p>

        <div class="sub-header">
          About Cedarcross International Technologies Inc.
        </div>

        <p>
          Cedarcross is an Artificial Intelligence cloud computing provider,
          with a vision of becoming one of Canada’s largest AI compute
          providers. The Company is dedicated to democratizing access to
          cutting-edge AI computing, offering access to the world’s fastest AI
          servers powered by Nvidia’s H100 HGX 8GPU Servers. Focused on leasing
          compute resources to enterprise clients, Cedarcross anticipates
          significant revenue growth.
        </p>

        <p className="bold">For further information about Alset Capital Inc., please contact:</p>

        <p className="bold">Morgan Good, CEO and Director</p>

        <p>T: 604.715.4751</p>

        <p>E: morgan@alsetai.com</p>

        <div class="cautionary" className="bold">
          Cautionary Note regarding Forward Looking Statements
        </div>

        <p>
          Certain statements in this press release may contain forward-looking
          information (within the meaning of Canadian securities legislation),
          including, without limitation, the release of securities from the
          Agreement, the intended use of proceeds, the issuance of the Warrant
          Shares upon exercise of the Warrants and the issuance of the Finders’
          Warrant Shares upon exercise of the Finders’ Warrants. These
          statements address future events and conditions and, as such, involve
          known and unknown risks, uncertainties, and other factors, which may
          cause the actual results, performance, or achievements to be
          materially different from any future results, performance, or
          achievements expressed or implied by the statements. Forward-looking
          statements speak only as of the date those statements are made.
          Although the Company believes the expectations expressed in such
          forward-looking statements are based on reasonable assumptions, such
          statements are not guarantees of future performance and actual results
          may differ materially from those in the forward-looking statements.
          Factors that could cause the actual results to differ materially from
          those in forward-looking statements include regulatory actions, market
          prices, and continued availability of capital and financing, and
          general economic, market or business conditions. Investors are
          cautioned that any such statements are not guarantees of future
          performance and actual results or developments may differ materially
          from those projected in the forward-looking statements.
          Forward-looking statements are based on the beliefs, estimates and
          opinions of the Company’s management on the date the statements are
          made. Except as required by applicable law, the Company assumes no
          obligation to update or to publicly announce the results of any change
          to any forward-looking statement contained or incorporated by
          reference herein to reflect actual results, future events or
          developments, changes in assumptions, or changes in other factors
          affecting the forward-looking statements. If the Company updates any
          forward-looking statement(s), no inference should be drawn that it
          will make additional updates with respect to those or other
          forward-looking statements.
        </p>

        <p className="bold">
          Neither TSX Venture Exchange nor its Regulation Services Provider (as
          that term is defined in the policies of the TSX Venture Exchange)
          accepts responsibility for the adequacy or accuracy of this release.
        </p>
      </span>
    </div>
  );
}

export default PressNews9;
