import React from "react";
import "./PressRelease.scss";
import table2 from "../../../../Assets/Images/table2.svg";
import { useSearchParams } from "react-router-dom";

function PressRelease3() {
  return (
    <div className="PressRelease_main">
      <div className="top">
        <img src={table2} alt="table 2" className="table2" />
        <div className="text">
          <div className="title">
            Alset Capital Inc. Announces Extended Engagement Of Corporate
            Awareness Providers, Including US And German Providers
          </div>
          <div className="sub_title">
            <span
              style={{
                fontFamily: "M_Bold",
              }}
            >
              NOT FOR DISTRIBUTION TO UNITED STATES NEWSWIRE SERVICES OR FOR
              RELEASE, PUBLICATION, DISTRIBUTION OR DISSEMINATION, DIRECTLY OR
              INDIRECTLY, IN WHOLE OR IN PART, IN OR INTO THE UNITED STATES
            </span>
            <br />
            <br />
            VANCOUVER, BC / ACCESSWIRE / May 14, 2024 / Alset Capital Inc.
            (TSXV:KSUM)(OTC PINK:ALSCF)(FSE:1R60, WKN:A3ESVQ) (“Alset” or the
            “Company”) is pleased to announce that it has extended its
            engagements of multiple corporate awareness providers, including
            Omni8 Communications Inc. DBA Omni8 Global (“OMNI8“), Shore Thing
            Media LLC (“STM“), and Aktiencheck.de AG (“Aktiencheck“) (Together
            referred to as the “Corporate Awareness Providers“).
            <br />
            <br />
            <b>OMNI 8</b>
            <br />
            <br />
            Further to the Company’s news release of March 27, 2024, the Company
            has extended its engagement (the “OMNI8 Extended Term“) of OMNI8
            Communications Inc. to assist Alset to develop a corporate marketing
            strategy and provide marketing and public relations advisory
            services, help coordinate marketing, news flow and events, making
            introductions to Omni8’s network of media contacts, internet and
            social media marketers and other such providers, as well as to
            assistwith various other public relations efforts. Alset shall pay
            OMNI8 a total of CAD $100,000 for the OMNI8 Extended Term, which
            shall run for a period of 2 months and shall commence on the later
            of May 8, 2024 and the date the OMNI8 Extended Term is accepted for
            filing by the TSX Venture Exchange. OMNI8 is a British Columbia
            based company and is headed by Ryan Johnson.
            <br />
            <br />
            As of the date hereof, to the Company’s knowledge, OMNI8 (including
            its directors and officers) does not own any securities of the
            Company and has an arm’s length relationship with the Company. The
            Company will not issue any securities to OMNI8 as compensation for
            its marketing services. For more information on the Company’s
            engagement of OMNI8, please refer to its news release of March 27,
            2024.
            <br />
            <br />
            <br />
            <b>Shore Thing Media</b>
            <br />
            <br />
            Further to the Company’s news release of March 27, 2024, the Company
            has extended it engagement (the “STM Extended Term“) of Shore Thing
            Media LLC to coordinate with the Company’s management concerning
            marketing ideas, re-distribution of press releases and memorandum
            describing the Company’s business together with such other materials
            as may be reasonably required for marketing of the Company.
            <br />
            <br />
            The STM Extended Term shall run for two months, commencing on the
            later of May 15, 2024, or on approval of the TSX Venture Exchange.
            In consideration for the STM Extended Term, the Company will pay STM
            a total marketing budget of USD $150,000 on May 15, 2024. As of the
            date hereof, to the Company’s knowledge, STM (including its
            directors and officers) does not own any securities of the Company
            and has an arm’s length relationship with the Company. The Company
            will not issue any securities to STM as compensation for its
            marketing services. For more information on the Company’s engagement
            of STM, please refer to its news release of March 27, 2024.
            <br />
            <br />
            <br />
            <b>Aktiencheck</b>
            <br />
            <br />
            All securities issued pursuant to the Offering are subject to a
            statutory hold period of four (4) months plus one (1) day from the
            date of issuance, in accordance with applicable securities
            legislation.
            <br />
            <br />
            The Aktiencheck Extended Term shall run for two-months, commencing
            on the later of May 15, 2024, or on approval of the TSX Venture
            Exchange. In consideration for the Aktiencheck Extended Term, the
            Company will pay Aktiencheck a total marketing budget of €50,000 on
            May 15, 2024. As of the date hereof, to the Company’s knowledge,
            Aktiencheck (including its directors and officers) does not own any
            securities of the Company and has an arm’s length relationship with
            the Company. The Company will not issue any securities to
            Aktiencheck as compensation for its marketing services. For more
            information on the Company’s engagement of Aktiencheck, please refer
            to its news release of March 27, 2024.
            <br />
            <br />
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="sec_1">
          <span
            style={{
              fontFamily: "M_Bold",
            }}
          >
            {" "}
            On behalf of Alset Capital Inc.
          </span>
          <br />
          “Morgan Good“ Morgan Good Chief Executive Officer
          <br />
          <br />
          <span
            style={{
              fontFamily: "M_Bold",
            }}
          >
            {" "}
            About Alset Capital Inc.
          </span>
          <br />
          <br />
          Alset Capital Inc. is an investment issuer that is focused on
          investment in diversified industries such as technology, healthcare,
          industrial, special situations, operating businesses through both debt
          and equity using cash resources or shares in its capital. The Company
          is led by an experienced, entrepreneurial group of executives having a
          diverse industry and capital markets background.
          <br />
          <br />
        </div>
        <div className="sec_2">
          <span
            style={{
              fontFamily: "M_Bold",
            }}
          >
            {" "}
            Cautionary Note regarding Forward Looking Statements
          </span>
          <br />
          <br />
          Certain statements in this press release may contain forward-looking
          information (within the meaning of Canadian securities legislation),
          including, without limitation, the intended services to be provided by
          each of Omni8, Aktienchek and STM, the consideration to be paid to
          each of Omni8, Aktiencheck and STM and the Company obtaining TSXV
          approval. These statements address future events and conditions and,
          as such, involve known and unknown risks, uncertainties, and other
          factors, which may cause the actual results, performance, or
          achievements to be materially different from any future results,
          performance, or achievements expressed or implied by the statements.
          Forward-looking statements speak only as of the date those statements
          are made. Although the Company believes the expectations expressed in
          such forward-looking statements are based on reasonable assumptions,
          such statements are not guarantees of future performance and actual
          results may differ materially from those in the forward-looking
          statements. Factors that could cause the actual results to differ
          materially from those in forward-looking statements include regulatory
          actions, market prices, and continued availability of capital and
          financing, and general economic, market or business conditions.
          Investors are cautioned that any such statements are not guarantees of
          future performance and actual results or developments may differ
          materially from those projected in the forward-looking statements.
          Forward-looking statements are based on the beliefs, estimates and
          opinions of the Company’s management on the date the statements are
          made. Except as required by applicable law, the Company assumes no
          obligation to update or to publicly announce the results of any change
          to any forward-looking statement contained or incorporated by
          reference herein to reflect actual results, future events or
          developments, changes in assumptions, or changes in other factors
          affecting the forward-looking statements. If the Company updates any
          forward-looking statement(s), no inference should be drawn that it
          will make additional updates with respect to those or other
          forward-looking statements.
          <br />
          <br />
          <span
            style={{
              fontFamily: "M_Bold",
            }}
          >
            {" "}
            Neither TSX Venture Exchange nor its Regulation Services Provider
            (as that term is defined in the policies of the TSX Venture
            Exchange) accepts responsibility for the adequacy or accuracy of
            this release.
            <br />
            <br />
            SOURCE: Alset Capital Inc.
          </span>
        </div>
      </div>
    </div>
  );
}

export default PressRelease3;
