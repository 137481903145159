import React, { useEffect, useState } from "react";
import "./HomeBody.scss";
import eye from "../../../../Assets/Images/eyeImg.svg";
import FancyText from "@carefully-coded/react-text-gradient";
import Button from "../../../../Components/Button/Button";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
gsap.registerPlugin(ScrollTrigger);

function HomeBody() {
  const navigate = useNavigate();
  const fx28Titles = [...document.querySelectorAll(".gradient-text")];
  const [first, setFirst] = useState(0);
  const scroll = () => {
    fx28Titles.forEach((title) => {
      const words = [...title.querySelectorAll(".homeBody_text2312")];
      const charsTotal = words.length;
      gsap.fromTo(
        words,
        {
          "will-change": "transform, filter",
          transformOrigin: "50% 100%",
          // scale: (position) => {
          //   const factor =
          //     position < Math.ceil(charsTotal / 2)
          //       ? position
          //       : Math.ceil(charsTotal / 2) -
          //         Math.abs(Math.floor(charsTotal / 2) - position) -
          //         1;
          //   return gsap.utils.mapRange(
          //     0,
          //     Math.ceil(charsTotal / 2),
          //     0.5,
          //     2.1,
          //     factor
          //   );
          // },
          y: (position) => {
            const factor =
              position < Math.ceil(charsTotal / 2)
                ? position
                : Math.ceil(charsTotal / 2) -
                  Math.abs(Math.floor(charsTotal / 2) - position) -
                  1;
            return gsap.utils.mapRange(
              0,
              Math.ceil(charsTotal / 2),
              0,
              60,
              factor
            );
          },
          // rotation: (position) => {
          //   const factor =
          //     position < Math.ceil(charsTotal / 2)
          //       ? position
          //       : Math.ceil(charsTotal / 2) -
          //         Math.abs(Math.floor(charsTotal / 2) - position) -
          //         1;
          //   return position < charsTotal / 2
          //     ? gsap.utils.mapRange(0, Math.ceil(charsTotal / 2), -4, 0, factor)
          //     : gsap.utils.mapRange(0, Math.ceil(charsTotal / 2), 0, 4, factor);
          // },
          filter: "blur(12px) opacity(0)",
        },
        {
          ease: "power2.inOut",
          y: 0,
          rotation: 0,
          scale: 1,
          repeat: 0,
          duration: 2.2,
          filter: "blur(0px) opacity(1)",
          animation: {
            trigger: words,
            start: "top bottom",
            end: "top top+=15%",

            // scrub: true,
          },
        }
      );
    });
  };

  useEffect(() => {
    if (fx28Titles && !first) {
      scroll();
      setFirst(1);
    }
  }, [fx28Titles]);

  const textVariants = {
    animate: {
      backgroundPositionX: ["0%", "100%", "0%"],
    },
  };
  return (
    <>
      <motion.div
        // initial={{
        //   y: -500,
        //   opacity: 0,
        // }}
        // animate={{
        //   //come from top using translateY
        //   y: reached ? 0 : -500,
        //   opacity: reached ? 1 : 0,
        //   transition: {
        //     type: "tween",
        //     duration: 1.8,
        //   },
        // }}
        className="homeBody_main_div backgruond_home_text"
      >
        <div className="gradient-text">
          <div className="homeBody_box homeBody_text2312">
            <img src={eye} className="homeBody_image" alt="eye" />
            <span className="homeBody_text">NEW</span>
            <span className="homeBody_text2">
              Alset offers companies access to Nvidia H100 Servers
            </span>
          </div>
        </div>
        <span className="gradient-text">
          <motion.span
            className="homeBody_text1  homeBody_text2312"
            variants={textVariants}
            animate="animate"
            transition={{
              duration: 10,
              repeat: Infinity,
              ease: "linear",

              // repeatDelay: 0.5,
            }}
            style={{
              backgroundImage:
                "linear-gradient(90deg, var(--lightBrown), var(--brown),  var(--lightBrown), var(--brown))",
              backgroundSize: "200% 100%",
              WebkitBackgroundClip: "text",
              color: "transparent",
              display: "inline-block",
              WebkitTextFillColor: "transparent",
              fontFamily: "M_Bold",
              fontSize: "4rem",
            }}
          >
            Investing in AI Cloud
            <br />
            Computing Infrastructure
          </motion.span>
        </span>
        <div className="homeBody_text3 gradient-text">
          <span className="homeBody_text2312">
            Alset is dedicated to fuelling the growth and progress of AI
            companies,
            <br /> empowering them to thrive and achieve their full potential.
          </span>
        </div>
      </motion.div>
      <div className=" gradient-text">
        <span className="homeBody_text2312 homeBody_buttons">
          <Button className="homeBody_button" animate onClick={() => navigate("/contact-us")}>
            Contact Us
          </Button>

          <Link to="howitworks" smooth={true} duration={500}>
            <div className="homeBody_button2">How It Works?</div>
          </Link>
        </span>
      </div>
    </>
  );
}

export default HomeBody;
