import React, { useState } from "react";
import "./Press.scss";
import { motion } from "framer-motion";
import wsj from "../../Assets/Images/wsj.png";
import table2 from "../../Assets/Images/table2.svg";
import logo from "../../Assets/Images/AlsetLogoSmall.svg";
import { useNavigate } from "react-router-dom";

function Press() {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const in_the_press = [
    {
      date: "June 13, 2024",
      title:
        "Alset Capital Inc. Announces AI GPU Contract With A $91.7M Option Signed By Investee Company Cedarcross",
      subTitle: "Tech Monitor",
      // link: "https://techmonitor.ai/technology/cloud/nvidia-ceo-lastest-ai-chip-to-power-new-industrial-revolution",
    },
    {
      date: "May 29, 2024",
      title: "Alset Capital Inc. Provides Corporate And Operational Update”",
      subTitle: "Tech Monitor",
      // link: "https://techmonitor.ai/technology/cloud/nvidia-ceo-lastest-ai-chip-to-power-new-industrial-revolution",
    },
    {
      date: "May 23, 2024",
      title:
        "Alset Capital Inc. Announces Formation Of Artificial Intelligence Advisory Board And Appointments Of Former Google, Panasonic, Huawei AI Executive And Web 3.0 Pioneer",
      // subTitle: "The Globe and Mail",
      // link: "https://www.theglobeandmail.com/business/article-canadas-ai-infrastructure-does-not-compute/",
    },
    {
      date: "May 21, 2024",
      title:
        "Alset Capital Inc. Announces Strategic Partnership With AI Data Engineering And Automation Pioneer, NOM NOM AI Inc. By Investee Company Vertex AI Ventures Inc.",
      // subTitle: "Trading View",
      // link: "https://www.tradingview.com/news/benzinga:c6a72f698094b:0-compute-not-fiat-or-bitcoin-will-be-the-currency-of-the-future-says-sam-altman-as-nvidia-s-jensen-huang-highlights-100-trillion-ai-opportunity/",
    },
    {
      date: "May 16, 2024",
      title:
        "Alset Capital Inc. Announces $26 Million H100 Server Contract By Investee Company Cedarcross",
      // subTitle: "Tech Monitor",
      // link: "https://techmonitor.ai/technology/cloud/nvidia-ceo-lastest-ai-chip-to-power-new-industrial-revolution",
    },
    {
      date: "May 15, 2024",
      title:
        "Alset Capital Inc. Closes First Tranche Of Previously Announced Non-Brokered Private Placement For Gross Proceeds Of $1.41M",
      // subTitle: "Tech Monitor",
      // link: "https://techmonitor.ai/technology/cloud/nvidia-ceo-lastest-ai-chip-to-power-new-industrial-revolution",
    },
    {
      date: "May 14, 2024",
      title:
        "Alset Capital Inc. Closes First Tranche Of Previously Announced Non-Brokered Private Placement For Gross Proceeds Of $1.41M",
      // subTitle: "Tech Monitor",
      // link: "https://techmonitor.ai/technology/cloud/nvidia-ceo-lastest-ai-chip-to-power-new-industrial-revolution",
    },
    {
      date: "May 2, 2024",
      title:
        "Alset Capital Inc. Expands US Investor Reach With OTC Listing Under Symbol ALSCF",
      // subTitle: "Tech Monitor",
      // link: "https://techmonitor.ai/technology/cloud/nvidia-ceo-lastest-ai-chip-to-power-new-industrial-revolution",
    },
    {
      date: "April 25, 2024",
      title:
        "Alset Capital Inc. Announces C$5.5 Million Two-Year AI Compute Leasing Agreement Signed By Investee Company Cedarcross Technologies",
      // subTitle: "Tech Monitor",
      // link: "https://techmonitor.ai/technology/cloud/nvidia-ceo-lastest-ai-chip-to-power-new-industrial-revolution",
    },
    {
      date: "April 22, 2024",
      title:
        "Alset Capital Inc. Investee Company Cedarcross Technologies Announces Nvidia AI Server Agreement, And Signs C$2.3 Million First H100 Server Distribution Transaction",
      // subTitle: "Tech Monitor",
      // link: "https://techmonitor.ai/technology/cloud/nvidia-ceo-lastest-ai-chip-to-power-new-industrial-revolution",
    },
    {
      date: "April 17, 2024",
      title:
        "Alset Capital Inc. Announces Certain Shareholders Have Entered Into 18-Month Voluntary Pooling Agreements And Undertakings, And Announces Non-Brokered Private Placement",
      // subTitle: "Tech Monitor",
      // link: "https://techmonitor.ai/technology/cloud/nvidia-ceo-lastest-ai-chip-to-power-new-industrial-revolution",
    },
    {
      date: "April 4, 2024",
      title:
        "Alset Capital Announces Investee Company Cedarcross Enters Into Supply Agreement To Purchase Nvidia AI Computing Hardware From Earthmade Computer Inc., An Authorized Distributor Of Super Micro Computer, Inc.",
      // subTitle: "Tech Monitor",
      // link: "https://techmonitor.ai/technology/cloud/nvidia-ceo-lastest-ai-chip-to-power-new-industrial-revolution",
    },
    {
      date: "April 2, 2024",
      title:
        "Alset Capital Inc. Announces Secured Loan To AI Investee Company Cedarcross International Technologies, Cedarcross To Purchase 10 Nvidia H100 HGX Servers",
      // subTitle: "Tech Monitor",
      // link: "https://techmonitor.ai/technology/cloud/nvidia-ceo-lastest-ai-chip-to-power-new-industrial-revolution",
    },
    {
      date: "March 27, 2024",
      title:
        "Alset Capital Inc. Announces Frankfurt Stock Exchange Listing And Engagement Of Corporate Awareness Providers, Including Omni8 Communications Inc.",
      // subTitle: "Tech Monitor",
      // link: "https://techmonitor.ai/technology/cloud/nvidia-ceo-lastest-ai-chip-to-power-new-industrial-revolution",
    },
    {
      date: "March 27, 2024",
      title:
        "Alset Capital Inc. Announces Stock Option, Restricted Share Unit Grant And Engagement Of Market Maker",
      // subTitle: "Tech Monitor",
      // link: "https://techmonitor.ai/technology/cloud/nvidia-ceo-lastest-ai-chip-to-power-new-industrial-revolution",
    },
    {
      date: "March 22, 2024",
      title:
        "Alset Capital Inc. Announces Closing Of Fully Subscribed $6M Non-Brokered Private Placement",
      // subTitle: "Tech Monitor",
      // link: "https://techmonitor.ai/technology/cloud/nvidia-ceo-lastest-ai-chip-to-power-new-industrial-revolution",
    },
    {
      date: "March 15, 2024",
      title:
        "Alset Capital Inc. Announces Closing Of $4.77M First Tranche Of $6M Non- Brokered Private Placement",
      // subTitle: "Tech Monitor",
      // link: "https://techmonitor.ai/technology/cloud/nvidia-ceo-lastest-ai-chip-to-power-new-industrial-revolution",
    },
    {
      date: "March 14, 2024",
      title:
        "Alset Capital Inc. Announces $6 Million Private Placement Of Units",
      // subTitle: "Tech Monitor",
      // link: "https://techmonitor.ai/technology/cloud/nvidia-ceo-lastest-ai-chip-to-power-new-industrial-revolution",
    },
    {
      date: "February 16, 2024",
      title: "Alset Capital Inc. Closes Previously Announced Debt Settlement",
      // subTitle: "Tech Monitor",
      // link: "https://techmonitor.ai/technology/cloud/nvidia-ceo-lastest-ai-chip-to-power-new-industrial-revolution",
    },
    {
      date: "February 2, 2024",
      title:
        "Alset Capital Inc. Announces Update To Previously Announced Debt Settlement",
      // subTitle: "Tech Monitor",
      // link: "https://techmonitor.ai/technology/cloud/nvidia-ceo-lastest-ai-chip-to-power-new-industrial-revolution",
    },
    {
      date: "January 11, 2024",
      title:
        "Alset Capital Inc. Announces Investment In Vertex AI Ventures Inc.",
      // subTitle: "Tech Monitor",
      // link: "https://techmonitor.ai/technology/cloud/nvidia-ceo-lastest-ai-chip-to-power-new-industrial-revolution",
    },
  ];

  const press_release = [
    {
      date: "March 21, 2024",
      title: "Canada's AI Infrastructure Does Not Compute",
      subTitle: "The Globe and Mail",
      link: "https://www.theglobeandmail.com/business/article-canadas-ai-infrastructure-does-not-compute/",
    },
    {
      date: "March 20, 2024",
      title:
        "Compute, Not Fiat Or Bitcoin, Will Be The 'Currency Of The Future,' Says Sam Altman As Nvidia's Jensen Huang Highlights $100 Trillion AI Opportunity",
      subTitle: "Trading View",
      link: "https://www.tradingview.com/news/benzinga:c6a72f698094b:0-compute-not-fiat-or-bitcoin-will-be-the-currency-of-the-future-says-sam-altman-as-nvidia-s-jensen-huang-highlights-100-trillion-ai-opportunity/",
    },
    {
      date: "March 19, 2024",
      title: "Nvidia CEO: Latest AI Chip To Power “New Industrial Revolution”",
      subTitle: "Tech Monitor",
      link: "https://techmonitor.ai/technology/cloud/nvidia-ceo-lastest-ai-chip-to-power-new-industrial-revolution",
    },
  ];

  const currentItems = in_the_press.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(in_the_press.length / itemsPerPage);

  const navigate = useNavigate();

  return (
    <div className="press_main">
      <div className="in_the_press">
        <div className="press_title_1">News</div>
        <div className="in_the_press_data">
          {currentItems.map((data, index) => {
            return (
              <motion.div
                className="in_the_press_data_container"
                whileHover={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  const globalIndex = (currentPage - 1) * itemsPerPage + index;
                  navigate("/press/press-news" + globalIndex);
                }}
              >
                <img src={table2} alt="table_2" className="left_img" />
                <div className="in_the_press_data_inner" key={index}>
                  <div className="in_the_press_data_inner_top">
                    {/* <img src={wsj} alt="wsj" className="wsj" /> */}
                    <div className="date">{data.date}</div>
                  </div>
                  <div className="title">{data.title}</div>
                  <div className="sub_title">Read More... </div>
                </div>
              </motion.div>
            );
          })}
          <div className="pagination">
            <div
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              style={{ cursor: currentPage === 1 ? "not-allowed" : "pointer" }}
            >
              {"< Previous"}
            </div>

            {Array.from({ length: totalPages }, (_, i) => i + 1)
              .filter((page) => page >= 1 && page <= totalPages)
              .map((number) => (
                <div
                  key={number}
                  id={number}
                  onClick={() => setCurrentPage(number)}
                  style={{
                    fontWeight: currentPage === number ? "bold" : "normal",
                    textDecoration:
                      currentPage === number ? "underline" : "none",
                  }}
                >
                  {number}
                </div>
              ))}
            <div
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              style={{
                cursor: currentPage === totalPages ? "not-allowed" : "pointer",
              }}
            >
              {"Next >"}
            </div>
          </div>
        </div>
      </div>
      <div className="press_release">
        <div className="press_title_2">Media</div>
        <div className="press_release_data">
          {press_release.map((data, index) => {
            return (
              <a href={data.link} target="_blank">
                <motion.div
                  className="press_release_container"
                  whileHover={{
                    cursor: "pointer",
                  }}
                >
                  <img src={table2} alt="table_2" className="left_img" />
                  <div className="press_release_inner" key={index}>
                    <div className="title">{data.title}</div>
                    <div className="sub_title">Article</div>
                    <div className="bottom">
                      <div className="sub_title">{data.subTitle}</div>
                      <div className="date">{data.date}</div>
                    </div>
                  </div>
                </motion.div>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Press;
