import React from "react";
import "./PressNews13.scss";
function PressNews13() {
  return (
    <div className="PressNews13">
      <div >Press Release</div>

      <div class="heading">
        Alset Capital Inc. Announces Stock Option, Restricted Share Unit Grant
        And Engagement Of Market Maker
      </div>

      <div class="date">March 27, 2024</div>

      <p>
        <span className="bold">VANCOUVER, British Columbia</span> – March 27, 2024 – <span className="bold">Alset Capital Inc.</span>
        (TSX-V:KSUM) (“<span className="bold">Alset</span>” or the “<span className="bold">Company</span>”) is pleased to announce the
        Company has granted a total of 6,000,000 incentive stock options (the
        “<span className="bold">Options</span>“) to purchase common shares of the Company (“<span className="bold">Common Shares</span>“) to
        certain Directors, Officers, Consultants and Employees of the Company
        pursuant to the Company’s long-term incentive plan (the “<span className="bold">LTIP</span>“). Each
        Option is exercisable into one Common Share at an exercise price of
        $0.25 per Common Share for a period of three (3) years from the date of
        grant. The Options shall vest immediately on the date of grant. All of
        the Options expire on March 27, 2027. Additionally, the Company has
        adopted a fixed number, Restricted Share Unit Plan (the “<span className="bold">RSU Plan</span>”)
        which will allow eligible participants to be granted restricted share
        units redeemable for up to 9,423,033 common shares of the Company (the
        “<span className="bold">RSUs</span>”). In connection with adoption of the RSU Plan, the Company has
        granted 3,000,000 RSUs to certain Directors and Officers of the Company.
        The RSU Plan and the grant of the RSUs is subject to the approval of the
        TSX Venture Exchange (the “<span className="bold">TSXV</span>”) and the approval of the shareholders
        at the next annual general meeting of the Company.
      </p>

      <div class="sub-header">Engagement of Market Maker</div>

      <p>
        The Company is also pleased to announce that it has entered into a
        market making agreement (the “<span className="bold">ITG Agreement</span>”) pursuant to which it has
        retained Independent Trading Group Inc. (“<span className="bold">ITG</span>”) to provide market-making
        services in accordance with the policies of the TSXV. ITG will trade
        Shares on the TSXV for the purposes of maintaining an orderly market and
        improving the liquidity of the Company’s Common Shares.
      </p>

      <p>
        ITG will not receive Shares or options as compensation for its services,
        however ITG or its clients may have or may acquire a direct interest in
        the securities of the Company. Alset and ITG are unrelated and
        unaffiliated entities. ITG is a member of the Investment Industry
        Regulatory Organization of Canada (IIROC) and can access all Canadian
        stock exchanges and alternative trading systems. The capital and
        securities required for any trade undertaken by ITG as principal will be
        provided by ITG.
      </p>

      <p>
        The ITG Agreement is for an infinite term and may be terminated on
        30-days’ notice. ITG will be compensated $5,000 per month and will be
        paid by the Company from its working capital resources.
      </p>

      <div class="sub-header">On behalf of Alset Capital Inc.</div>

      <p><span className="italic">“Morgan Good”</span> Morgan Good</p>

      <p>Chief Executive Officer</p>

      <div class="sub-header">About Alset Capital Inc.</div>

      <p>
        Alset Capital Inc. is an investment issuer that is focused on investment
        in diversified industries such as technology, healthcare, industrial,
        special situations, operating businesses through both debt and equity
        using cash resources or shares in its capital. The Company is led by an
        experienced, entrepreneurial group of executives having a diverse
        industry and capital markets background.
      </p>

      <p className="bold">For further information about Alset Capital Inc., please contact:</p>

      <p className="bold">Morgan Good, CEO and Director</p>

      <p>T: 604.715.4751</p>

      <p>E: morgan@alsetai.com</p>

        <div class="sub-header">
          Cautionary Note regarding Forward Looking Statements
        </div>

        <p>
          Certain statements in this press release may contain forward-looking
          information (within the meaning of Canadian securities legislation),
          including, without limitation, the issuance of the Common Shares upon
          exercise of the Options and the RSUs and the approval of the TSX
          Venture Exchange and the shareholders of the Company. These statements
          address future events and conditions and, as such, involve known and
          unknown risks, uncertainties, and other factors, which may cause the
          actual results, performance, or achievements to be materially
          different from any future results, performance, or achievements
          expressed or implied by the statements. Forward-looking statements
          speak only as of the date those statements are made. Although the
          Company believes the expectations expressed in such forward-looking
          statements are based on reasonable assumptions, such statements are
          not guarantees of future performance and actual results may differ
          materially from those in the forward-looking statements. Factors that
          could cause the actual results to differ materially from those in
          forward-looking statements include regulatory actions, market prices,
          and continued availability of capital and financing, and general
          economic, market or business conditions. Investors are cautioned that
          any such statements are not guarantees of future performance and
          actual results or developments may differ materially from those
          projected in the forward-looking statements. Forward-looking
          statements are based on the beliefs, estimates, and opinions of the
          Company’s management on the date the statements are made. Except as
          required by applicable law, the Company assumes no obligation to
          update or to publicly announce the results of any change to any
          forward-looking statement contained or incorporated by reference
          herein to reflect actual results, future events or developments,
          changes in assumptions, or changes in other factors affecting the
          forward-looking statements. If the Company updates any forward-looking
          statement(s), no inference should be drawn that it will make
          additional updates with respect to those or other forward-looking
          statements.
        </p>

        <p className="bold">
          Neither TSX Venture Exchange nor its Regulation Services Provider (as
          that term is defined in the policies of the TSX Venture Exchange)
          accepts responsibility for the adequacy or accuracy of this release.
        </p>
      </div>
  );
}

export default PressNews13;
