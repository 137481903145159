import React from "react";
import "./InPressSingle.scss";

function InPressSingle() {
  return (
    <div className="inPress_singlePage">
      <div className="sec_1">
        November 28, 2023 – Vancouver, Canada – ALSET CAPITAL INC.
        (TSX-V:KSUM.H) (“ALSET” or the “Company”) is pleased to announce that it
        hasNovember 28, 2023 – Vancouver, Canada – ALSET CAPITAL INC.
        (TSX-V:KSUM.H) (“ALSET” or the “Company”) is pleased to announce that it
        hasNovember 28, 2023 – Vancouver, Canada – ALSET CAPITAL INC.
        (TSX-V:KSUM.H) (“ALSET” or the “Company”) is pleased to announce that it
        hasNovember 28, 2023 – Vancouver, Canada – ALSET CAPITAL INC.
        (TSX-V:KSUM.H) (“ALSET” or the “Company”) is pleased to announce that it
        hasNovember 28, 2023 – Vancouver, Canada – ALSET CAPITAL INC.
        (TSX-V:KSUM.H) (“ALSET” or the “Company”) is pleased to announce that it
        has
      </div>
      <div className="sec_2">
        November 28, 2023 - Vancouver, Canada - ALSET CAPITAL INC.
        (TSX-V:KSUM.H) (“ALSET” or the “Company”) is pleased to announce that it
        hasNovember 28, 2023 - Vancouver, Canada - ALSET CAPITAL INC.
        (TSX-V:KSUM.H) (“ALSET” or the “Company”) is pleased to announce that it
        hasNovember 28, 2023 - Vancouver, Canada - ALSET CAPITAL INC.
        (TSX-V:KSUM.H) (“ALSET” or the “Company”) is pleased to announce that it
        has November 28, 2023 - Vancouver, Canada - ALSET CAPITAL INC.
        (TSX-V:KSUM.H) (“ALSET” or the “Company”) is pleased to announce that it
        hasNovember 28, 2023 - Vancouver, Canada - ALSET CAPITAL INC.
        (TSX-V:KSUM.H) (“ALSET” or the “Company”) is pleased to announce that it
        has
      </div>
      <div className="sec_3">
        November 28, 2023 - Vancouver, Canada - ALSET CAPITAL INC.
        (TSX-V:KSUM.H) (“ALSET” or the “Company”) is pleased to announce that it
        hasNovember 28, 2023 - Vancouver, Canada - ALSET CAPITAL INC.
        (TSX-V:KSUM.H) (“ALSET” or the “Company”) is pleased to announce that it
        hasNovember 28, 2023 - Vancouver, Canada - ALSET CAPITAL INC.
        (TSX-V:KSUM.H) (“ALSET” or the “Company”) is pleased to announce that it
        hasNovember 28, 2023 - Vancouver, Canada - ALSET CAPITAL INC.
        (TSX-V:KSUM.H) (“ALSET” or the “Company”) is pleased to announce that it
        has
      </div>
    </div>
  );
}

export default InPressSingle;
